import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Button,
  FormControl,
  MenuItem,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "calc(100% - 30px)",
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  p: 4,
  pt: 2,
  pb: 2,
  borderRadius: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: 24,
};

const OutletForm = ({
  open = true,
  edit = false,
  data = {},
  handleChange = () => {},
  readOnly = false,
  handleClose = () => {},
  handleSubmit = () => {},
  owners,
  countries,
  cities,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" sx={{ p: 2 }}>
          {readOnly ? "View" : edit ? "Edit" : "Add"} Outlet
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid key="1" item xs={12} sm={6}>
              <TextField
                sx={{ width: "100%" }}
                name={"name"}
                variant={"standard"}
                label={"Name"}
                required
                autoComplete="off"
                type="text"
                autoFocus={true}
                disabled={readOnly}
                value={data?.name || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="2" item xs={12} sm={6}>
              <Dropdown
                name={"owner_id"}
                value={data?.owner_id || data?.owner?.id}
                options={owners}
                handleChange={handleChange}
                label="Owner"
              />
            </Grid>
            <Grid key="3" item xs={12} sm={12}>
              <TextField
                sx={{ width: "100%" }}
                name={"email"}
                variant={"standard"}
                label={"Email"}
                required
                autoComplete="off"
                type="email"
                disabled={readOnly}
                value={data?.email || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="4" item xs={12} sm={6}>
              <TextField
                sx={{ width: "100%" }}
                name={"phone"}
                variant={"standard"}
                label={"Phone"}
                required
                autoComplete="off"
                type="phone"
                disabled={readOnly}
                value={data?.phone || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="5" item xs={12} sm={6}>
              <TextField
                sx={{ width: "100%" }}
                name={"gst"}
                variant={"standard"}
                label={"GST"}
                required
                autoComplete="off"
                type="text"
                disabled={readOnly}
                value={data?.gst || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="6" item xs={12} sm={6}>
              <TextField
                sx={{ width: "100%" }}
                name={"address_1"}
                variant={"standard"}
                label={"Address Line 1"}
                required
                autoComplete="off"
                type="text"
                disabled={readOnly}
                value={data?.address_1 || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="7" item xs={12} sm={6}>
              <TextField
                sx={{ width: "100%" }}
                name={"address_2"}
                variant={"standard"}
                label={"Address Line 2"}
                required
                autoComplete="off"
                type="text"
                disabled={readOnly}
                value={data?.address_2 || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="8" item xs={12} sm={6}>
              <Dropdown
                name={"country_id"}
                value={data?.country_id || data?.city?.country?.id}
                options={countries}
                handleChange={handleChange}
                label="Country"
              />
            </Grid>
            <Grid key="9" item xs={12} sm={6}>
              <Dropdown
                name={"city_id"}
                value={data?.city_id || data?.city?.id}
                options={cities}
                handleChange={handleChange}
                label="City"
              />
            </Grid>
            <Grid
              key="10"
              item
              xs={12}
              sx={{ m: 3, display: "flex", justifyContent: "center" }}
            >
              {readOnly ? (
                <>
                  <Button onClick={handleClose}>Close</Button>
                </>
              ) : (
                <>
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                  <Button onClick={handleClose}>Cancel</Button>
                </>
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

const Dropdown = ({
  value = "",
  options = [],
  label = "",
  name = "",
  handleChange = () => {},
}) => {
  return (
    <>
      <FormControl fullWidth >
        <TextField
          select
          variant="standard"
          labelId="owner-label"
          id="owner"
          value={value || ""}
          label={label}
          onChange={handleChange}
          name={name}
          disabled={!options || !options.length}
          required
        >
          {options &&
            options.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.value || ""}
              </MenuItem>
            ))}
        </TextField>
      </FormControl>
    </>
  );
};

export default OutletForm;

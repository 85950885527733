import AppointmentsActionType from "./appointments.types";

const INITIAL_STATE = {
    appointments: null,
    page: 1,
    pages: 1
}

const reducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case AppointmentsActionType.SET_APPOINTMENTS:
            return {
                ...state,
                page: payload.page,
                pages: payload.pages,
                appointments: payload.objects
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;

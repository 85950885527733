const AuthActionType = {
    LOGIN: "LOGIN",
    SET_TOKEN: "SET_TOKEN",
    GET_USER: "GET_USER",
    SET_USER: "SET_USER",
    LOGOUT: "LOGOUT",
    GET_OUTLETS: "GET_OUTLETS_USER",
    SET_OUTLETS: "SET_OUTLETS_USER",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
}

export default AuthActionType

export const paymentMethods = [
    {
        value: "Cash",
        id: "CASH",
    },
    {
        value: "Voucher",
        id: "VOUCHER",
    },
    {
        value: "Card",
        id: "CARD",
    },
    {
        value: "Cheque",
        id: "CHEQUE",
    },
    {
        value: "Other",
        id: "OTHER",
    },
];

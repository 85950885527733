import React, { useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: '450px',
    maxWidth: '500px',
    maxHeight: "calc(100vh)",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "1px solid #ccc",
    p: 4,
    pt: 2,
    pb: 2,
    borderRadius: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    boxShadow: 24,
};

const ConfirmDelete = ({
    open, id, handleDelete, handleClose
}) => {
    const [deleting, setDeleting] = useState(false);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h6" sx={{ p: 2 }}>
                    Confirm Delete
                </Typography>
                <Typography>
                    Are you sure you want to delete this transaction?
                </Typography>
                <Box sx={{mt: 2}}>
                    <Button
                        onClick={() => {
                            setDeleting(true);
                            handleDelete(id);
                        }}
                    >
                        {deleting ? "Deleting..." : "Delete"}
                    </Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmDelete;

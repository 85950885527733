import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOutlet, getOutlets } from "../../redux/outlets/outlets.actions";
import { getCategoriesOptions } from "../../redux/categories/categories.actions";
import {
  getProducts,
  getSales,
  createSale,
  editSale,
  deleteSale,
  setSales,
  addPayments as _addPayments,
} from "../../redux/sales/sales.actions";
import { getCustomerOptions } from "../../redux/customers/customers.actions";
import { addCustomer } from "../../redux/customers/customers.actions";
import Loader from "../../sharedComponents/Loader";
import Container from "../../sharedComponents/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TrashIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/EditNote";
import SaleForm from "../../components/sales/SaleForm";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import {dateString} from "../../utils/date.js"
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const newProduct = {
  category_id: "",
  product_id: "",
  quantity: 1,
  price: 0,
  total: 0,
  discount: 0,
};

const newSaleRecord = {
  customer_id: "",
  payment_status: "PENDING",
  products: [{ ...newProduct }],
  total: 0,
};

const Sales = () => {
  const dispatch = useDispatch();
  const outlets = useSelector((state) => state.outlets.outlets);
  const selectedOutlet = useSelector((state) => state.outlets.outlet);
  const categories = useSelector((state) => state.categories.options);
  const customers = useSelector((state) => state.customers.options);
  const products = useSelector((state) => state.sales.products);
  const user = useSelector((state) => state.auth.user);
  const sales = useSelector((state) => state.sales.sales);
  const page = useSelector((state) => state.sales.page);
  const pages = useSelector((state) => state.sales.pages);
  const [showNew, setShowNew] = useState(false);
  const [newSale, setNewSale] = useState({ ...newSaleRecord });
  const [filteredCategories, setCategories] = useState([]);
  const [pay, setPay] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [searchIconClicked, setSearchIconClicked] = useState(false)
  const handleSelectOutlet = (outletId) => {
    dispatch(selectOutlet(outletId));
    // eslint-disable-next-line
  };

  useEffect(() => {
    if (!outlets && user?.role === "admin") dispatch(getOutlets());
    // eslint-disable-next-line
  }, [outlets]);

  useEffect(() => {
    user?.outlets && handleSelectOutlet(user.outlets[0]?.id);
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    _getCategoriesOptions();
    if (selectedOutlet) {
      dispatch(getCustomerOptions(selectedOutlet.id));
      if (products?.length > 0) {
        dispatch(getSales(selectedOutlet.id, searchInput, page));
      } else {
        dispatch(getProducts(selectedOutlet.id));
      }
    }
    setSearchIconClicked(false);
    // eslint-disable-next-line
  }, [selectedOutlet, products]);

  useEffect(() => {
    if (searchIconClicked) {
      dispatch(getSales(selectedOutlet.id, searchInput, 1));
      setSearchIconClicked(false);
    }
    // eslint-disable-next-line
  }, [searchIconClicked])

  useEffect(() => {
    if (categories?.length > 0 && products.length > 0) {
      let _catIds = products.map((p) => p.category_id);
      let _filteredCategories = categories.filter((c) =>
        _catIds.includes(c.id)
      );
      setCategories(_filteredCategories);
    }
  }, [categories, products]);

  const _getCategoriesOptions = () => {
    if (!categories?.length) dispatch(getCategoriesOptions());
  };

  const handleNewProductChange = (i, e) => {
    _handleProductChange(i, e, newSale);
  };

  const _handleProductChange = (i, e, sale) => {
    let _products = sale.products;
    let _product = _products[i];
    let { name, value } = e.target;
    if (name === "product_id") {
      _product.price = products.find((p) => p.id === value)?.price;
      _product.quantity = 1;
      _product.total = _product.quantity * _product.price;
      _product.discount = 0;
    }
    if (name === "category_id") {
      _product.product_id = "";
      _product.price = null;
    }
    if (name === "quantity" && value <= 0) value = 1;
    if (name === "quantity") {
      value = parseInt(value);
      _product.total = value * _product.price;
    }
    let discount = _product?.discount || 0;
    if (name === "total"){
      value = value.replace("-", "");
      value = parseFloat(value.match(/\d+(\.\d+)/)) || parseFloat(value);
      discount = 100 * (1 - value/(_product.quantity*_product.price))
    }
    _product = { ..._product, [name]: value };
    _products[i] = { ..._product, discount };
    console.log(_products)
    let total = _products.reduce((x, y) => x + parseFloat(y.total, 0), 0);
    setNewSale({
      ...sale,
      total,
      products: [..._products],
    });
  };

  const handleNewSaleChange = ({ target: { name, value } }) => {
    setNewSale({ ...newSale, [name]: value });
  };

  const handleAddProduct = () => {
    setNewSale({
      ...newSale,
      products: [...newSale.products, newProduct],
    });
  };

  const handleDeleteProduct = (i) => {
    const _products = newSale.products;
    _products.splice(i, 1);
    setNewSale({
      ...newSale,
      products: [..._products],
    });
  };

  const setSale = (sa) => {
    setBackdrop(false);
    let _products = sa.products;
    _products = _products.map((_p) => ({
      ..._p,
      category_id: products?.find((p) => p.id === _p.product_id)?.category_id,
    }));
    _products.sort((a, b) =>
      a.sort_idx > b.sort_idx ? 1 : a.sort_idx < b.sort_idx ? -1 : 0
    );
    setNewSale({
      ...sa,
      products: _products,
    });
  };

  const handleSubmit = () => {
    if (newSale?.id) {
      dispatch(
        editSale(selectedOutlet.id, newSale.id, newSale, (d) => setSale(d))
      );
    } else {
      setBackdrop(true);
      dispatch(createSale(selectedOutlet.id, newSale, (d) => setSale(d)));
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this record?") === true)
      dispatch(
        deleteSale(selectedOutlet?.id, id, () => {
          setShowNew(false);
        })
      );
  };

  const addPayments = (payments, partial, cb = () => {}) => {
    setBackdrop(true);
    dispatch(
      _addPayments(
        selectedOutlet.id,
        newSale.id,
        payments.filter((p) => !p.id),
        (data) => {
          cb();
          setBackdrop(false);
          setPay(false);
          setNewSale({
            ...newSale,
            payment_status: partial ? "PARTIALLY_PAID" : "PAID",
            payments: [...data],
          });
        }
      )
    );
  };

  const addNewCustomer = (customer, callback) => {
    dispatch(
      addCustomer(selectedOutlet.id, customer, () => {
        callback();
        dispatch(getCustomerOptions(selectedOutlet.id));
      })
    );
  };

  useEffect(() => {
    let d = false;
    if (!newSale.customer_id) d = true;
    if (!newSale.products.every((p) => p.product_id !== "")) d = true;
    setDisableSave(d);
  }, [newSale]);

  const handlePageChange = (p) => {
    if (p === page) return;
    dispatch(setSales({page: p, pages, objects: null}));
    dispatch(getSales(selectedOutlet.id, searchInput, p));
  }

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchIconClick = (e) => {
    e.preventDefault();
    setSearchIconClicked(true);
  };

  if (!selectedOutlet && user.role === "admin") {
    return (
      <Container>
        <Box sx={{ width: "100%", marginBottom: "20px" }}>
          <FormControl sx={{ width: "500px", maxWidth: "100%" }}>
            <InputLabel id="outlet-label">Outlet</InputLabel>
            <Select
              labelId="outlet-label"
              id="outlet"
              value=""
              label="Outlet"
              onChange={(e) => handleSelectOutlet(e.target.value)}
            >
              {outlets
                ?.filter((o) => o.active)
                .map((outlet) => (
                  <MenuItem key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Typography>
          No outlet selected. Please select an outlet to view sales.
        </Typography>
      </Container>
    );
  }
  return (
    <Container>
      <Box sx={{ width: "100%", marginBottom: "20px" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
          open={backdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {user.role === "admin" && (
          <FormControl sx={{ width: "500px", maxWidth: "100%" }}>
            <InputLabel id="outlet-label">Outlet</InputLabel>
            <Select
              labelId="outlet-label"
              id="outlet"
              value={selectedOutlet.id}
              label="Outlet"
              onChange={(e) => handleSelectOutlet(e.target.value)}
            >
              {outlets
                ?.filter((o) => o.active)
                .map((outlet) => (
                  <MenuItem key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </Box>
      {showNew && (
        <SaleForm
          open={showNew}
          edit={newSale?.id}
          handleClose={() => setShowNew(false)}
          customers={customers}
          products={products}
          currency={selectedOutlet?.city?.country?.currency_short}
          categories={filteredCategories}
          data={newSale}
          handleChange={handleNewSaleChange}
          handleProductChange={handleNewProductChange}
          handleAddProduct={handleAddProduct}
          handleDeleteProduct={handleDeleteProduct}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          pay={pay}
          setPay={setPay}
          addPayments={addPayments}
          readOnly={newSale?.payment_status !== "PENDING" || (newSale.id && !newSale?.appointment?.sale_only)}
          addNewCustomer={addNewCustomer}
          disableSave={disableSave}
        />
      )}
      <Box
        sx={{
          width: "900px",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ width: "100%" }}>Sales</Typography>
        {sales ? (
          <Box width="100%">
            <Button
              variant="standard"
              onClick={() => {
                setShowNew(true);
                if (newSale?.id)
                  setNewSale({
                    ...newSaleRecord,
                    products: [{ ...newProduct }],
                  });
              }}
              sx={{ float: "right" }}
            >
              Add
            </Button>
            <form onSubmit={handleSearchIconClick}>
              <TextField
                type="text"
                placeholder="Search customers"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e)}
                style={{ width: "100%", padding: "1rem", marginBottom: "1rem" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={(e) => handleSearchIconClick(e)}>
                        <SearchIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            <TableContainer sx={{ width: "100%" }} component={Paper}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow key={"head"}>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Products
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Payment
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sales?.map((c) => (
                    <TableRow
                      key={c.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {`${c?.customer?.firstname || 'Walk'} ${c?.customer?.lastname || 'In'}`}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {c.products
                          .map(
                            (p) =>
                              products?.find((pr) => pr.id === p.product_id)
                                ?.name
                          )
                          .join(",")}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {`${
                          selectedOutlet.city.country.currency_short
                        } ${parseFloat(c.total).toFixed(2)}`}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {c.payment_status.replace("_", " ")}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {dateString(c.created_at)}
                      </TableCell>
                      <TableCell sx={{ padding: "0px 16px" }} align="right">
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            setShowNew(true);
                            setSale({ ...c });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        {false && (
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() => handleDelete(c.id)}
                          >
                            <TrashIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack mt={2} alignItems="center">
              <Pagination
                count={pages}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={(_, n) => handlePageChange(n)}
              />
            </Stack>
          </Box>
        ) : (
          <Loader />
        )}
      </Box>
    </Container>
  );
};

export default Sales;

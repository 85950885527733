import ProductsActionType from "./products.types";
import * as ProductsActions from "./products.actions";
import { GET, POST, PUT } from "../../axios";
import { takeEvery, put } from "redux-saga/effects";
import urls from "../urls";
import { setError, setSuccess } from "../actions/action.actions";

function* getProductsSaga({ categoryId, page, size }) {
  const data = yield GET(`${urls.categories}/${categoryId}/products`, {
    page,
    size,
  });
  if (data) {
    yield put(ProductsActions.setProducts(categoryId, data));
  }
}

function* addProductSaga({ categoryId, product, callback }) {
  const data = yield POST(`${urls.categories}/${categoryId}/products`, product);
  if (data?.id) {
    callback();
    yield put(setSuccess("Product added succesfully!"));
    yield put(ProductsActions.getProducts(categoryId, 1, 100));
  } else {
    yield setError("Something went wrong");
  }
}

function* updateProductSaga({ categoryId, service, callback }) {
  const data = yield PUT(
    `${urls.categories}/${categoryId}/products/${service.id}`,
    service
  );
  if (data?.id) {
    callback();
    yield put(setSuccess("Product updated succesfully!"));
    yield put(ProductsActions.alterProduct(data));
  } else {
    yield put(setError("Something went wrong"));
  }
}

function* getProductsOptionsSaga({ outletId, categoryId }) {
  const data = yield GET(
    `${urls.outlets}/${outletId}/categories/${categoryId}/products`
  );
  if (data) {
    yield put(ProductsActions.setProductsOptions(data));
  }
}

export function* productsSagas() {
  yield takeEvery(ProductsActionType.GET_PRODUCTS, getProductsSaga);
  yield takeEvery(ProductsActionType.ADD_PRODUCT, addProductSaga);
  yield takeEvery(ProductsActionType.UPDATE_PRODUCT, updateProductSaga);
  yield takeEvery(
    ProductsActionType.GET_PRODUCTS_OPTIONS,
    getProductsOptionsSaga
  );
}

import CountriesActionType from "./countries.types";

const INITIAL_STATE = {
  countries: null,
  page: 1,
  pages: 1,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case CountriesActionType.SET_COUNTRIES:
      return {
        ...state,
        page: payload.page,
        pages: payload.pages,
        countries: payload.objects.sort((a, b) =>
          a.currency_short > b.currency_short ? 1 : -1
        ),
      };
    case CountriesActionType.SET_CITIES:
      let countries = state.countries;
      let countryId = action.countryId;
      countries.find((c) => c.id === countryId).cities = payload.objects;
      return {
        ...state,
        countries: [...countries],
      };
    case CountriesActionType.ALTER_COUNTRY:
      let countries1 = state.countries;
      let idx = countries1.findIndex((x) => x.id === payload.id);
      let _cities = countries1[idx].cities;
      countries1[idx] = { ...payload, cities: _cities };
      return {
        ...state,
        countries: [...countries1],
      };
    default:
      return state;
  }
};

export default reducer;

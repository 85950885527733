import ProductsActionType from './products.types'

export const addProduct = (categoryId, product, callback=()=>{}) => ({
    type: ProductsActionType.ADD_PRODUCT,
    categoryId, product, callback
});

export const getProducts = (categoryId, page, size) => ({
    type: ProductsActionType.GET_PRODUCTS,
    categoryId, page, size
});

export const setProducts = (categoryId, payload) => ({
    type: ProductsActionType.SET_PRODUCTS,
    categoryId, payload
});

export const updateProduct = (categoryId, service, callback=()=>{}) => ({
    type: ProductsActionType.UPDATE_PRODUCT,
    categoryId, service, callback
});

export const alterProduct = (payload) => ({
    type: ProductsActionType.ALTER_PRODUCT,
    payload
});

export const resetProducts = () => ({
    type: ProductsActionType.RESET_PRODUCTS
});

export const getProductsOptions = (outletId, categoryId) => ({
    type: ProductsActionType.GET_PRODUCTS_OPTIONS,
    outletId, categoryId
});

export const setProductsOptions = (payload) => ({
    type: ProductsActionType.SET_PRODUCTS_OPTIONS,
    payload
});


import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../redux/auth/auth.actions";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Settings from "@mui/icons-material/Settings";
import ExpensesIcon from '@mui/icons-material/CurrencyExchange';

const drawerWidth = 240;

const adminNavs = [
  // {
  //   name: "Notifications",
  //   route: "/notifications",
  //   icon: <MailIcon />,
  // },
  {
    name: "Countries",
    route: "/countries",
    icon: <InboxIcon />,
  },
  {
    name: "Cities",
    route: "/cities",
    icon: <InboxIcon />,
  },
  {
    name: "Categories",
    route: "/categories",
    icon: <MailIcon />,
  },
  {
    name: "Services",
    route: "/services",
    icon: <InboxIcon />,
  },
  {
    name: "Products",
    route: "/products",
    icon: <MailIcon />,
  },
  {
    name: "Owners",
    route: "/owners",
    icon: <InboxIcon />,
  },
  {
    name: "Outlets",
    route: "/outlets",
    icon: <MailIcon />,
  },
];

const outletNavs = [
  {
    name: "Technicians",
    route: "/technicians",
    icon: <MailIcon />,
  },
  {
    name: "Customers",
    route: "/customers",
    icon: <InboxIcon />,
  },
  {
    name: "Sales",
    route: "/sales",
    icon: <MailIcon />,
  },
  {
    name: "Appointments",
    route: "/appointments",
    icon: <InboxIcon />,
  },
  {
    name: "Expenses",
    route: "/expenses",
    icon: <ExpensesIcon />,
  },
  {
    name: "Reports",
    route: "/reports",
    icon: <InboxIcon />,
  },
];

const legend = {
  "#3479BA": "Booked",
  "#FFB6C1": "No Show",
  "#00B300": "In Progress",
  "#D2691E": "Partially Paid",
  "#AAAAAA": "Completed",
};

const Legend = ({text, color}) => {
  return (
    <Box sx={{width: "100%", textAlign: "left", ml: 4, mt: 1, display: "flex"}}>
      <div style={{width: "1.5rem", height: "1.5rem", backgroundColor: color, marginRight: "0.5rem"}}></div>
      {text}
    </Box>
  );
}

export default function ResponsiveDrawer(props) {
  const user = useSelector((state) => state.auth.user);
  
  if (user.role === 'owner')
    if (!outletNavs.find(n => n.name === "Settings"))
      outletNavs.push({
        name: "Settings",
        route: "/settings",
        icon: <Settings />,
      });

  const dispatch = useDispatch(null);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box sx={{ overflow: "auto" }}>
      <List sx={{ mt: 8 }}>
        {user?.role === "admin" &&
          adminNavs.map((item) => (
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                selected={location.pathname.includes(item.route)}
                onClick={() => {
                  navigate(item.route);
                  handleDrawerToggle();
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        {outletNavs.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              selected={location.pathname.includes(item.route)}
              onClick={() => navigate(`${item.route}`)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {location.pathname === "/appointments" &&
      <>
      <Typography variant="body" fontStyle={"italic"}>Appointment Statuses</Typography>
      <List>
          {Object.keys(legend).map((k) => <Legend text={legend[k]} color={k}/>)}
      </List>
      </>}
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Finoppal
          </Typography>
          <Button
            color="inherit"
            onClick={() => dispatch(logout())}
            size="small"
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Divider />
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

import ServicesActionType from "./services.types"
import * as ServicesActions from "./services.actions"
import { GET, POST, PUT } from "../../axios"
import { takeEvery, put } from "redux-saga/effects"
import urls from "../urls"
import {setError, setSuccess} from "../actions/action.actions"

function* getServicesSaga({categoryId, page, size}) {
    const data = yield GET(`${urls.categories}/${categoryId}/services`, {page, size})
    if (data) {
        yield put(ServicesActions.setServices(categoryId, data))
    }
}

function* addServiceSaga({categoryId, service, callback}) {
    const data = yield POST(`${urls.categories}/${categoryId}/services`, service)
    if (data?.id) {
        callback()
        yield put(setSuccess('Service added succesfully!'))
        yield put(ServicesActions.getServices(categoryId, 1, 100))
    } else {
        yield setError('Something went wrong')
    }
}

function* updateServiceSaga({categoryId, service, callback}) {
    const data = yield PUT(`${urls.categories}/${categoryId}/services/${service.id}`, service)
    if (data?.id) {
        callback()
        yield put(setSuccess('Service updated succesfully!'))
        yield put(ServicesActions.alterService(data))
    } else {
        yield put(setError('Something went wrong'))
    }
}

function* getServicesOptionsSaga() {
    const data = yield GET(urls.servicesOptions)
    if (data){
        yield put(ServicesActions.setServicesOptions(data))
    }
}

export function* servicesSagas() {
    yield takeEvery(ServicesActionType.GET_SERVICES, getServicesSaga)
    yield takeEvery(ServicesActionType.ADD_SERVICE, addServiceSaga)
    yield takeEvery(ServicesActionType.UPDATE_SERVICE, updateServiceSaga)
    yield takeEvery(ServicesActionType.GET_SERVICES_OPTIONS, getServicesOptionsSaga)
}

import ReportsActionType from './reports.types';

export const getReport = (filters, callback=()=>{}) => ({
    type: ReportsActionType.GET_REPORT,
    filters, callback
});

export const setReport = (payload) => ({
    type: ReportsActionType.SET_REPORT,
    payload
});

export const getOutlets = (countryId, callback=()=>{}) => ({
    type: ReportsActionType.GET_OUTLETS,
    countryId, callback
});

export const downloadDailyReport = (filters) => ({
    type: ReportsActionType.DOWNLOAD_DAILY_REPORT,
    filters,
});

export const downloadReport = (data) => ({
    type: ReportsActionType.DOWNLOAD_REPORT,
    data,
});
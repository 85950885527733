import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "../../sharedComponents/Container";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FAB from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/EditNote";
import TrashIcon from "@mui/icons-material/Delete";
import SearchSharp from "@mui/icons-material/SearchSharp";
import Loader from "../../sharedComponents/Loader";
import {
  getAppointments,
  createAppointment,
  updateAppointment,
  deleteAppointment,
  setAppointments,
  addPayments as _addPayments,
  deletePayment as _deletePayment,
  getNotes,
  addNotes
} from "../../redux/appointments/appointments.actions";
import { addCustomer, getCustomer } from "../../redux/customers/customers.actions";
import { getCustomerOptions } from "../../redux/customers/customers.actions";
import { getServicesOptions } from "../../redux/services/services.actions";
import { getProducts, addPayments as _addSalePayments } from "../../redux/sales/sales.actions";
import { selectOutlet, getOutlets } from "../../redux/outlets/outlets.actions";
import { getTechnicians } from "../../redux/technicians/technicians.actions";
import AppointmentForm from "../../components/appointments/AppointmentForm";
import moment from "moment";
// import Calendar from "../../components/appointments/Calendar";
import Calendar from "../../components/appointments/Scheduler";
import { formatDate, formatTime } from "../../utils/date";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SearchDrawer from "../../components/appointments/SearchDrawer";
import SaleForm from "../../components/sales/SaleForm";


const Appointments = () => {
  const appointments = useSelector((state) => state.appointments.appointments);
  const _page = useSelector((state) => state.appointments.page)
  const _pages = useSelector((state) => state.appointments.pages)
  const [showCalendar, setShowCalendar] = useState(true);
  const [calendarAppointments, setCalendarAppointments] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [technicianOptions, setTechnicianOptions] = useState([]);
  const [view, setView] = useState("day");
  const [date, setDate] = useState(formatDate(new Date()));
  const technicians = useSelector((state) => state.technicians.technicians);
  const user = useSelector((state) => state.auth.user);
  const outletId = useSelector((state) => state.outlets.outletId);
  const outlets = useSelector((state) => state.outlets.outlets);
  const [notes, setNotes] = useState([]);
  const products = useSelector((state) => state.sales.products);
  const [searchDrawer, setSearchDrawer] = useState(false);
  const [pay, setPay] = useState(false);

  useEffect(() => {
    user?.outlets && handleSelectOutlet(user.outlets[0]?.id);
    // eslint-disable-next-line
  }, [user]);

  const handleSelectOutlet = (outletId) => {
    dispatch(selectOutlet(outletId));
    // eslint-disable-next-line
  };

  const _setSale = (app) => {
    setSale({
      ...app,
      id: app.sale_id
    });
  }

  const addSalePayments = (payments, partial, cb = () => {}) => {
    dispatch(
      _addSalePayments(
        outlet.id,
        sale.id,
        payments.filter((p) => !p.id),
        (data) => {
          cb();
          setPay(false);
          setSale({
            ...sale,
            payment_status: partial ? "PARTIALLY_PAID" : "PAID",
            payments: [...data],
          });
          updateCustomerSearchResults({
            ...sale,
            payment_status: partial ? "PARTIALLY_PAID" : "PAID",
            payments: [...data],
          });
        }
      )
    );
  };


  const [newAppointment, setNewAppointment] = useState({
    date: formatDate(new Date()),
    services: [{
      category_id: "",
      service_id: "",
      amount: 0,
    }],
    products: [],
  });

  const handleAddProduct = () => {
    setNewAppointment({
      ...newAppointment,
      products: [
        ...newAppointment.products,
        {
          category_id: '',
          product_id: '',
          quantity: 1,
          discount: 0,
          total: 0,
        }
      ]
    })
  }

  const handleAddProductEdit = () => {
    setSelectedAppointment({
      ...selectedAppointment,
      products: [
        ...selectedAppointment.products,
        {
          category_id: '',
          product_id: '',
          quantity: 1,
          discount: 0,
          total: 0
        }
      ]
    })
  }

  const handleProductChange = (i, e, obj, edit) => {
    let { name, value } = e.target;
    let p = newAppointment.products;
    p[i][name] = value;
    if (name === "product_id") {
      p[i]['price'] = products.find((p) => p.id === value).price;
    }
    if (name === "product_id" || name === "quantity") {
      p[i]['total'] = p[i]['price'] * p[i]['quantity']
    }
    if (name === "total") {
      value = value.replace("-", "");
      let _total = value === "" ? 0 : parseFloat(value);
      p[i][name] = _total;
      p[i]['discount'] = parseFloat((1-(_total/(p[i]['price']*p[i]['quantity'])))*100).toFixed(2);
    }
    
    if (name === "category_id") {
      p[i] = {
        ...p[i],
        price: 0,
        discount: 0,
        quantity: 1, 
        product_id: '',
        total: 0
      }
    }
    
    setNewAppointment({
      ...newAppointment,
      products: [...p]
    })
  }

  const handleProductChangeEdit = (i, e, obj, edit) => {
    let { name, value } = e.target;
    let p = selectedAppointment.products;
    p[i][name] = value;
    if (name === "product_id") {
      p[i]['price'] = products.find((p) => p.id === value).price;
    }
    if (name === "product_id" || name === "quantity") {
      p[i]['total'] = p[i]['price'] * p[i]['quantity']
    }
    if (name === "total") {
      value = value.replace("-", "");
      let _total = value === "" ? 0 : parseFloat(value);
      p[i][name] = _total;
      p[i]['discount'] = parseFloat((1-(_total/(p[i]['price']*p[i]['quantity'])))*100).toFixed(2);
    }
    
    if (name === "category_id") {
      p[i] = {
        ...p[i],
        price: 0,
        discount: 0,
        quantity: 1, 
        product_id: '',
        total: 0
      }
    }

    setSelectedAppointment({
      ...selectedAppointment,
      products: [...p]
    })
  }

  const handleProductDelete = (i) => {
    const _products = newAppointment.products;
    _products.splice(i, 1);
    setNewAppointment({
      ...newAppointment,
      products: _products,
    });
  }

  const handleProductDeleteEdit = (i) => {
    const _products = selectedAppointment.products;
    _products.splice(i, 1);
    setSelectedAppointment({
      ...selectedAppointment,
      products: _products,
    });
  }

  useEffect(() => {
    if (appointments) {
      let _appointments = [];
      appointments.forEach((a) => {
        let start = new Date(`${a.date}T${a.time}`);
        let end = moment(start).add(a.duration, "m").toDate();
        _appointments.push({
          id: a.id,
          title: `${a?.customer ? a.customer?.firstname + ' ' + a.customer?.lastname : 'Walk-in'}`,
          label: `${a?.customer ? a.customer?.firstname + ' ' + a.customer?.lastname : 'Walk-in'}`,
          dateStart: start,
          dateEnd: end,
          start,
          end,
          technicianId: a.technician_id,
          class: `event-${
            a.payment_status === "PARTIALLY_PAID"
              ? "brown"
              : a.status === "COMPLETED"
              ? "gray"
              : a.status === "IN_PROGRESS"
              ? "teal"
              : a.status === "NO_SHOW"
              ? "red"
              : "blue"
          }`,
          // backgroundColor: "black",
          // color: "black"
        });
      });
      setCalendarAppointments(_appointments);
    }
  }, [appointments]);

  useEffect(() => {
    if (technicians?.length)
      setTechnicianOptions(
        technicians
          .filter((t) => t.active)
          .map((t) => ({
            id: t.id,
            label: `${t.firstname} ${t.lastname}`,
            value: `${t.firstname} ${t.lastname}`,
          }))
      );
  }, [technicians]);

  const dispatch = useDispatch(null);
  const outlet = useSelector((state) => state.outlets.outlet);
  const customers = useSelector((state) => state.customers.options);
  const categories = useSelector((state) => state.services.options);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [sale, setSale] = useState(null);
  const [customerResults, setCustomerResults] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [customerAppointments, setCustomerAppointments] = useState(null);

  useEffect(() => {
    if ((!outlet && outletId) || outlet?.id !== outletId)
      outletId && dispatch(selectOutlet(outletId));
    // eslint-disable-next-line
  }, [outlet]);

  useEffect(() => {
    if (outletId) {
      dispatch(getTechnicians(outletId));
      dispatch(getAppointments(outletId, view, date));
      dispatch(getCustomerOptions(outletId));
      dispatch(getProducts(outletId));
    }
    // eslint-disable-next-line
  }, [outletId]);

  useEffect(() => {
    if (!categories) dispatch(getServicesOptions());
    // eslint-disable-next-line
  }, [categories]);

  const updateNotes = (customer_id) => {
    let notes = customers.find((c) => c.id === customer_id)?.notes;
    setNewAppointment({ ...newAppointment, customer_id, notes });
  };

  const handleNewAppointmentChange = (e) => {
    let { name, value } = e.target;
    if (name === "customer_id") return updateNotes(value);
    setNewAppointment({
      ...newAppointment,
      [name]: value,
    });
  };
  
  const handleAddService = () => {
    const _services = newAppointment.services;
    _services.push({
      category_id: "",
      service_id: "",
      amount: 0,
    });
    setNewAppointment({
      ...newAppointment,
      services: _services,
    });
  };

  const handleAddServiceEdit = () => {
    const _services = selectedAppointment.services;
    _services.push({
      category_id: "",
      service_id: "",
      amount: 0,
    });
    setSelectedAppointment({
      ...selectedAppointment,
      services: _services,
    });
  };

  const handleDeleteService = (index) => {
    const _services = newAppointment.services;
    _services.splice(index, 1);
    setNewAppointment({
      ...newAppointment,
      services: _services,
    });
  };

  const handleDeleteServiceEdit = (index) => {
    const _services = selectedAppointment.services;
    _services.splice(index, 1);
    setSelectedAppointment({
      ...selectedAppointment,
      services: _services,
    });
  };

  const setTotal = () => {
    setNewAppointment({
      ...newAppointment,
      total:
        newAppointment?.services?.reduce(
          (x, y) => x + parseFloat(y.amount),
          0
        ) || 0,
    });
  };

  const setTotalEdit = () => {
    setSelectedAppointment({
      ...selectedAppointment,
      total:
        selectedAppointment?.services?.reduce(
          (x, y) => x + parseFloat(y.amount),
          0
        ) || 0,
    });
  };

  const addNewCustomer = (customer, callback) => {
    dispatch(
      addCustomer(outletId, customer, () => {
        callback();
        dispatch(getCustomerOptions(outletId));
      })
    );
  };

  const _handleServiceChange = (i, e, obj, edit) => {
    const _services = obj.services;
    let { name, value } = e.target;
    if (name === "amount") {
      value = value.replace("-", "");
      value = value === "" ? 0 : parseFloat(value);
    }
    _services[i] = {
      ..._services[i],
      [name]: value,
    };
    _services[i] = {
      ..._services[i],
      [name]: value,
    };
    if (name === "service_id") {
      let price =
        categories
          ?.find((c) => c.id === _services[i].category_id)
          ?.services?.find((s) => s.id === value)?.prices?.[
          outlet.city.country.id
        ] || 0;
      _services[i] = {
        ..._services[i],
        amount: price,
      };
    }
    if (name === "category_id") {
      _services[i] = {
        ..._services[i],
        service_id: "",
        amount: 0,
      };
    }
    if (edit) {
      setSelectedAppointment({
        ...obj,
        services: _services,
      });
      setTotalEdit();
    } else {
      setNewAppointment({
        ...obj,
        services: _services,
      });
      setTotal();
    }
  };

  const handleServiceChange = (i, e) => {
    _handleServiceChange(i, e, newAppointment);
  };

  const handleUpdateServiceChange = (i, e) => {
    _handleServiceChange(i, e, selectedAppointment, true);
  };

  const _setAppointments = (app) => {
    dispatch(setAppointments({
      page: _page,
      pages: _pages,
      objects: [...appointments, app]
    }));
  }

  const callback = (app) => {
    setShowNew(false);
    setNewAppointment({ date: formatDate(new Date()), services: [], products: [] });
    _setSelectedAppointment(app);
    _setAppointments(app);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createAppointment(outletId, newAppointment, callback));
  };

  const updateCustomerSearchResults = (data) => {
    const apps = customerAppointments;
    const idx = apps?.findIndex((a) => a.id === data.id);
    if (idx >= 0){
      apps[idx] = data;
      setCustomerAppointments([...apps]);
    }
    if (customer) {
      dispatch(getCustomer(outletId, customer.id, (d) => setCustomer(d)));
    }
  }

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateAppointment(outletId, selectedAppointment, (d) => {
        _setSelectedAppointment(d);
        dispatch(getAppointments(outletId, view, date));
        updateCustomerSearchResults(d);
      }
    ));
  };

  const _setSelectedAppointment = (appointment) => {
    appointment?.services?.forEach((_s) => {
      _s.category_id = _s.service?.category_id;
      _s.service_id = _s.service?.id;
    });
    appointment?.products.forEach((_p) => {
      _p.category_id = products?.find(p => p.id === _p.product_id)?.category_id
    })
    setSelectedAppointment(appointment);
  };

  const setAppointmentById = (id) => {
    _setSelectedAppointment(appointments.find((x) => x.id === id));
  };

  const handleEditAppointmentChange = (e) => {
    let { name, value } = e.target;
    setSelectedAppointment({
      ...selectedAppointment,
      [name]: value,
    });
  };

  const deleteCallback = () => {
    setSelectedAppointment(null);
  };

  const handleDelete = (id) => {
    if (
      window.confirm("Are you sure you want to cancel this appointment?") ===
      true
    )
      dispatch(deleteAppointment(outletId, id, view, date, deleteCallback));
  };

  const addNewItem = (technicianId, timeObj) => {
    setShowNew(true);
    setNewAppointment({
      ...newAppointment,
      services: [{
        category_id: "",
        service_id: "",
        amount: 0,
      }],
      technician_id: technicianId,
      date: formatDate(timeObj),
      duration: 60,
      time: formatTime(timeObj),
    });
  };

  const updateTip = (tip) => {
    let data = {
      ...selectedAppointment,
      tip,
    };
    dispatch(
      updateAppointment(outletId, data, () => {
        setSelectedAppointment({ ...selectedAppointment, tip });
        dispatch(dispatch(getAppointments(outletId, view, date)));
      })
    );
  };

  const addPaymentsCB = (partial = false) => {
    dispatch(
      updateAppointment(
        outletId,
        {
          ...selectedAppointment,
          status: "COMPLETED",
          payment_status: partial ? "PARTIALLY_PAID" : "PAID",
        },
        (a) => {
          dispatch(dispatch(getAppointments(outletId, view, date)));
          _setSelectedAppointment({ ...a });
          updateCustomerSearchResults(a);
        }
      )
    );
  };

  const addPayments = (payments, partial = false, cb = () => {}) => {
    dispatch(
      _addPayments(
        outletId,
        selectedAppointment.id,
        payments.filter((p) => !p.id),
        () => {
          cb();
          addPaymentsCB(partial);
        }
      )
    );
  };

  const deletePayment = (id, cb) => {
    dispatch(
      _deletePayment(
        outletId,
        selectedAppointment.id,
        id,
        () => {
          cb();
          _setSelectedAppointment({
            ...selectedAppointment,
            payment_status: 'PARTIALLY_PAID',
            payments: selectedAppointment.payments.filter(p => p.id !== id)
          });
          dispatch(getAppointments(outletId, view, date));
        }
      )
    );
  }

  const fetchNotesCB = (n) => {
    setNotes(n)
  }
  const fetchNotes = () => {
    setNotes([]);
    dispatch(getNotes(outletId, selectedAppointment.id, fetchNotesCB));
  }
  const addNotesCB = (d) => {
    setNotes([
      ...notes, d
    ]);
  }
  const addNote = (note, cb=()=>{}) => {
    dispatch(addNotes(outletId, selectedAppointment.id, note, (n) => {addNotesCB(n); cb();}))
  }

  const onDateChange = (e) => {
    let _date = formatDate(e?.detail?.value);
    if (_date === date)
      _date = formatDate(e?.detail?.oldValue);
    setDate(_date);
  }

  useEffect(() => {
    if (outletId)
      dispatch(getAppointments(outletId, view, date));
    // eslint-disable-next-line
  }, [view, date]);


  useEffect(() => {
    if (!outlets && user?.role === "admin") dispatch(getOutlets());
    // eslint-disable-next-line
  }, [outlets]);

  const handleDownloadInvoice = () => {
    const win = window.open(`${process.env.REACT_APP_API_URL}/reports/download_invoice?appointment_id=${selectedAppointment.id}`, '_blank');
    win.focus();
  }

  if (!outletId && user.role === "admin") {
    return (
      <Container>
        <Box sx={{ width: "100%", marginBottom: "20px" }}>
          <FormControl sx={{ width: "500px", maxWidth: "100%" }}>
            <InputLabel id="outlet-label">Outlet</InputLabel>
            <Select
              labelId="outlet-label"
              id="outlet"
              value=""
              label="Outlet"
              onChange={(e) => handleSelectOutlet(e.target.value)}
            >
              {outlets
                ?.filter((o) => o.active)
                .map((outlet) => (
                  <MenuItem key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Typography>
          No outlet selected. Please select an outlet to view sales.
        </Typography>
      </Container>
    );
  }

  if (showCalendar) {
    return (
      <Container>
        {showNew && (
          <AppointmentForm
            customers={customers}
            categories={categories}
            handleClose={() => setShowNew(false)}
            data={newAppointment}
            technicians={technicianOptions}
            handleChange={handleNewAppointmentChange}
            handleAddService={handleAddService}
            handleServiceChange={handleServiceChange}
            handleDeleteService={handleDeleteService}
            handleSubmit={handleSubmit}
            currency={outlet?.city?.country?.currency_short}
            country_id={outlet?.city?.country?.id}
            updateTip={updateTip}
            addPayments={addPayments}
            deletePayment={deletePayment}
            handleDeleteAppointment={handleDelete}
            addNewCustomer={addNewCustomer}
            products={products}
            handleAddProduct={handleAddProduct}
            handleProductChange={handleProductChange}
            handleProductDelete={handleProductDelete}
          />
        )}
        {sale && <SaleForm
          open={sale}
          edit={sale?.id}
          handleClose={() => setSale(null)}
          customers={customers}
          products={products}
          currency={outlet?.city?.country?.currency_short}
          categories={categories}
          data={sale}
          pay={pay}
          setPay={setPay}
          addPayments={addSalePayments}
          readOnly={true}
          disableSave={true}
        />}
        {selectedAppointment && (
          <AppointmentForm
            edit={true}
            customers={selectedAppointment.customer ? [
              {
                id: selectedAppointment?.customer_id,
                value: `${selectedAppointment?.customer?.firstname} ${selectedAppointment?.customer?.lastname}`,
              },
            ] : customers}
            categories={categories}
            handleClose={() => _setSelectedAppointment(null)}
            data={selectedAppointment}
            handleChange={handleEditAppointmentChange}
            technicians={technicianOptions}
            handleAddService={handleAddServiceEdit}
            handleServiceChange={handleUpdateServiceChange}
            handleDeleteService={handleDeleteServiceEdit}
            handleSubmit={handleEditSubmit}
            currency={outlet?.city?.country?.currency_short}
            country_id={outlet?.city?.country?.id}
            updateTip={updateTip}
            addPayments={addPayments}
            deletePayment={deletePayment}
            handleDeleteAppointment={handleDelete}
            readOnly={selectedAppointment?.payment_status !== 'PENDING'}
            notes={notes}
            fetchNotes={fetchNotes}
            addNote={addNote}
            products={products}
            handleAddProduct={handleAddProductEdit}
            handleProductChange={handleProductChangeEdit}
            handleProductDelete={handleProductDeleteEdit}
            handleDownloadInvoice={handleDownloadInvoice}
          />
        )}
        <Box
          sx={{
            // minWidth: "1600px",
            width: "100%",
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {user.role === "admin" && (
            <FormControl sx={{ width: "500px", maxWidth: "100%" }}>
              <InputLabel id="outlet-label">Outlet</InputLabel>
              <Select
                labelId="outlet-label"
                id="outlet"
                value={outletId}
                label="Outlet"
                onChange={(e) => handleSelectOutlet(e.target.value)}
              >
                {outlets
                  ?.filter((o) => o.active)
                  .map((outlet) => (
                    <MenuItem key={outlet.id} value={outlet.id}>
                      {outlet.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          <FAB color="primary" sx={{ opacity: 0.5, position: 'absolute', bottom: '1rem', right: '1rem'}}>
            <IconButton sx={{color: '#fff'}} onClick={() => setSearchDrawer(!searchDrawer)}>
              <SearchSharp/>
            </IconButton>
          </FAB>
          <SearchDrawer
            open={searchDrawer}
            handleClose={() => setSearchDrawer(false)}
            setAppointment={(a) => _setSelectedAppointment(a)}
            customers={customerResults}
            setCustomers={setCustomerResults}
            customer={customer}
            setCustomer={setCustomer}
            appointments={customerAppointments}
            setAppointments={setCustomerAppointments}
            setSale={_setSale}
          />
          <Calendar
            events={calendarAppointments}
            data={calendarAppointments}
            addNew={addNewItem}
            listView={() => setShowCalendar(false)}
            setAppointment={(a) => setAppointmentById(a)}
            employees={technicianOptions}
            setView={setView}
            view={view}
            onDateChange={onDateChange}
          />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box
        sx={{
          width: "600px",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showNew && (
          <AppointmentForm
            customers={customers}
            categories={categories}
            handleClose={() => setShowNew(false)}
            data={newAppointment}
            handleChange={handleNewAppointmentChange}
            handleAddService={handleAddService}
            handleServiceChange={handleServiceChange}
            handleDeleteService={handleDeleteService}
            technicians={technicianOptions}
            handleSubmit={handleSubmit}
            currency={outlet?.city?.country?.currency_short}
          />
        )}
        {selectedAppointment && (
          <AppointmentForm
            edit={true}
            customers={customers}
            categories={categories}
            handleClose={() => _setSelectedAppointment(null)}
            data={selectedAppointment}
            technicians={technicianOptions}
            handleChange={handleEditAppointmentChange}
            handleAddService={handleAddServiceEdit}
            handleServiceChange={handleUpdateServiceChange}
            handleDeleteService={handleDeleteServiceEdit}
            handleSubmit={handleEditSubmit}
            currency={outlet?.city?.country?.currency_short}
          />
        )}
        <Typography sx={{ width: "100%" }}>Appointments</Typography>
        {appointments ? (
          <Box width="100%">
            <Button
              variant="standard"
              onClick={() => setShowNew(true)}
              sx={{ float: "right" }}
            >
              Add
            </Button>
            <Button
              variant="standard"
              onClick={() => setShowCalendar(true)}
              sx={{ float: "right" }}
            >
              Calendar
            </Button>
            <TableContainer sx={{ width: "100%" }} component={Paper}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow key={"head"}>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Customer
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Phone
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Schedule
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Total
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointments?.map((c) => (
                    <TableRow
                      key={c.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {c?.customer?.firstname} {c?.customer?.lastname}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {c?.customer?.phone}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {c?.date} {c?.time}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {c?.amount}
                      </TableCell>
                      <TableCell sx={{ padding: "0px 16px" }} align="right">
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => _setSelectedAppointment(c)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => handleDelete(c.id)}
                        >
                          <TrashIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Loader />
        )}
      </Box>
    </Container>
  );
};

export default Appointments;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainContainer from "../../sharedComponents/Container";
import {
    Button,
    Typography,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Stack,
    Pagination,
} from "@mui/material";
import { selectOutlet, getOutlets } from "../../redux/outlets/outlets.actions";
import ExpenseForm from "../../components/expenses/ExpenseForm";
import ExpensesTable from "../../components/expenses/ExpenseTable";
import ConfirmDelete from "../../components/expenses/ConfirmDelete";
import {
    addTransaction,
    getTransactions,
    updateTransaction,
    deleteTransaction,
} from "../../redux/expenses/expenses.actions";
import Loader from "../../sharedComponents/Loader";

const Expenses = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const outletId = useSelector((state) => state.outlets.outletId);
    const currency = useSelector((state) => state.outlets.outlet?.currency);
    const outlets = useSelector((state) => state.outlets.outlets);
    const [showNew, setShowNew] = useState(false);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editTransaction, setEditTransaction] = useState(null);
    const [transactionToDelete, setTransactionToDelete] = useState(null);

    const handleSelectOutlet = (outletId) => {
        dispatch(selectOutlet(outletId));
        // eslint-disable-next-line
    };

    useEffect(() => {
        if (!outlets && user?.role === "admin") dispatch(getOutlets());
        // eslint-disable-next-line
    }, [outlets]);

    useEffect(() => {
        user?.outlets && handleSelectOutlet(user.outlets[0]?.id);
        // eslint-disable-next-line
    }, [user]);

    const addNewTransaction = (transaction) => {
        dispatch(
            addTransaction(outletId, transaction, () => {
                _getTransactions();
                setShowNew(false);
            })
        );
    };

    const _setTransactions = (d) => {
        setTransactions(d.objects);
        setPage(d.page);
        setPages(d.pages);
        setLoading(false);
    };

    const _getTransactions = () => {
        setLoading(true);
        dispatch(getTransactions(outletId, (d) => _setTransactions(d), page));
    };

    const _updateTransaction = (t) => {
      dispatch(updateTransaction(outletId, t.id, t, () => {
        setEditTransaction(null);
        _getTransactions();
      }));
    }

    const _deleteTransaction = (id) => {
      dispatch(deleteTransaction(outletId, id, () => {
        setTransactionToDelete(null);
        _getTransactions();
      }))
    }

    useEffect(() => {
        outletId && _getTransactions();
        // eslint-disable-next-line
    }, [page, outletId]);

    if (!outletId && user.role === "admin") {
        return (
            <MainContainer>
                <Box sx={{ width: "100%", marginBottom: "20px" }}>
                    <FormControl sx={{ width: "500px", maxWidth: "100%" }}>
                        <InputLabel id="outlet-label">Outlet</InputLabel>
                        <Select
                            labelId="outlet-label"
                            id="outlet"
                            value=""
                            label="Outlet"
                            onChange={(e) => handleSelectOutlet(e.target.value)}
                        >
                            {outlets
                                ?.filter((o) => o.active)
                                .map((outlet) => (
                                    <MenuItem key={outlet.id} value={outlet.id}>
                                        {outlet.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
                <Typography>
                    No outlet selected. Please select an outlet to view sales.
                </Typography>
            </MainContainer>
        );
    }

    return (
        <MainContainer>
            {user.role === "admin" && (
                <FormControl sx={{ width: "500px", maxWidth: "100%" }}>
                    <InputLabel id="outlet-label">Outlet</InputLabel>
                    <Select
                        labelId="outlet-label"
                        id="outlet"
                        value={outletId}
                        label="Outlet"
                        onChange={(e) => handleSelectOutlet(e.target.value)}
                    >
                        {outlets
                            ?.filter((o) => o.active)
                            .map((outlet) => (
                                <MenuItem key={outlet.id} value={outlet.id}>
                                    {outlet.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            )}
            <Box
                sx={{
                    width: "900px",
                    maxWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "20px",
                }}
            >
                <Typography sx={{ width: "100%" }}>Expenses</Typography>
                <Box width="100%">
                    <Button
                        variant="standard"
                        onClick={() => {
                            setShowNew(true);
                        }}
                        sx={{ float: "right" }}
                    >
                        Add
                    </Button>
                </Box>
                {loading ? (
                    <Loader />
                ) : transactions?.length > 0 ? (
                    <ExpensesTable
                        data={transactions}
                        page={page}
                        pages={pages}
                        setPage={setPage}
                        role={user.role}
                        currency={currency}
                        setEdit={setEditTransaction}
                        setDelete={setTransactionToDelete}
                    />
                ) : (
                    <Typography>
                        No records found. Click Add button to create new
                        transaction.
                    </Typography>
                )}
                {pages > 1 && (
                    <Stack mt={2} alignItems="center">
                        <Pagination
                            count={pages}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={(_, n) => setPage(n)}
                        />
                    </Stack>
                )}
            </Box>
            {showNew && (
                <ExpenseForm
                    open={showNew}
                    handleClose={() => setShowNew(false)}
                    handleSubmit={addNewTransaction}
                    currency={currency}
                />
            )}
            {editTransaction && (
                <ExpenseForm
                    open={editTransaction}
                    edit={true}
                    transaction={editTransaction}
                    handleClose={() => setEditTransaction(null)}
                    handleSubmit={_updateTransaction}
                    currency={currency}
                />
            )}
            {
              transactionToDelete && (
                <ConfirmDelete
                  open={transactionToDelete}
                  handleClose={() => setTransactionToDelete(null)}
                  id={transactionToDelete}
                  handleDelete={_deleteTransaction}
                />
              )
            }
        </MainContainer>
    );
};

export default Expenses;

import OwnersActionType from "./owners.types";

export const addOwner = (owner, callback = () => {}) => ({
  type: OwnersActionType.ADD_OWNER,
  owner,
  callback,
});

export const getOwners = (page=1, size=100) => ({
  type: OwnersActionType.GET_OWNERS,
  page,
  size,
});

export const updateOwner = (owner, callback = () => {}) => ({
  type: OwnersActionType.UPDATE_OWNER,
  owner,
  callback,
});

export const setOwners = (payload) => ({
  type: OwnersActionType.SET_OWNERS,
  payload,
});

export const getOwnersOptions = () => ({
  type: OwnersActionType.GET_OWNERS_OPTIONS
});

export const setOwnersOptions = (payload) => ({
  type: OwnersActionType.SET_OWNERS_OPTIONS,
  payload
});

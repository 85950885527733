import React from "react";
import Container from "../../sharedComponents/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Notifications = () => {
  return (
    <Container>
      <Box
        sx={{
          width: "600px",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ width: "100%" }}>Your notification goes here...</Typography>
      </Box>
    </Container>
  );
};

export default Notifications;

import AppointmentsActionType from "./appointments.types";

export const getAppointments = (outletId, view = 'day', date = '') => ({
  type: AppointmentsActionType.GET_APPOINTMENTS,
  outletId,
  view,
  date,
});

export const setAppointments = (payload) => ({
  type: AppointmentsActionType.SET_APPOINTMENTS,
  payload,
});

export const createAppointment = (
  outletId,
  appointment,
  callback = () => {}
) => ({
  type: AppointmentsActionType.CREATE_APPOINTMENT,
  outletId,
  appointment,
  callback,
});

export const updateAppointment = (
  outletId,
  appointment,
  callback = () => {}
) => ({
  type: AppointmentsActionType.UPDATE_APPOINTMENT,
  outletId,
  appointment,
  callback,
});

export const deleteAppointment = (
  outletId,
  appointmentId,
  view,
  date,
  callback = () => {}
) => ({
  type: AppointmentsActionType.DELETE_APPOINTMENT,
  outletId,
  appointmentId,
  view,
  date,
  callback
});

export const addPayments = (
  outletId,
  appointmentId,
  payments,
  callback = () => {}
) => ({
  type: AppointmentsActionType.ADD_PAYMENTS,
  outletId,
  appointmentId,
  payments,
  callback,
});

export const deletePayment = (
  outletId,
  appointmentId,
  paymentId,
  callback = () => {}
) => ({
  type: AppointmentsActionType.DELETE_PAYMENT,
  outletId,
  appointmentId,
  paymentId,
  callback,
});

export const getNotes = (
  outletId,
  appointmentId,
  callback = () => {}
) => ({
  type: AppointmentsActionType.GET_NOTES,
  outletId,
  appointmentId,
  callback
});

export const addNotes = (
  outletId,
  appointmentId,
  note,
  callback = () => {}
) => ({
  type: AppointmentsActionType.ADD_NOTES,
  outletId,
  appointmentId,
  note,
  callback
});

export const getCustomerAppointments = (outletId, customerId, callback) => ({
  type: AppointmentsActionType.GET_CUSTOMER_APPOINTMENTS,
  outletId,
  customerId,
  callback
});

export const getAppointment = (outletId, customerId, appointmentId, callback) => ({
  type: AppointmentsActionType.GET_CUSTOMER_APPOINTMENTS,
  outletId,
  customerId,
  appointmentId,
  callback
});

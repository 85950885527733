const ServicesActionType = {
    GET_SERVICES: "GET_SERVICES",
    SET_SERVICES: "SET_SERVICES",
    GET_SERVICES_OPTIONS: "GET_SERVICES_OPTIONS",
    SET_SERVICES_OPTIONS: "SET_SERVICES_OPTIONS",
    ADD_SERVICE: "ADD_SERVICE",
    UPDATE_SERVICE: "UPDATE_SERVICE",
    ALTER_SERVICE: "ALTER_SERVICE",
    RESET_SERVICES: "RESET_SERVICES",
}

export default ServicesActionType;

import axios from "axios"
const { REACT_APP_API_URL } = process.env

const headers = {
}

const _axios = axios.create({
    baseURL: REACT_APP_API_URL,
    timeout: 30000
})

const get_headers = () => {
    const h = {}
    Object.keys(headers).forEach(k => {
        h[k] = headers[k]
    })
    const token = localStorage.getItem("finoppal_token")
    if (token)
        h['Authorization'] = `Bearer ${token}`
    return h
}

export const GET = async (url, params={}) => {
    let response = {}
    await _axios.get(url, {
        params,
        headers: get_headers()
    })
    .then(({data}) => {
        response = data
    })
    .catch((e) => {
        response = e.response.data
    })
    return response
}

export const POST = async (url, data=null) => {
    let response = {}
    await _axios.post(url, data, {
        headers: get_headers()
    })
    .then(({data}) => {
        response = data
    })
    .catch((e) => {
        response = e.response.data
    })
    return response
}

export const PUT = async (url, data=null) => {
    let response = {}
    await _axios({method: 'put', url, data, headers: get_headers()})
    .then(({data}) => response = data)
    .catch((e) => response = e.response.data)
    return response
}

export const DELETE = async (url) => {
    let response = {}
    await _axios({method: 'delete', url, headers: get_headers()})
    .then(({data}) => response = data)
    .catch((e) => response = e.response.data)
    return response
}

export const DOWNLOAD = async (url, data = null) => {
    try {
      const response = await _axios({
        method: 'post',
        url,
        data,
        headers: get_headers(),
        responseType: 'blob',
      });
  
      return response.data;
    } catch (error) {
      throw error;
    }
  }
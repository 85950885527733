import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as CategoriesActions from "../../redux/categories/categories.actions";
import Container from "../../sharedComponents/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/EditNote";
import ToggleOff from "@mui/icons-material/ToggleOff";
import ToggleOn from "@mui/icons-material/ToggleOn";
import Loader from "../../sharedComponents/Loader";

const Categories = () => {
  const [showNew, setShowNew] = useState(false);
  const [newCat, setNewCat] = useState({ value: "" });
  const [editCat, setEditCat] = useState({});
  const categories = useSelector((state) => state.categories.categories);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!categories) dispatch(CategoriesActions.getCategories(1, 100));
    // eslint-disable-next-line
  }, []);

  const setNewCategory = ({ target }) => {
    const { name, value } = target;
    setNewCat({
      ...newCat,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    dispatch(
      CategoriesActions.addCategory(newCat, () => {
        setShowNew(false);
        setNewCat({ value: "" });
      })
    );
  };

  const updateCategory = (data) => {
    dispatch(CategoriesActions.updateCategory(data, ()=>setEditCat({})));
  };

  return (
    <Container>
      <Box sx={{ width: "100%", maxWidth: "500px", }}>
        <Typography>Categories</Typography>
        {!categories && <Loader/>}
        {categories && <Box>
        <Button
          variant="standard"
          onClick={() => {
            setShowNew(true);
          }}
          sx={{ float: "right" }}
        >
          Add
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow key={"head"}>
                <TableCell
                  sx={{
                    padding: "10px 16px",
                    color: (theme) => theme.palette.text.secondary,
                  }}
                  align="left"
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px 16px",
                    color: (theme) => theme.palette.text.secondary,
                  }}
                  align="right"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showNew && (
                <AddCategory
                  cat={newCat}
                  handleChange={setNewCategory}
                  onSubmit={handleSubmit}
                  onCancel={() => setShowNew(false)}
                />
              )}
              {categories?.map((c) =>
                editCat.id === c.id ? (
                  <TableRow key={c.id}>
                    <TableCell
                      sx={{ padding: "0px 16px" }}
                      align="left"
                      scope="row"
                    >
                      <TextField autoFocus variant="standard" name="value" value={editCat.value} onChange={(e) => setEditCat({...editCat, value: e.target.value})}/>
                    </TableCell>
                    <TableCell sx={{ padding: "0px 16px" }} align="right">
                      <IconButton disabled={(!editCat.value)} onClick={() => updateCategory(editCat)}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => setEditCat({})}
                      >
                        <CancelIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={c.id}>
                    <TableCell
                      sx={{ padding: "0px 16px" }}
                      align="left"
                      scope="row"
                    >
                      {c.value}
                    </TableCell>
                    <TableCell sx={{ padding: "0px 16px" }} align="right">
                      <IconButton onClick={() => setEditCat(c)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          updateCategory({ ...c, active: !c.active });
                        }}
                      >
                        {c.active ? (
                          <ToggleOn color="primary" />
                        ) : (
                          <ToggleOff />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>}
      </Box>
    </Container>
  );
};

const AddCategory = ({ onCancel, cat, handleChange, onSubmit }) => {
  return (
    <TableRow key={"new"}>
      <TableCell sx={{ padding: "15px 16px" }} align="left" scope="row">
        <TextField
          autoFocus
          variant="standard"
          sx={{ width: 150 }}
          value={cat.value}
          name="value"
          onChange={handleChange}
          placeholder="Name"
        />
      </TableCell>
      <TableCell sx={{ padding: "15px 16px" }} align="right">
        <IconButton onClick={onSubmit} disabled={!cat.value}>
          <SaveIcon />
        </IconButton>
        <IconButton onClick={onCancel}>
          <CancelIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default Categories;

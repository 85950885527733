const ProductsActionType = {
    GET_PRODUCTS: "GET_PRODUCTS",
    SET_PRODUCTS: "SET_PRODUCTS",
    ADD_PRODUCT: "ADD_PRODUCT",
    UPDATE_PRODUCT: "UPDATE_PRODUCT",
    ALTER_PRODUCT: "ALTER_PRODUCT",
    RESET_PRODUCTS: "RESET_PRODUCTS",
    GET_PRODUCTS_OPTIONS: "GET_PRODUCTS_OPTIONS",
    SET_PRODUCTS_OPTIONS: "SET_PRODUCTS_OPTIONS"
}

export default ProductsActionType;

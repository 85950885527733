import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../sharedComponents/Container";
import {
    Box,
    Typography,
    Grid,
    TextField,
    MenuItem,
    Button,
} from "@mui/material";
import { getCountries } from "../../redux/countries/countries.actions";
import Loader from "../../sharedComponents/Loader";
import { getReport, setReport, getOutlets } from "../../redux/reports/reports.actions";
import ReportsTable from "../../components/reports/ReportsTable";
import { formatDate } from "../../utils/date";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const Reports = () => {
    const dispatch = useDispatch();
    const [country, setCountry] = useState("");
    const [reportType, setReportType] = useState("");
    const [displayRT, setDisplayRT] = useState("");
    const [loader, setLoader] = useState(false);

    const user = useSelector((state) => state.auth.user);
    const userRole = user?.role;
    const countries = useSelector((state) => state.countries.countries);
    const [currency, setCurrency] = useState("");
    const [displayCurreny, setDisplayCurrency] = useState("");
    const report = useSelector((state) => state.reports.report);
    const [filename, setFilename] = useState("finoppal.csv");
    const [outlets, setOutlets] = useState([]);
    const [outlet, setOutlet] = useState("");
    const [month, setMonth] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return () => dispatch(setReport(null));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!countries) dispatch(getCountries());
        // eslint-disable-next-line
    }, [countries]);

    const types = userRole === 'manager' ? 
        {
            "Daily Sales": "DAILY"
        } :
        {
            "Monthly Sales": "MONTHLY",
            "Daily Sales": "DAILY",
            Quarterly: "QUARTERLY",
            Products: "PRODUCTS",
            Services: "SERVICES",
        };

    useEffect(() => {
        if (country) {
            if (userRole === 'admin') {
                let _country = countries.find((c) => c.id === country);
                setCurrency(_country?.currency_short);
                setLoading(true);
                dispatch(getOutlets(country, (d)=>{
                    setOutlets(d);
                    setLoading(false);
                }))
                setOutlet('all');
            } else {
                setOutlets(user.outlets);
                setCurrency(user.outlets[0].city.country.currency_short);
            }
        }
        // eslint-disable-next-line
    }, [country]);

    const reportTypesDropdown = () => {
        let dd = [];
        for (const k in types) {
            dd.push(
                <MenuItem key={types[k]} value={types[k]}>
                    {k}
                </MenuItem>
            );
        }
        return dd;
    };

    const handleSubmit = () => {
        let filter = {
            country_id: country,
            type: reportType,
        };
        if (outlet && outlet !== 'all')
            filter['outlet_id'] = outlet;
        if ((reportType === "PRODUCTS" || reportType === "SERVICES" || reportType === "DAILY") && month !== '')
            filter['month'] = month;
        setDisplayRT(reportType);
        setDisplayCurrency(currency);
        setLoader(true);
        dispatch(getReport(filter, () => setLoader(false)));
        setFilename(`finoppal_${countries?.find(x=>x.id===country)?.value}_${reportType?.toLowerCase()}_${formatDate()}.csv`);
    };

    const handleMonthChange = (d) => {
        setMonth(`${d['$y']}, ${d['$M']+1}`)
    }

    useEffect(() => {
        if (user?.role !== 'admin') {
            setCountry(user?.outlets?.[0]?.city?.country?.id)
            setOutlet(user?.outlets?.[0]?.id)
        }
    }, [user]);

    return (
        <Container>
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1500px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography sx={{ width: "100%" }}>Reports</Typography>
                <Grid
                    container
                    spacing={3}
                    sx={{ mt: 1, width: "100%", justifyContent: "center" }}
                >
                    {userRole === "admin" && <Grid item xs={12} sm={6} md={3} lg={2}>
                        <TextField
                            sx={{ width: "100%", textAlign: "left" }}
                            select
                            variant="standard"
                            label="Country"
                            required
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            placeholder="Country"
                        >
                            {countries ? (
                                countries.map((c) => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.value}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="loading" key="loading">
                                    Loading...
                                </MenuItem>
                            )}
                        </TextField>
                    </Grid>}
                    {userRole === "admin" && <Grid item xs={12} sm={6} md={3} lg={2}>
                        <TextField
                            sx={{ width: "100%", textAlign: "left" }}
                            select
                            variant="standard"
                            label="Outlet"
                            value={loading ? "loading" : outlet}
                            onChange={(e) => setOutlet(e.target.value)}
                            placeholder="Outlet"
                            disabled={!outlets.length}
                        >
                            <MenuItem value="all" key="all">
                                    All Outlets
                            </MenuItem>
                            {loading ? (
                                <MenuItem value="loading" key="loading">
                                    Loading...
                                </MenuItem>
                            ) : (
                                outlets.map((c) => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.name}
                                    </MenuItem>
                                ))
                            )}
                        </TextField>
                    </Grid>}
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                        <TextField
                            sx={{ width: "100%", textAlign: "left" }}
                            select
                            variant="standard"
                            label="Report Type"
                            required
                            value={reportType}
                            onChange={(e) => setReportType(e.target.value)}
                            placeholder="Report Type"
                        >
                            {reportTypesDropdown().map((m) => m)}
                        </TextField>
                    </Grid>
                    {(reportType === "PRODUCTS" || reportType === "SERVICES" || reportType === "DAILY") && <Grid item xs={12} sm={6} md={3} lg={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={'Month'}
                                views={['year', 'month']}
                                disableFuture={true}
                                variant="standard"
                                slots={{textField: TextField}}
                                onChange={handleMonthChange}
                                slotProps={{
                                    textField: {
                                        variant: "standard"
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>}
                    <Grid
                        item
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                        }}
                    >
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            disabled={!(reportType && country)}
                        >
                            Apply
                        </Button>
                    </Grid>
                </Grid>
                {loader ? (
                    <Loader />
                ) : report ? (
                    <ReportsTable
                        report={report}
                        reportType={displayRT}
                        currency={displayCurreny}
                        filename={filename}
                        country={country}
                        outlet={outlet}
                    />
                ) : (
                    <Typography mt={5}>
                        Select a {userRole === "admin" ? " country and " : ""} report type and hit apply
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default Reports;

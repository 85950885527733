import React, { useState } from "react";
import {
    Modal,
    Box,
    Grid,
    TextField,
    InputAdornment,
    Button,
    Typography,
} from "@mui/material";
import Dropdown from "../../sharedComponents/Dropdown";
import { formatDate } from "../../utils/date";
import { paymentMethods } from "../../utils/constants";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxWidth: "calc(100% - 30px)",
    maxHeight: "calc(100vh)",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "1px solid #ccc",
    p: 4,
    pt: 2,
    pb: 2,
    borderRadius: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    boxShadow: 24,
};

const newTransaction = {
    type: "",
    item: "",
    date: formatDate(),
    amount: 0,
    payment_method: "",
};

const ExpenseForm = ({
    open,
    edit = false,
    handleClose,
    readOnly,
    currency = "TST1",
    transaction = newTransaction,
    handleSubmit = () => {},
}) => {
    const [lTransaction, setTransaction] = useState(transaction);
    const [saving, setSaving] = useState(false);

    const handleChange = (field, e) => {
        setTransaction({
            ...lTransaction,
            [field]: e.target.value,
        });
    };

    const saveDisabled = () => {
        return (
            saving ||
            readOnly ||
            !Object.values(lTransaction).every((value) => value)
        );
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style} width={"100%"}>
                <Typography variant="h6" sx={{ p: 2 }}>
                    {readOnly ? "View" : edit ? "Edit" : "Add"} Transaction
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} xl={4}>
                        <Dropdown
                            name={`type`}
                            value={lTransaction?.type}
                            options={[
                                { id: "INCOME", value: "Income" },
                                { id: "EXPENSE", value: "Expense" },
                            ]}
                            handleChange={(e) => handleChange("type", e)}
                            label="Type"
                            disabled={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={4}>
                        <TextField
                            variant={"standard"}
                            type="date"
                            required
                            value={lTransaction.date}
                            onChange={(e) => handleChange("date", e)}
                            sx={{ width: "100%" }}
                            label="Date"
                            placeholder="Date"
                            disabled={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={4}>
                        <TextField
                            variant={"standard"}
                            type="text"
                            required
                            value={lTransaction.item}
                            onChange={(e) => handleChange("item", e)}
                            sx={{ width: "100%" }}
                            label="Item"
                            placeholder="Item"
                            disabled={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={4}>
                        <TextField
                            variant={"standard"}
                            required
                            type="number"
                            value={lTransaction.amount}
                            onChange={(e) => handleChange("amount", e)}
                            sx={{ width: "100%" }}
                            label="Amount"
                            placeholder="Amount"
                            disabled={readOnly}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {currency}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={4}>
                        <Dropdown
                            name={`payment_method`}
                            value={lTransaction?.payment_method}
                            options={paymentMethods}
                            handleChange={(e) =>
                                handleChange("payment_method", e)
                            }
                            label="Payment Method"
                            disabled={readOnly}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <>
                            <Button
                                disabled={saveDisabled()}
                                onClick={() => {
                                    setSaving(true);
                                    handleSubmit(lTransaction);
                                }}
                            >
                                {saving ? "Saving..." : "Save"}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default ExpenseForm;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../sharedComponents/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/EditNote";
import ToggleOff from "@mui/icons-material/ToggleOff";
import ToggleOn from "@mui/icons-material/ToggleOn";
import { selectOutlet, getOutlets } from "../../redux/outlets/outlets.actions";
import {
  addCustomer,
  getCustomers,
  updateCustomer,
} from "../../redux/customers/customers.actions";
import Loader from "../../sharedComponents/Loader";
import CustomerForm from "../../components/customers/CustomerForm";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

const Customers = () => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers.customers);
  const page = useSelector((state) => state.customers.page);
  const pages = useSelector((state) => state.customers.pages);
  const [showNew, setShowNew] = useState(false);
  const [newCustomer, setNewCustomer] = useState({});
  const [customer, setCustomer] = useState(null);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const outlets = useSelector((state) => state.outlets.outlets);
  const user = useSelector((state) => state.auth.user);
  const [searchInput, setSearchInput] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchIconClicked, setSearchIconClicked] = useState(false);

  useEffect(() => {
    user?.outlets && handleSelectOutlet(user.outlets[0]?.id);
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (selectedOutlet) {
      dispatch(getCustomers(selectedOutlet,searchInput, currentPage));
    }
    setSearchIconClicked(false);
    // eslint-disable-next-line
  }, [selectedOutlet, currentPage, searchIconClicked]);

  const handleChange = ({ target: { name, value } }) => {
    setNewCustomer({
      ...newCustomer,
      [name]: value,
    });
  };

  const handleEditChange = ({ target: { name, value } }) => {
    setCustomer({
      ...customer,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addCustomer(selectedOutlet, newCustomer, () => {
        setShowNew(false);
        setNewCustomer({});
      })
    );
    dispatch(getCustomers(selectedOutlet,searchInput, currentPage));
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCustomer(selectedOutlet, customer, () => setCustomer(null)));
  };

  const handleSelectOutlet = (outletId) => {
    setSelectedOutlet(outletId);
    dispatch(selectOutlet(outletId));
    dispatch(getCustomers(outletId));
  };

  const handlePageChange = (p) => {
    if (p === page) {
      return;
    }
    setCurrentPage(p);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchIconClick = (e) => {
    e.preventDefault()
    setSearchIconClicked(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (!outlets && user?.role === "admin") dispatch(getOutlets());
    // eslint-disable-next-line
  }, [outlets]);

  if (!selectedOutlet && user.role === "admin") {
    return (
      <Container>
        <Box sx={{ width: "100%", marginBottom: "20px" }}>
          <FormControl sx={{ width: "500px", maxWidth: "100%" }}>
            <InputLabel id="outlet-label">Outlet</InputLabel>
            <Select
              labelId="outlet-label"
              id="outlet"
              value=""
              label="Outlet"
              onChange={(e) => handleSelectOutlet(e.target.value)}
            >
              {outlets?.filter((o) => o.active).map((outlet) => (
                <MenuItem key={outlet.id} value={outlet.id}>
                  {outlet.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Typography>
          No outlet selected. Please select an outlet to view customers.
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Box sx={{ width: "100%", marginBottom: "20px" }}>
        {user.role === "admin" && (
          <FormControl sx={{ width: "500px", maxWidth: "100%" }}>
            <InputLabel id="outlet-label">Outlet</InputLabel>
            <Select
              labelId="outlet-label"
              id="outlet"
              value={selectedOutlet || ""}
              label="Outlet"
              onChange={(e) => handleSelectOutlet(e.target.value)}
            >
              {outlets?.filter((o) => o.active).map((outlet) => (
                <MenuItem key={outlet.id} value={outlet.id}>
                  {outlet.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      {showNew && (
        <CustomerForm
          open={showNew}
          handleClose={() => setShowNew(false)}
          handleChange={handleChange}
          data={newCustomer}
          handleSubmit={handleSubmit}
        />
      )}
      {customer && (
        <CustomerForm
          open={customer ? true : false}
          handleClose={() => setCustomer(null)}
          handleChange={handleEditChange}
          data={customer}
          handleSubmit={handleEditSubmit}
          edit={true}
        />
      )}
      <Box
        sx={{
          width: "600px",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ width: "100%" }}>Customers</Typography>
        {customers ? (
          <Box width="100%">
            <Button
              variant="standard"
              onClick={() => setShowNew(true)}
              sx={{ float: "right" }}
            >
              Add
            </Button>
            <form onSubmit={(e) => {handleSearchIconClick(e)}}>
            <TextField
              type="text"
              placeholder="Search customers"
              value={searchInput}
              onChange={handleSearchInputChange}
              style={{ width: "100%", padding: "1rem", marginBottom: "1rem" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={(e) =>handleSearchIconClick(e)}>
                      <SearchIcon />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            </form>
            <TableContainer sx={{ width: "100%" }} component={Paper}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow key={"head"}>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Gender
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers?.map((c) => (
                    <TableRow
                      key={c.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {`${c.firstname} ${c.lastname}`}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {c.gender[0].toUpperCase() +
                          c.gender.slice(1).toLowerCase()}
                      </TableCell>
                      <TableCell sx={{ padding: "0px 16px" }} align="right">
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => setCustomer(c)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() =>
                            dispatch(
                              updateCustomer(selectedOutlet, {
                                id: c.id,
                                active: !c.active,
                              })
                            )
                          }
                        >
                          {c.active ? (
                            <ToggleOn color="primary" />
                          ) : (
                            <ToggleOff />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack mt={2} alignItems="center">
              <Pagination
                count={pages}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={(_, n) => handlePageChange(n)}
              />
            </Stack>
          </Box>
        ) : (
          <Loader />
        )}
      </Box>
    </Container>
  );
};

export default Customers;

import OwnersActionType from "./owners.types";
import * as OwnersActions from "./owners.actions";
import { GET, POST, PUT } from "../../axios";
import { takeEvery, put } from "redux-saga/effects";
import urls from "../urls";
import { setError, setSuccess } from "../actions/action.actions";

function* addOwnerSaga({ owner, callback }) {
  const data = yield POST(`${urls.owners}`, owner);
  if (data?.id) {
    callback();
    yield put(setSuccess("Owner added succesfully!"));
    yield put(OwnersActions.getOwners(1, 100));
  } else {
    yield setError("Something went wrong");
  }
}

function* getOwnersSaga({ page, size }) {
  const data = yield GET(urls.owners, { page, size });
  if (data) {
    yield put(OwnersActions.setOwners(data));
  }
}

function* updateOwnerSaga({ owner, callback }) {
  const data = yield PUT(`${urls.owners}/${owner.id}`, owner);
  if (data) {
    callback();
    yield put(setSuccess("Owner updated succesfully!"));
    yield put(OwnersActions.getOwners(1, 100));
  }
}

function* getOwnersOptionsSaga() {
  const data = yield GET(urls.owners, { dropdown: true });
  if (data) {
    yield put(OwnersActions.setOwnersOptions(data.owners));
  }
}

export function* ownersSagas() {
  yield takeEvery(OwnersActionType.GET_OWNERS, getOwnersSaga);
  yield takeEvery(OwnersActionType.ADD_OWNER, addOwnerSaga);
  yield takeEvery(OwnersActionType.UPDATE_OWNER, updateOwnerSaga);
  yield takeEvery(OwnersActionType.GET_OWNERS_OPTIONS, getOwnersOptionsSaga);
}

import React, { useEffect, useState } from "react";
import Container from "../../sharedComponents/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import * as CountriesActionType from "../../redux/countries/countries.actions";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/EditNote";
import ToggleOff from "@mui/icons-material/ToggleOff";
import ToggleOn from "@mui/icons-material/ToggleOn";
import Loader from "../../sharedComponents/Loader";

const Countries = () => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.countries.countries);
  const [showNew, setShowNew] = useState(false);
  const [newCountry, setNewCountry] = useState({
    value: "",
    currency: "",
    currency_short: "",
    currency_symbol: "",
  });
  const [editCountry, setEditCountry] = useState({});

  useEffect(() => {
    if (!countries) dispatch(CountriesActionType.getCountries());
    // eslint-disable-next-line
  }, [countries]);

  const _setEditCountry = (e) => {
    const { name, value } = e.target;
    setEditCountry({
      ...editCountry,
      [name]: value,
    });
  };

  const handleNewCountryChange = (e) => {
    const { name, value } = e.target;
    setNewCountry({
      ...newCountry,
      [name]: value,
    });
  };

  const submitNewCountry = (e) => {
    dispatch(
      CountriesActionType.addCountry(newCountry, () => {
        setNewCountry({});
        setShowNew(false);
      })
    );
  };

  const updateCountry = (data) => {
    dispatch(
      CountriesActionType.updateCountry(data, () => {
        setEditCountry({});
      })
    );
  };

  return (
    <Container>
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Typography sx={{ width: "100%" }}>Countries</Typography>
        {!countries && <Loader />}
        {countries && (
          <Box>
            <Button
              variant="standard"
              onClick={() => {
                setShowNew(true);
              }}
              sx={{ float: "right" }}
            >
              Add
            </Button>
            <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
              <Table>
                <TableHead>
                  <TableRow key={"gerw"}>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Currency
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Short
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Symbol
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {showNew && (
                    <AddCountry
                      data={newCountry}
                      onSubmit={submitNewCountry}
                      onCancel={() => setShowNew(false)}
                      onChange={handleNewCountryChange}
                    />
                  )}
                  {countries &&
                    countries.map((c) =>
                      c.id === editCountry?.id ? (
                        <TableRow
                          key={c.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ padding: "15px 16px" }}
                            align="left"
                            scope="row"
                          >
                            <TextField
                              autoFocus
                              name="value"
                              variant="standard"
                              sx={{ width: 75 }}
                              value={editCountry.value}
                              onChange={_setEditCountry}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ padding: "15px 16px" }}
                            align="right"
                          >
                            <TextField
                              name="currency"
                              variant="standard"
                              sx={{ width: 50 }}
                              value={editCountry.currency}
                              onChange={_setEditCountry}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ padding: "15px 16px" }}
                            align="right"
                          >
                            <TextField
                              name="currency_short"
                              variant="standard"
                              sx={{ width: 35 }}
                              value={editCountry.currency_short}
                              onChange={_setEditCountry}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ padding: "15px 16px" }}
                            align="right"
                          >
                            <TextField
                              name="currency_symbol"
                              variant="standard"
                              sx={{ width: 20 }}
                              value={editCountry.currency_symbol}
                              onChange={_setEditCountry}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ padding: "15px 16px" }}
                            align="right"
                          >
                            <IconButton
                              onClick={() => updateCountry(editCountry)}
                              sx={{ p: 0 }}
                            >
                              <SaveIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => setEditCountry({})}
                              sx={{ p: 0 }}
                            >
                              <CancelIcon color="primary" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow
                          key={c.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          hover={true}
                        >
                          <TableCell
                            sx={{ padding: "15px 16px" }}
                            align="left"
                            scope="row"
                          >
                            {c.value}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "15px 16px" }}
                            align="right"
                          >
                            {c.currency}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "15px 16px" }}
                            align="right"
                          >
                            {c.currency_short}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "15px 16px" }}
                            align="right"
                          >
                            {c.currency_symbol}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "15px 16px" }}
                            align="right"
                          >
                            <IconButton
                              onClick={() => setEditCountry(c)}
                              sx={{ p: 0 }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                updateCountry({ ...c, active: !c.active })
                              }
                              sx={{ p: 0 }}
                            >
                              {c.active ? (
                                <ToggleOn color="primary" />
                              ) : (
                                <ToggleOff />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Container>
  );
};

const AddCountry = ({ onCancel, data, onChange = () => {}, onSubmit }) => {
  return (
    <TableRow key={"0000"}>
      <TableCell sx={{ padding: "15px 16px" }} align="left" scope="row">
        <TextField
          autoFocus
          name="value"
          variant="standard"
          sx={{ width: 75 }}
          value={data.value}
          onChange={onChange}
          placeholder="Name"
          size="small"
        />
      </TableCell>
      <TableCell sx={{ padding: "15px 16px" }} align="right">
        <TextField
          name="currency"
          variant="standard"
          sx={{ width: 50 }}
          value={data.currency}
          onChange={onChange}
          placeholder="Currency"
          size="small"
        />
      </TableCell>
      <TableCell sx={{ padding: "15px 16px" }} align="right">
        <TextField
          name="currency_short"
          variant="standard"
          sx={{ width: 35 }}
          value={data.currency_short}
          onChange={onChange}
          placeholder="Short"
          size="small"
        />
      </TableCell>
      <TableCell sx={{ padding: "15px 16px" }} align="right">
        <TextField
          name="currency_symbol"
          variant="standard"
          sx={{ width: 20, fontSize: "8px" }}
          value={data.currency_symbol}
          onChange={onChange}
          placeholder="$"
          size="small"
        />
      </TableCell>
      <TableCell sx={{ padding: "15px 16px" }} align="right">
        <IconButton onClick={onSubmit} disabled={!data.value} sx={{ p: 0 }}>
          <SaveIcon />
        </IconButton>
        <IconButton onClick={onCancel} sx={{ p: 0 }}>
          <CancelIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default Countries;

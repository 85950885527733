import "../../smart/styles/smart.default.css";
import React, { useState, useEffect } from "react";
import { Scheduler } from "smart-webcomponents-react/scheduler";

const { SCHEDULER_LICENCE } = process.env;

const CalendarApp = ({
  data = [],
  employees = [],
  addNew = () => {},
  setAppointment = () => {},
  setView = () => {},
  view = 'day',
  onDateChange = () => {},
}) => {

  window.Smart.License = SCHEDULER_LICENCE;
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  const isMobile = width <= 768;

  let views = [
    "day",
    {
        type: "week",
    }
    // {
    //   type: "month",
    //   hideWeekend: true,
    //   groupOrientation: "vertical",
    // },
    // {
    //     type: "agenda",
    //     groupOrientation: "vertical"
    // }
    // {
    //   label: "4 days",
    //   value: "workWeek",
    //   type: "week",
    //   shortcutKey: "X",
    //   hideWeekend: false,
    //   hideNonworkingWeekdays: true,
    // },
  ];

  let firstDayOfWeek = 1;

  let disableDateMenu = false;

  let currentTimeIndicator = true;

  let scrollButtonsPosition = "far";

  let resources = [
    {
      label: "Technician",
      value: "technicianId",
      dataSource: employees,
    },
  ];

  // const getPastThreeWeekdays = (weekday) => {
  //   let weekdays = [];

  //   for (let i = 0; i < 3; i++) {
  //     weekdays.push((weekday - i + 7) % 7);
  //   }

  //   return weekdays;
  // };


  return (
    <div style={{ width: "100%" }}>
      <Scheduler
        id="scheduler"
        dataSource={data}
        view={view}
        views={views}
        firstDayOfWeek={firstDayOfWeek}
        disableDateMenu={disableDateMenu}
        currentTimeIndicator={currentTimeIndicator}
        scrollButtonsPosition={scrollButtonsPosition}
        resources={resources}
        groups={["technicianId"]}
        disableResize={true}
        disableDrag={true}
        hideAllDay={true}
        hourStart={8}
        hourEnd={21}
        onDateChange={(e)=> onDateChange(e)}
        onItemClick={(e) => {
          e.preventDefault()  
          e.stopImmediatePropagation()
          e.stopPropagation()
          setAppointment(e.detail.itemObj.id)
        }}
        disableEventMenu={!isMobile}
        disableViewMenu={true}
        disableWindowEditor={true}
        disableContextMenu={true}
        disableSelection={true}
        onClick={(e) => {
            if (e.target.className === 'smart-scheduler-cell'){
                let d = e.srcElement.attributes;
                let date = new Date(d.getNamedItem('date')?.value);
                let technicianId = d.getNamedItem('technicianid')?.value;
                addNew(technicianId, date)
            }
        }}
        onViewChange={(e) => setView(e.detail.value)}
        onItemRemove={() => {}}
      ></Scheduler>
    </div>
  );
};

export default CalendarApp;

import ReportsActionType from "./reports.types"

const INITIAL_STATE = {
    report: null
}

const reducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ReportsActionType.SET_REPORT:
            return {
                ...state,
                report: payload,
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;

import { takeEvery, put } from "redux-saga/effects";
import ExpensesActionType from "./expenses.types";
import { POST, GET, PUT, DELETE } from "../../axios";
import urls from "../urls";
import { setError, setSuccess } from "../actions/action.actions";

function* addTransactionSaga({ outletId, transaction, callback }) {
    const data = yield POST(
        `${urls.outlets}/${outletId}/transactions`,
        transaction
    );
    if (data) {
        callback();
        yield put(setSuccess("Transaction added succesfully!"));
    } else {
        yield put(setError("Something went wrong"));
    }
}

function* getTransactionsSaga({ outletId, page, size, callback }) {
    const data = yield GET(`${urls.outlets}/${outletId}/transactions`, {
        page,
        size,
    });
    if (data) {
        callback(data);
    }
}

function* updateTransactionSaga({
    outletId,
    transactionId,
    transaction,
    callback,
}) {
    const data = yield PUT(
        `${urls.outlets}/${outletId}/transactions/${transactionId}`,
        transaction
    );
    if (data) {
        yield put(setSuccess("Transaction updated succesfully!"));
        callback();
    } else {
        yield put(setError("Something went wrong"));
    }
}

function* deleteTransactionSaga({ outletId, transactionId, callback }) {
    const data = yield DELETE(
        `${urls.outlets}/${outletId}/transactions/${transactionId}`
    );
    if (data) {
        yield put(setSuccess("Transaction deleted succesfully!"));
        callback();
    } else {
        yield put(setError("Something went wrong"));
    }
}

export function* expensesSagas() {
    yield takeEvery(ExpensesActionType.ADD_TRANSACTION, addTransactionSaga);
    yield takeEvery(ExpensesActionType.GET_TRANSACTIONS, getTransactionsSaga);
    yield takeEvery(
        ExpensesActionType.UPDATE_TRANSACTION,
        updateTransactionSaga
    );
    yield takeEvery(
        ExpensesActionType.DELETE_TRANSACTION,
        deleteTransactionSaga
    );
}

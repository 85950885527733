import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SaveIcon  from "@mui/icons-material/Save";
import ShowPassword from '@mui/icons-material/Visibility';
import HidePassword from '@mui/icons-material/VisibilityOff';
import Loader from "../../sharedComponents/Loader";
import Edit from "@mui/icons-material/Edit";
import Cancel from "@mui/icons-material/Cancel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "calc(100% - 30px)",
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  p: 4,
  borderRadius: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: 24,
};

const ShowUser = ({
  data,
  changePassword=()=>{}
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("***********");
  const [editPassword, setEditPassword] = useState(false);
  const role = data.role;
  const handleChange=()=>{};
  const _setEditPassword = (val=true) => {
    setEditPassword(val);
    setPassword(val ? "" : "***********");
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    let d = {
      user_id: data?.id,
      password
    }
    changePassword(d);
    _setEditPassword(false);
  }

  return (
    <form onSubmit={(e) => handleSubmit(e, data)} style={{width: '100%'}}>
      <Grid container spacing={2}>
      <Grid key="2" item xs={12} sm={6}>
          <TextField
            sx={{ width: "100%" }}
            autoComplete="off"
            variant={"standard"}
            label={role.charAt(0).toUpperCase()+role.slice(1)}
            type="text"
            name="username"
            disabled
            onChange={(e) => handleChange(e, role)}
            value={data?.username}
            autoFocus={true}
          />
        </Grid>
        <Grid key="3" item xs={12} sm={6}>
          <TextField
            sx={{ width: "100%" }}
            variant={"standard"}
            label="Password"
            name="password"
            disabled={!editPassword}
            type={password?.length && !showPassword ? "password" : "text"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="off"
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: 'off',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    <IconButton sx={{p:0}}>
                      {
                        !editPassword?
                        <Edit onClick={()=>_setEditPassword(true)}/> :
                        showPassword ?
                        <HidePassword onClick={()=>setShowPassword(false)}/> :
                        <ShowPassword onClick={()=>setShowPassword(true)}/>
                      }
                    </IconButton>
                    {editPassword && <IconButton disabled={!password} sx={{p:0}} type="submit" onClick={handleSubmit}>
                      <SaveIcon />
                    </IconButton>}
                    {editPassword && <IconButton sx={{p:0}} onClick={()=>{_setEditPassword(false)}}>
                      <Cancel />
                    </IconButton>}
                  </>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        </Grid>
      </form>
  );
}

const AddUser = ({
  role="owner",
  data={username: '', password: ''},
  handleChange=()=>{},
  handleSubmit=(e)=>{e.preventDefault()},
  handleCancel=()=>{}
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <form onSubmit={(e) => handleSubmit(e, data)} style={{width: '100%'}}>
      <Grid container spacing={2}>
      <Grid key="2" item xs={12} sm={6}>
          <TextField
            sx={{ width: "100%" }}
            autoComplete="off"
            variant={"standard"}
            label="Username"
            type="text"
            name="username"
            onChange={(e) => handleChange(e, role)}
            value={data?.username}
            autoFocus={true}
            required
          />
        </Grid>
        <Grid key="3" item xs={12} sm={6}>
          <TextField
            sx={{ width: "100%" }}
            variant={"standard"}
            label="Password"
            name="password"
            type={data?.password?.length && !showPassword ? "password" : "text"}
            value={data?.password || ""}
            onChange={(e) => handleChange(e, role)}
            autoComplete="off"
            required
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: 'off',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    <IconButton sx={{p:0}} onClick={()=>setShowPassword(!showPassword)}>
                      {
                        showPassword ?
                        <HidePassword /> :
                        <ShowPassword />
                      }
                    </IconButton>
                    <IconButton sx={{p:0}} type="submit" disabled={!data?.password} onClick={()=>{}}>
                      <SaveIcon />
                    </IconButton>
                    <IconButton sx={{p:0}} onClick={handleCancel}>
                      <Cancel />
                    </IconButton>
                    
                  </>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        </Grid>
      </form>
  );
}

const Users = ({
  handleClose = () => {},
  outlet = {},
  users = null,
  createUser = () => {},
  changePassword = () => {},
}) => {
  const owner = users?.find((u) => u.role === "owner");
  const manager = users?.find((u) => u.role === "manager");
  const [showAddOwner, setShowAddOwner] = useState(false);
  const [showAddManager, setShowAddManager] = useState(false);
  const [newOwner, setNewOwner] = useState({username: "", password: "", role: "owner"});
  const [newManager, setNewManager] = useState({username: "", password: "", role: "manager"});
  const handleChange = (e, role) => {
    const {name, value} = e.target;
    if (role === "owner"){
      setNewOwner({...newOwner, [name]: value.replaceAll(' ', '')});
    } else {
      setNewManager({...newManager, [name]: value.replaceAll(' ', '')});
    }
  }
  const handleSubmit = (e, data) => {
    e.preventDefault();
    createUser(data);
  }
  useEffect(() => {
    owner && setShowAddOwner(false);
  }, [owner]);
  useEffect(() => {
    manager && setShowAddManager(false);
  }, [manager]);
  return (
    <Modal open={true} onClose={handleClose}>
      {users?
      <Box sx={style}>
        <Grid container spacing={2}>
        <Grid item key="title" xs={12} sm={12}>
        <Typography variant={"h6"} sx={{borderBottom: '1px solid #cccccc', width: '100%', lineHeight: 2.5, textAlign: 'center'}}>
          {outlet.name} - {showAddManager ? "Add Manager" : showAddOwner ? "Add Owner" : "Users"}
        </Typography>
        </Grid>
        <Grid item key="owner" xs={12} sm={12}>
          {owner && !showAddManager ?
          <ShowUser data={owner} changePassword={changePassword}/> :
          showAddOwner ?
          <AddUser
            role="owner"
            handleChange={handleChange}
            data={newOwner} 
            handleSubmit={handleSubmit}
            handleCancel={()=>setShowAddOwner(false)}
          /> :
          !showAddManager ?
          <Grid key="1" item xs={12} sm={12}>
            <Button onClick={()=>setShowAddOwner(!showAddOwner)}>Add Owner</Button>
          </Grid> :
          <></>
          }
        </Grid>
        <Grid item key="manager" xs={12} sm={12}>
          {manager && !showAddOwner ?
          <ShowUser data={manager} changePassword={changePassword}/> :
          showAddManager ?
          <AddUser
            role="manager"
            handleChange={handleChange}
            data={newManager}
            handleSubmit={handleSubmit}
            handleCancel={()=>{setShowAddManager(false)}}
          /> :
          !showAddOwner ?
          <Grid key="eqr" item xs={12} sm={12}>
            <Button onClick={()=>setShowAddManager(true)}>Add Manager</Button>
          </Grid> :
          <></>
          }
        </Grid>
        </Grid>
        <Button sx={{mt: 2}} onClick={handleClose}>Close</Button>
      </Box> :
      <Box sx={style}>
        <Loader pt={0}/>
      </Box>}
    </Modal>
  );
};

export default Users;

const SalesActionType = {
    GET_SALES: "GET_SALES",
    SET_SALES: "SET_SALES",
    CREATE_SALE: "CREATE_SALE",
    DELETE_SALE: "DELETE_SALE",
    EDIT_SALE: "EDIT_SALE",
    ADD_PAYMENTS: "ADD_PAYMENTS_SALE",
    GET_PRODUCTS: "GET_PRODUCTS_SALE",
    SET_PRODUCTS: "SET_PRODUCTS_SALE"
}

export default SalesActionType;

import CountriesActionType from "./countries.types";

export const addCountry = (country, callback=()=>{}) => {
    return {
        type: CountriesActionType.ADD_COUNTRY,
        country, callback
    }
}

export const updateCountry = (country, callback=()=>{}) => {
    return {
        type: CountriesActionType.UPDATE_COUNTRY,
        country, callback
    }
}

export const getCountries = (page=1, size=10) => {
    return {
        type: CountriesActionType.GET_COUNTRIES,
        page, size
    }
}

export const setCountries = (payload) => {
    return {
        type: CountriesActionType.SET_COUNTRIES,
        payload
    }
}

export const getCities = (countryId, page=1, size=10) => {
    return {
        type: CountriesActionType.GET_CITIES,
        countryId, page, size
    }
}

export const setCities = (countryId, payload) => {
    return {
        type: CountriesActionType.SET_CITIES,
        countryId, payload
    }
}

export const addCity = (countryId, city, callback=()=>{}) => {
    return {
        type: CountriesActionType.ADD_CITY,
        countryId, city, callback
    }
}

export const updateCity = (countryId, city, callback=()=>{}) => {
    return {
        type: CountriesActionType.UPDATE_CITY,
        countryId, city, callback
    }
}

export const alterCountry = (country) => {
    return {
        type: CountriesActionType.ALTER_COUNTRY,
        payload: country
    }
}

const CategoriesActionType = {
    GET_CATEGORIES: "GET_CATEGORIES",
    SET_CATEGORIES: "SET_CATEGORIES",
    ADD_CATEGORY: "ADD_CATEGORY",
    UPDATE_CATEGORY: "UPDATE_CATEGORY",
    ALTER_CATEGORY: "ALTER_CATEGORY",
    GET_CAT_OPTIONS: "GET_CAT_OPTIONS",
    SET_CAT_OPTIONS: "SET_CAT_OPTIONS"
}

export default CategoriesActionType;

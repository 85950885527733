import React, { useEffect, useState } from "react";
import Container from "../../sharedComponents/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import * as CountriesActionType from "../../redux/countries/countries.actions";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/EditNote";
import ToggleOff from "@mui/icons-material/ToggleOff";
import ToggleOn from "@mui/icons-material/ToggleOn";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Loader from "../../sharedComponents/Loader";

const Cities = () => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.countries.countries);
  const [showNewCity, setShowNewCity] = useState(false);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState({ value: "" });
  const [editCity, setEditCity] = useState({});

  const addCities = () => {
    let c = countries?.find((_c) => _c.id === country);
    if (!c?.cities) dispatch(CountriesActionType.getCities(country, 1, 100));
  };

  useEffect(() => {
    if (!countries) dispatch(CountriesActionType.getCountries());
    // eslint-disable-next-line
  }, [countries]);

  useEffect(() => {
    if (country) addCities();
    // eslint-disable-next-line
  }, [country]);

  const _setCity = (e) => {
    setCity({
      ...city,
      value: e.target.value,
    });
  };

  const callback = () => {
    setCity({
      value: "",
      currency: "",
      currency_short: "",
      currency_symbol: "",
    });
  };

  const addCity = () => {
    setShowNewCity(false);
    dispatch(CountriesActionType.addCity(country, city, callback));
  };

  const _setEditCity = (e) => {
    setEditCity({ ...editCity, value: e.target.value });
  };

  const updateCity = (data) => {
    dispatch(
      CountriesActionType.updateCity(country, data, () => setEditCity({}))
    );
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const cities = countries?.find((c) => c.id === country)?.cities;

  return (
    <Container>
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography>Cities</Typography>
        <CountryDropdown
          countries={countries}
          handleChange={handleCountryChange}
          country={country}
        />
        {country && cities ? (
          <Box>
            <Button
              variant="standard"
              onClick={() => {
                setShowNewCity(true);
              }}
              sx={{ float: "right" }}
            >
              Add
            </Button>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow key={"gerw"}>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {showNewCity && (
                    <AddCity
                      city={city}
                      setCity={_setCity}
                      onCancel={() => setShowNewCity(false)}
                      addCity={addCity}
                    />
                  )}
                  {cities?.map((c) => (
                    <TableRow
                      key={c.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                      hover={true}
                    >
                      {editCity?.id === c.id ? (
                        <>
                          <TableCell
                            sx={{ padding: "0px 16px" }}
                            align="left"
                            scope="row"
                          >
                            <TextField
                              autoFocus
                              variant="standard"
                              sx={{ width: 150 }}
                              value={editCity.value}
                              onChange={_setEditCity}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: "0px 16px" }} align="right">
                            <IconButton onClick={() => updateCity(editCity)}>
                              <SaveIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setEditCity({});
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell
                            sx={{ padding: "0px 16px" }}
                            align="left"
                            scope="row"
                          >
                            {c.value}
                          </TableCell>
                          <TableCell sx={{ padding: "0px 16px" }} align="right">
                            <IconButton onClick={() => setEditCity(c)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                updateCity({ ...c, active: !c.active })
                              }
                            >
                              {c.active ? (
                                <ToggleOn color="primary" />
                              ) : (
                                <ToggleOff />
                              )}
                            </IconButton>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : country ? (
          <Loader />
        ) : (
          <Typography
            sx={{ pt: 10, color: (theme) => theme.palette.text.secondary }}
          >
            Please select a country...
          </Typography>
        )}
      </Box>
    </Container>
  );
};

const AddCity = ({ onCancel, city, setCity, addCity }) => {
  return (
    <TableRow key={"0001"}>
      <TableCell sx={{ padding: "15px 16px" }} align="left" scope="row">
        <TextField
          autoFocus
          variant="standard"
          sx={{ width: 150 }}
          value={city.value}
          onChange={setCity}
          placeholder="City Name"
        />
      </TableCell>
      <TableCell sx={{ padding: "15px 16px" }} align="right">
        <IconButton onClick={addCity} disabled={!city.value}>
          <SaveIcon />
        </IconButton>
        <IconButton onClick={onCancel}>
          <CancelIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const CountryDropdown = ({
  country = null,
  countries = [],
  handleChange = () => {},
}) => {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="country-label">
          {countries && countries.length ? "Country" : "Loading Countries..."}
        </InputLabel>
        <Select
          labelId="country-label"
          id="country"
          value={country || ""}
          label="Country"
          onChange={handleChange}
          name={"country_id"}
          disabled={!countries || !countries.length}
        >
          {countries &&
            countries.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Cities;

import Box from "@mui/material/Box";

const Container = ({ children }) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 4,
        pt: 10,
        ml: { sm: "240px" },
        maxHeight: "100vh",
        overflow: "auto",
      }}
    >
      {children}
    </Box>
  );
};

export default Container;

import CustomersActionType from "./customers.types"

const INITIAL_STATE = {
    outletId: null,
    customers: null,
    options: null,
    page: 1,
    pages: 1,
}

const reducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case CustomersActionType.SET_CUSTOMERS:
            return {
                ...state,
                outletId: action.outletId,
                page: payload.page,
                pages: payload.pages,
                customers: payload.objects,
            }
        case CustomersActionType.ALTER_CUSTOMER:
            let _customers = state.customers
            _customers[
                _customers.findIndex(x => x.id === payload.id)
            ] = payload
            return {
                ...state,
                customers: [..._customers]
            }
        case CustomersActionType.RESET_CUSTOMERS:
            return {
                ...state,
                page: 1,
                pages: 1,
                customers: null
            }
        case CustomersActionType.SET_CUSTOMER_OPTIONS:
            return {
                ...state,
                options: payload
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;

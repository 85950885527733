import OutletsActionType from "./outlets.types";

export const addOutlet = (outlet, callback = () => {}) => ({
  type: OutletsActionType.ADD_OUTLET,
  outlet,
  callback,
});

export const getOutlets = (page=1, size=100, city='') => ({
  type: OutletsActionType.GET_OUTLETS,
  page,
  size,
  city
});

export const updateOutlet = (outlet, callback = () => {}) => ({
  type: OutletsActionType.UPDATE_OUTLET,
  outlet,
  callback,
});

export const setOutlets = (payload) => ({
  type: OutletsActionType.SET_OUTLETS,
  payload,
});

export const selectOutlet = (outletId) => ({
  type: OutletsActionType.SELECT_OUTLET,
  outletId
});

export const setOutlet = (payload) => ({
  type: OutletsActionType.SET_OUTLET,
  payload
});

export const resetOutletUsers = () => ({
  type: OutletsActionType.RESET_USERS
});

export const getUsers = (outletId) => ({
  type: OutletsActionType.GET_USERS,
  outletId
});

export const addUser = (outletId, payload) => ({
  type: OutletsActionType.ADD_USER,
  outletId, payload
});

export const setUsers = (payload) => ({
  type: OutletsActionType.SET_USERS,
  payload
});

export const checkUsername = (username, callback) => ({
  type: OutletsActionType.CHECK_USERNAME,
  username, callback
});

export const getManagerCanEdit = (outletId, callback=()=>{}) => ({
  type: OutletsActionType.GET_MANAGER_CAN_EDIT,
  outletId, callback
});

export const toggleManagerCanEdit = (outletId, callback=()=>{}) => ({
  type: OutletsActionType.TOGGLE_MANAGER_CAN_EDIT,
  outletId, callback
});

import { Box, CircularProgress } from "@mui/material";

const Loader = ({pt=10}) => {
  return (
    <Box sx={{ pt, justifyContent: "center", alignItems: "center" }}>
      <CircularProgress />
    </Box>
  );
};

export default Loader;

import CustomersActionType from "./customers.types";
import * as CustomersActions from "./customers.actions";
import { GET, POST, PUT } from "../../axios";
import { takeEvery, put } from "redux-saga/effects";
import urls from "../urls";
import { setError, setSuccess } from "../actions/action.actions";

function* getCustomersSaga({ outletId, search, page, size, callback }) {
    const data = yield GET(`${urls.outlets}/${outletId}/customers`, {
        search,
        page,
        size,
    });
    if (data) {
        if (callback) {
            callback(data.objects);
        } else {
            yield put(CustomersActions.setCustomers(outletId, data));
        }
    }
}

function* addCustomerSaga({ outletId, customer, callback }) {
    const data = yield POST(`${urls.outlets}/${outletId}/customers`, customer);
    if (data?.id) {
        callback();
        yield put(setSuccess("Customer added succesfully!"));
    } else {
        yield setError("Something went wrong");
    }
}

function* updateCustomerSaga({ outletId, customer, callback }) {
    const data = yield PUT(
        `${urls.outlets}/${outletId}/customers/${customer.id}`,
        customer
    );
    if (data?.id) {
        callback();
        yield put(setSuccess("Customer updated succesfully!"));
        yield put(CustomersActions.alterCustomer(data));
    } else {
        yield put(setError("Something went wrong"));
    }
}

function* getCustomerOptionsSaga({ outletId }) {
    const data = yield GET(`${urls.outlets}/${outletId}/customers/options`);
    if (data) {
        yield put(CustomersActions.setCustomerOptions(outletId, data));
    }
}

function* getCustomerSaga({ outletId, customerId, callback }) {
    const data = yield GET(
        `${urls.outlets}/${outletId}/customers/${customerId}`
    );
    if (data) {
        callback(data);
    } else {
        yield setError("Error updating customer details, please reload...");
    }
}

export function* customersSagas() {
    yield takeEvery(CustomersActionType.GET_CUSTOMERS, getCustomersSaga);
    yield takeEvery(CustomersActionType.ADD_CUSTOMER, addCustomerSaga);
    yield takeEvery(
        CustomersActionType.GET_CUSTOMER_OPTIONS,
        getCustomerOptionsSaga
    );
    yield takeEvery(CustomersActionType.UPDATE_CUSTOMER, updateCustomerSaga);
    yield takeEvery(CustomersActionType.GET_CUSTOMER, getCustomerSaga);
}

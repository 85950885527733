import ServicesActionType from "./services.types"

const INITIAL_STATE = {
    categoryId: null,
    services: null,
    options: null,
    page: 1,
    pages: 1
}

const reducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ServicesActionType.SET_SERVICES:
            return {
                ...state,
                categoryId: action.categoryId,
                page: payload.page,
                pages: payload.pages,
                services: payload.objects
            }
        case ServicesActionType.ALTER_SERVICE:
            let _services = state.services
            _services[
                _services.findIndex(x => x.id === payload.id)
            ] = payload
            return {
                ...state,
                services: [..._services]
            }
        case ServicesActionType.RESET_SERVICES:
            return {
                ...state,
                page: 1,
                pages: 1,
                services: null
            }
        case ServicesActionType.SET_SERVICES_OPTIONS:
            return {
                ...state,
                options: payload
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;

import SalesActionType from "./sales.types";

const INITIAL_STATE = {
  sales: null,
  products: [],
  page: 1,
  pages: 1,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SalesActionType.SET_SALES:
      return {
        ...state,
        page: payload.page,
        pages: payload.pages,
        sales: payload.objects,
      };
    case SalesActionType.SET_PRODUCTS:
      return {
        ...state,
        products: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;

import AuthActionType from "./auth.types"

const INITIAL_STATE = {
    token: null,
    user: null
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AuthActionType.SET_TOKEN:
            localStorage.setItem("finoppal_token", action.token)
            return {
                ...state,
                token: action.token,
                user: action.user
            }
        case AuthActionType.SET_USER:
            return {
                ...state,
                user: action.user
            }
        case AuthActionType.LOGOUT:
            localStorage.removeItem("finoppal_token")
            return {
                ...state,
                token: null,
                user: null
            }
        case AuthActionType.SET_OUTLETS:
            return {
                ...state,
                user: {
                    ...state.user,
                    outlets: action.payload
                }
            }
        default:
            return state
    }
}

export default reducer;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Container from "../../sharedComponents/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { selectOutlet } from "../../redux/outlets/outlets.actions";

const Dashboard = () => {
  const dispatch = useDispatch();
  const selectedOutletId = useSelector((state) => state.outlets.outletId);
  const { outletId } = useParams();

  useEffect(() => {
    if (!selectedOutletId && outletId)
        dispatch(selectOutlet(outletId));
        // eslint-disable-next-line
  }, [selectedOutletId]);


  return (
    <Container>
      <Box
        sx={{
          width: "600px",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ width: "100%" }}>
          This is a placeholder for dashboard...
        </Typography>
      </Box>
    </Container>
  );
};

export default Dashboard;

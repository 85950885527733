const OutletsActionType = {
    GET_OUTLETS: "GET_OUTLETS",
    SET_OUTLETS: "SET_OUTLETS",
    ADD_OUTLET: "ADD_OUTLET",
    UPDATE_OUTLET: "UPDATE_OUTLET",
    ALTER_OUTLET: "ALTER_OUTLET",
    RESET_OUTLETS: "RESET_OUTLETS",
    SELECT_OUTLET: "SELECT_OUTLET",
    SET_OUTLET: "SET_OUTLET",
    RESET_USERS: "RESET_USERS",
    GET_USERS: "GET_USERS",
    SET_USERS: "SET_USERS",
    CHECK_USERNAME: "CHECK_USERNAME",
    ADD_USER: "ADD_USER",
    GET_MANAGER_CAN_EDIT: "GET_MANAGER_CAN_EDIT",
    TOGGLE_MANAGER_CAN_EDIT: "TOGGLE_MANAGER_CAN_EDIT",
}

export default OutletsActionType;

import ActionActionTypes from "./action.types";

export const setError = (error) => {
    return {
        type: ActionActionTypes.SET_ERROR,
        payload: error
    }
}

export const setSuccess = (success) => {
    return {
        type: ActionActionTypes.SET_SUCCESS,
        payload: success
    }
}

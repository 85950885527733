import ProductsActionType from "./products.types"

const INITIAL_STATE = {
    categoryId: null,
    products: null,
    options: null,
    page: 1,
    pages: 1
}

const reducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ProductsActionType.SET_PRODUCTS:
            return {
                ...state,
                categoryId: action.categoryId,
                page: payload.page,
                pages: payload.pages,
                products: payload.objects
            }
        case ProductsActionType.ALTER_PRODUCT:
            let _products = state.products
            _products[
                _products.findIndex(x => x.id === payload.id)
            ] = payload
            return {
                ...state,
                products: [..._products]
            }
        case ProductsActionType.RESET_PRODUCTS:
            return {
                ...state,
                page: 1,
                pages: 1,
                products: null,
                options: null
            }
        case ProductsActionType.SET_PRODUCTS_OPTIONS:
            return {
                ...state,
                options: payload
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;

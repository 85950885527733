import ExpenseType from "./expenses.types";

export const addTransaction = (outletId, transaction, callback = () => {}) => ({
    type: ExpenseType.ADD_TRANSACTION,
    outletId,
    transaction,
    callback
});

export const getTransactions = ( outletId, callback = () => {}, page = 1, size = 10) => ({
    type: ExpenseType.GET_TRANSACTIONS,
    outletId,
    callback,
    page,
    size
});

export const updateTransaction = (
    outletId,
    transactionId,
    transaction,
    callback = () => {}
) => ({
    type: ExpenseType.UPDATE_TRANSACTION,
    outletId,
    transactionId,
    transaction,
    callback
});

export const deleteTransaction = (
    outletId,
    transactionId,
    callback = () => {}
) => ({
    type: ExpenseType.DELETE_TRANSACTION,
    outletId,
    transactionId,
    callback
});

import React, { useState } from "react"
import { Container, Typography } from "@mui/material"
import { Grid, Button } from "@mui/material"
import { useDispatch } from "react-redux"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import TextField from "@mui/material/TextField"
import Divider from "@mui/material/Divider"
import backgroundImage from "../../assets/logo.png"
import * as AuthActions from "../../redux/auth/auth.actions"


const Auth = (props) => {
    const dispatch = useDispatch()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const login = (e) => {
        e.preventDefault()
        if (username && password)
            dispatch(AuthActions.login(username, password))
    }
    return (
        <Container sx={{height: "100vh", padding: "0!important", background: "palette.background.default"}} maxWidth={"100vw"}>
            <Grid container>
                <Grid
                    item
                    xs={0}
                    md={6}
                >
                    <div
                        className="landing-image"
                        style={{
                            width: '100%',
                            height: "100vh",
                            backgroundColor: 'rgb(74, 28, 54)',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${backgroundImage})`,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h3" sx={{
                        fontFamily: 'arial narrow',
                        fontWeight: 300,
                        letterSpacing: '1.25rem',
                        textDecoration: 'none',
                        color: 'white',
                        marginTop: 50,
                        display: {
                            xs: 'none',
                            md: 'block'
                        }
                    }}>
                        FINOPPAL
                    </Typography>
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Typography variant="h3" sx={{
                        fontFamily: 'arial narrow',
                        fontWeight: 300,
                        letterSpacing: '1.5rem',
                        textDecoration: 'none',
                        marginBottom: 3,
                        textAlign: 'center',
                        color: 'rgb(74, 28, 54)',
                        display: {
                            xs: 'block',
                            md: 'none'
                        }
                    }}>
                        FINOPPAL
                    </Typography>
                    <Card
                        variant="outlined"
                        sx={{ width: 400, maxWidth: '100%' }}
                    >
                        <CardHeader
                            sx= {{ textAlign: "center" }}
                            title="Sign in"
                        />
                        <Divider />
                        <CardContent>
                            <form
                                onSubmit={(e)=>login(e)}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <TextField
                                    id="username"
                                    type="text"
                                    value={username}
                                    width="100%"
                                    autoFocus={true}
                                    fullWidth={true}
                                    placeholder="Username"
                                    label="Username"
                                    sx={{ marginTop: 2 }}
                                    onChange={(e)=>setUsername(e.target.value)}
                                />
                                <TextField
                                    id="password"
                                    type="password"
                                    value={password}
                                    placeholder="Password"
                                    fullWidth={true}
                                    label="Password"
                                    sx={{ marginTop: 2 }}
                                    onChange={(e)=>setPassword(e.target.value)} 
                                />
                                <Button
                                    id="submit"
                                    type="submit"
                                    value="Sign In"
                                    variant="contained"
                                    sx={{ marginTop: 2 }}
                                >
                                    Sign in
                                </Button>
                            </form>
                            {/* <a href="/#">Register</a>
                            <a href="/#">Forgot Password</a> */}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Auth

import ReportsActionType from "./reports.types";
import { setReport } from "./reports.actions";
import { POST, GET, DOWNLOAD } from "../../axios";
import { takeEvery, put } from "redux-saga/effects";
import urls from "../urls";

function* getReportSaga({filters, callback}) {
    let url = '/reports';
    const data = yield POST(url, filters);
    if (data){
        callback()
        yield put(setReport(data));
    }
}

function* getOutletsSaga({countryId, callback}) {
    let url = urls.outlets;
    const data = yield GET(url, {country_id: countryId});
    if (data) {
        callback(data.objects)
    }
}

function* downloadDailyReport({ filters }) {
    try {
      let url = '/reports/daily-task-download';
      const pdfFile = yield DOWNLOAD(url, filters);
  
      const blobObj = new Blob([pdfFile], { type: "application/pdf" });
      const now = new Date();
      const timestamp = now.toISOString().slice(0, 19).replace(/[:T-]/g, "_");
      const filename = `daily_report_${timestamp}.pdf`;
      const blobUrl = URL.createObjectURL(blobObj);
      
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = filename;
      anchor.click();
  
      yield 'Downloaded';
    } catch (error) {
      yield error;
    }
  }

  function* downloadReportSaga({ data }) {
    try {
      let url = '/reports/download';
      const types = {
        "MONTHLY":"Monthly",
        "DAILY":"Daily",
        "QUARTERLY":"Quarterly",
        "PRODUCTS":"Products",
        "SERVICES":"Services"
      };
      const file_type = types[data.type]
      data.type = file_type
      const pdfFile = yield DOWNLOAD(url, data);
  
      const blobObj = new Blob([pdfFile], { type: "application/pdf" });
      const now = new Date();
      const timestamp = now.toISOString().slice(0, 19).replace(/[:T-]/g, "_");
      const filename = `${file_type}_${timestamp}.pdf`;
      const blobUrl = URL.createObjectURL(blobObj);
      
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = filename;
      anchor.click();
  
      yield 'Downloaded';
    } catch (error) {
      yield error;
    }
  }
  

export function* reportSagas() {
    yield takeEvery(ReportsActionType.GET_REPORT, getReportSaga);
    yield takeEvery(ReportsActionType.GET_OUTLETS, getOutletsSaga);
    yield takeEvery(ReportsActionType.DOWNLOAD_DAILY_REPORT, downloadDailyReport);
    yield takeEvery(ReportsActionType.DOWNLOAD_REPORT, downloadReportSaga);
}

const TechniciansActionType = {
    GET_TECHNICIANS: "GET_TECHNICIANS",
    SET_TECHNICIANS: "SET_TECHNICIANS",
    ADD_TECHNICIAN: "ADD_TECHNICIAN",
    UPDATE_TECHNICIAN: "UPDATE_TECHNICIAN",
    ALTER_TECHNICIAN: "ALTER_TECHNICIAN",
    RESET_TECHNICIANS: "RESET_TECHNICIANS",
}

export default TechniciansActionType;

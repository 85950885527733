import { createStore, applyMiddleware } from "redux";
// import { logger } from "redux-logger"
import rootReducer from "./root.reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { authSagas } from "./auth/auth.saga";
import { countriesSagas } from "./countries/countries.sagas";
import { categoriesSagas } from "./categories/categories.sagas";
import { servicesSagas } from "./services/services.sagas";
import { productsSagas } from "./products/products.sagas";
import { ownersSagas } from "./owners/owners.sagas";
import { outletsSagas } from "./outlets/outlets.sagas";
import { techniciansSagas } from "./technicians/technicians.sagas";
import { customersSagas } from "./customers/customers.sagas";
import { salesSagas } from "./sales/sales.sagas";
import { appointmentsSagas } from "./appointments/appointments.sagas";
import { reportSagas } from "./reports/reports.sagas";
import { expensesSagas } from "./expenses/expenses.sagas";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

sagaMiddleware.run(authSagas);
sagaMiddleware.run(countriesSagas);
sagaMiddleware.run(categoriesSagas);
sagaMiddleware.run(servicesSagas);
sagaMiddleware.run(productsSagas);
sagaMiddleware.run(ownersSagas);
sagaMiddleware.run(outletsSagas);
sagaMiddleware.run(techniciansSagas);
sagaMiddleware.run(customersSagas);
sagaMiddleware.run(salesSagas);
sagaMiddleware.run(appointmentsSagas);
sagaMiddleware.run(reportSagas);
sagaMiddleware.run(expensesSagas);

export default store;

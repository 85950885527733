import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../sharedComponents/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/EditNote";
import ToggleOff from "@mui/icons-material/ToggleOff";
import ToggleOn from "@mui/icons-material/ToggleOn";
import { selectOutlet, getOutlets } from "../../redux/outlets/outlets.actions";
import {
  addTechnician,
  getTechnicians,
  updateTechnician,
} from "../../redux/technicians/technicians.actions";
import Loader from "../../sharedComponents/Loader";
import TechnicianForm from "../../components/technicians/TechnicianForm";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const Technicians = () => {
  const dispatch = useDispatch();
  const technicians = useSelector((state) => state.technicians.technicians);
  const [showNew, setShowNew] = useState(false);
  const [newTechnician, setNewTechnician] = useState({});
  const [technician, setTechnician] = useState(null);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const outlets = useSelector((state) => state.outlets.outlets);

  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    user?.outlets && handleSelectOutlet(user.outlets[0]?.id);
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (!outlets && user?.role === "admin") dispatch(getOutlets());
    // eslint-disable-next-line
  }, [outlets]);

  useEffect(() => {
    if (selectedOutlet && !technicians) {
      dispatch(getTechnicians(selectedOutlet));
    }
    // eslint-disable-next-line
  }, [selectedOutlet]);

  const handleChange = ({ target: { name, value } }) => {
    setNewTechnician({
      ...newTechnician,
      [name]: value,
    });
  };

  const handleEditChange = ({ target: { name, value } }) => {
    setTechnician({
      ...technician,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addTechnician(selectedOutlet, newTechnician, () => {
        setShowNew(false);
        setNewTechnician({});
      })
    );
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateTechnician(selectedOutlet, technician, () => setTechnician(null))
    );
  };

  const handleSelectOutlet = (outletId) => {
    setSelectedOutlet(outletId);
    dispatch(selectOutlet(outletId));
    dispatch(getTechnicians(outletId));
  };

  if (!selectedOutlet && user?.role === "admin") {
    return (
      <Container>
        <Box sx={{ width: "100%", marginBottom: "20px" }}>
          <FormControl sx={{ width: "500px", maxWidth: "100%" }}>
            <InputLabel id="outlet-label">Outlet</InputLabel>
            <Select
              labelId="outlet-label"
              id="outlet"
              value=""
              label="Outlet"
              onChange={(e) => handleSelectOutlet(e.target.value)}
            >
              {outlets?.filter((o) => o.active).map((outlet) => (
                <MenuItem key={outlet.id} value={outlet.id}>
                  {outlet.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Typography>
          No outlet selected. Please select an outlet to view technicians.
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Box sx={{ width: "100%", marginBottom: "20px" }}>
        {user?.role === "admin" && (
          <FormControl sx={{ width: "500px", maxWidth: "100%" }}>
            <InputLabel id="outlet-label">Outlet</InputLabel>
            <Select
              labelId="outlet-label"
              id="outlet"
              value={selectedOutlet || ""}
              label="Outlet"
              onChange={(e) => handleSelectOutlet(e.target.value)}
            >
              {outlets?.filter((o) => o.active).map((outlet) => (
                <MenuItem key={outlet.id} value={outlet.id}>
                  {outlet.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      {showNew && (
        <TechnicianForm
          open={showNew}
          handleClose={() => setShowNew(false)}
          handleChange={handleChange}
          data={newTechnician}
          handleSubmit={handleSubmit}
        />
      )}
      {technician && (
        <TechnicianForm
          open={technician ? true : false}
          handleClose={() => setTechnician(null)}
          handleChange={handleEditChange}
          data={technician}
          handleSubmit={handleEditSubmit}
          edit={true}
        />
      )}
      <Box
        sx={{
          width: "600px",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ width: "100%" }}>Technicians</Typography>
        {technicians ? (
          <Box width="100%">
            <Button
              variant="standard"
              onClick={() => setShowNew(true)}
              sx={{ float: "right" }}
            >
              Add
            </Button>
            <TableContainer sx={{ width: "100%" }} component={Paper}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow key={"head"}>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Designation
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {technicians?.map((c) => (
                    <TableRow
                      key={c.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {`${c.firstname} ${c.lastname}`}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {c.designation}
                      </TableCell>
                      <TableCell sx={{ padding: "0px 16px" }} align="right">
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => setTechnician(c)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() =>
                            dispatch(
                              updateTechnician(selectedOutlet, {
                                id: c.id,
                                active: !c.active,
                              })
                            )
                          }
                        >
                          {c.active ? (
                            <ToggleOn color="primary" />
                          ) : (
                            <ToggleOff />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Loader />
        )}
      </Box>
    </Container>
  );
};

export default Technicians;

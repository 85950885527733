import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../sharedComponents/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/EditNote";
import ToggleOff from "@mui/icons-material/ToggleOff";
import ToggleOn from "@mui/icons-material/ToggleOn";
import OwnerForm from "../../components/owners/OwnerForm";
import {
  addOwner,
  getOwners,
  updateOwner,
} from "../../redux/owners/owners.actions";
import Loader from "../../sharedComponents/Loader";

const Owners = () => {
  const dispatch = useDispatch();
  const owners = useSelector((state) => state.owners.owners);
  const [showNew, setShowNew] = useState(false);
  const [newOwner, setNewOwner] = useState({});

  const handleChange = ({ target: { name, value } }) => {
    setNewOwner({
      ...newOwner,
      [name]: value,
    });
  };

  const handleEditChange = ({ target: { name, value } }) => {
    setOwner({
      ...owner,
      [name]: value,
    });
  };
  const [owner, setOwner] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addOwner(newOwner, () => {
        setShowNew(false);
        setNewOwner({});
      })
    );
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(updateOwner(owner, () => setOwner(null)));
  };

  useEffect(() => {
    if (!owners) dispatch(getOwners());
    // eslint-disable-next-line
  }, [owners]);

  return (
    <Container>
      <OwnerForm
        data={newOwner}
        open={showNew}
        handleChange={handleChange}
        handleClose={() => setShowNew(false)}
        handleSubmit={handleSubmit}
      />
      <OwnerForm
        edit={true}
        data={owner}
        open={owner ? true : false}
        handleChange={handleEditChange}
        handleClose={() => setOwner(null)}
        handleSubmit={handleEditSubmit}
      />
      <Box
        sx={{
          width: "600px",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ width: "100%" }}>Owners</Typography>
        {owners ? (
          <Box width="100%">
            <Button
              variant="standard"
              onClick={() => setShowNew(true)}
              sx={{ float: "right" }}
            >
              Add
            </Button>
            <TableContainer sx={{ width: "100%" }} component={Paper}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow key={"head"}>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Phone
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {owners?.map((c) => (
                    <TableRow
                      key={c.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{ padding: "0px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {`${c.firstname} ${c.lastname}`}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {c.phone}
                      </TableCell>
                      <TableCell sx={{ padding: "0px 16px" }} align="right">
                        <IconButton sx={{ p: 0 }} onClick={() => setOwner(c)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() =>
                            dispatch(
                              updateOwner({ id: c.id, active: !c.active })
                            )
                          }
                        >
                          {c.active ? (
                            <ToggleOn color="primary" />
                          ) : (
                            <ToggleOff />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Loader />
        )}
      </Box>
    </Container>
  );
};

export default Owners;

import { takeEvery, put } from "redux-saga/effects";
import SalesActionType from "./sales.types";
import { setSales, getSales, setProducts } from "./sales.actions";
import { DELETE, GET, POST, PUT } from "../../axios";
import urls from "../urls";
import { setSuccess, setError } from "../actions/action.actions";

function* createSaleSaga({ outletId, sale, callback }) {
  const data = yield POST(`${urls.outlets}/${outletId}/sales`, sale);
  if (data) {
    callback(data);
    yield put(getSales(outletId));
  }
}

function* getSalesSaga({ outletId, search, page, size }) {
  const data = yield GET(`${urls.outlets}/${outletId}/sales`, {search, page, size});
  if (data) {
    yield put(setSales(data));
  }
}

function* deleteSaleSaga({outletId, saleId, callback}) {
    const data = yield DELETE(`${urls.outlets}/${outletId}/sales/${saleId}`);
    if (data) {
        callback();
        yield put(setSuccess("Record succesfully deleted!"));
        yield put(getSales(outletId));
    }
}

function* editSaleSaga({outletId, saleId, sale, callback}) {
  const data = yield PUT(`${urls.outlets}/${outletId}/sales/${saleId}`, sale);
  if (data) {
    callback(data);
    yield put(setSuccess("Record succesfully updated!"));
    yield put(getSales(outletId));
  }
}

function* addPaymentsSaga({ outletId, saleId, payments, callback }) {
  const data = yield POST(`${urls.outlets}/${outletId}/sales/${saleId}/payments`, payments)
  if (data) {
      callback(data);
      yield put(getSales(outletId));
      yield put(setSuccess("Payment details saved succesfully!"));
  } else {
      yield put(setError("Something went wrong"));
  }
}

function* getProductsSaga({outletId}) {
  const data = yield GET(`${urls.outlets}/${outletId}/products`);
  if (data) {
    yield put(setProducts(data));
  } else {
    yield put(setError("Something went wrong"));
  }
}

export function* salesSagas() {
  yield takeEvery(SalesActionType.CREATE_SALE, createSaleSaga);
  yield takeEvery(SalesActionType.GET_SALES, getSalesSaga);
  yield takeEvery(SalesActionType.DELETE_SALE, deleteSaleSaga);
  yield takeEvery(SalesActionType.EDIT_SALE, editSaleSaga);
  yield takeEvery(SalesActionType.ADD_PAYMENTS, addPaymentsSaga);
  yield takeEvery(SalesActionType.GET_PRODUCTS, getProductsSaga)
}

import TechniciansActionType from './technicians.types'

export const addTechnician = (outletId, technician, callback=()=>{}) => ({
    type: TechniciansActionType.ADD_TECHNICIAN,
    outletId, technician, callback
});

export const getTechnicians = (outletId, page=1, size=100) => ({
    type: TechniciansActionType.GET_TECHNICIANS,
    outletId, page, size
});

export const setTechnicians = (outletId, payload) => ({
    type: TechniciansActionType.SET_TECHNICIANS,
    outletId, payload
});

export const updateTechnician = (outletId, technician, callback=()=>{}) => ({
    type: TechniciansActionType.UPDATE_TECHNICIAN,
    outletId, technician, callback
});

export const alterTechnician = (payload) => ({
    type: TechniciansActionType.ALTER_TECHNICIAN,
    payload
});

export const resetTechnicians = () => ({
    type: TechniciansActionType.RESET_TECHNICIANS
});

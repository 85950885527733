import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dropdown from "../../sharedComponents/Dropdown";
import TextField from "@mui/material/TextField";
import TrashIcon from "@mui/icons-material/Delete";
import CustomerForm from "../customers/CustomerForm";
import PaymentWindow from "../../sharedComponents/PaymentsForm";
import InputAdornment from "@mui/material/InputAdornment";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxHeight: "calc(100vh)",
    overflowY: "auto",
    maxWidth: "calc(100% - 30px)",
    bgcolor: "background.paper",
    border: "1px solid #ccc",
    p: 4,
    pt: 2,
    pb: 2,
    borderRadius: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    boxShadow: 24,
};

const SaleForm = ({
    open = true,
    data = {},
    handleClose = () => {},
    readOnly = false,
    edit = false,
    handleChange = () => {},
    handleProductChange = () => {},
    handleAddProduct = () => {},
    handleSubmit = () => {},
    handleDeleteProduct = () => {},
    categories = [],
    customers = [],
    currency = "INR",
    products = [],
    setPay = () => {},
    disableSave = false,
    handleDelete = () => {},
    addPayments = () => {},
    pay = false,
    addNewCustomer = () => {},
}) => {
    const [newCustomer, setNewCustomer] = useState({});
    const [showNewCustomer, setShowNewCustomer] = useState(false);
    const handleCustomerChange = ({ target: { name, value } }) => {
        setNewCustomer({
            ...newCustomer,
            [name]: value,
        });
    };
    const total = data?.products?.reduce((a, b) => a + b.total, 0);
    const saleOnly = data?.sale_only || data?.appointment?.sale_only || false;
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <CustomerForm
                    open={showNewCustomer}
                    handleClose={() => setShowNewCustomer(false)}
                    handleChange={handleCustomerChange}
                    handleSubmit={(e) => {
                        e.preventDefault();
                        addNewCustomer(newCustomer, () =>
                            setShowNewCustomer(false)
                        );
                    }}
                    data={newCustomer}
                />
                <Typography id="modal-modal-title" variant="h6" sx={{ p: 2 }}>
                    {readOnly ? "View" : edit ? "Edit" : "New"} Purchase
                </Typography>
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <Grid container spacing={2} sx={{ width: "100%" }}>
                        <Grid key="1" item xs={12} md={6}>
                            <Dropdown
                                sx={{ width: "100%" }}
                                name={"customer_id"}
                                variant={"standard"}
                                label={"Customer"}
                                required
                                autoComplete="off"
                                disabled={readOnly || edit}
                                handleChange={handleChange}
                                value={data?.customer_id || ""}
                                options={customers}
                                addNew={
                                    edit
                                        ? null
                                        : () => {
                                              setShowNewCustomer(true);
                                          }
                                }
                            />
                        </Grid>
                        <Grid key="2" item xs={12} sm={12}>
                            {data?.products.map((productData, i) => {
                                return (
                                    <ProductRow
                                        handleChange={handleProductChange}
                                        handleDelete={handleDeleteProduct}
                                        categories={categories}
                                        currency={currency}
                                        data={productData}
                                        products={products}
                                        index={i}
                                        showDelete={data?.products?.length > 1}
                                        readOnly={readOnly}
                                    />
                                );
                            })}
                        </Grid>
                    </Grid>
                </form>
                <PaymentWindow
                    open={pay}
                    noTip={true}
                    handleClose={() => setPay(false)}
                    amount={total}
                    addPayments={addPayments}
                    paymentsData={data?.payments}
                    // outstanding_balance={
                    //     data?.payment_status === "PENDING"
                    //         ? data?.customer?.outstanding_balance
                    //         : 0
                    // }
                    status={data?.payment_status}
                    currency={currency}
                />
                <Grid
                    key="8"
                    item
                    xs={12}
                    sm={4}
                    sx={{
                        width: "100%",
                        mt: 1.5,
                        mr: 5,
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <TextField
                        sx={{ width: 50 }}
                        name={"total"}
                        variant={"standard"}
                        autoComplete="off"
                        type="text"
                        disabled={true}
                        value={`Total`}
                    />
                    <TextField
                        sx={{ width: "100" }}
                        name={"total"}
                        variant={"standard"}
                        autoComplete="off"
                        type="float"
                        disabled={true}
                        value={`${currency} ${total}`}
                    />
                </Grid>
                <Grid
                    item
                    key="unique_key_0324"
                    xs={12}
                    sx={{
                        mt: 2,
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        size="small"
                        disabled={readOnly}
                        sx={{ fontSize: "11px" }}
                        onClick={handleAddProduct}
                    >
                        Add Product
                    </Button>
                </Grid>
                {data?.id && !saleOnly && <Grid item alignSelf={'flex-start'}>
                    <Typography fontSize={'small'} color={'red'}>This sale is part of an appointment. Go to appointment history of the customer to edit/view payment details.</Typography>
                </Grid>}
                <Grid item key="unique_key_0323">
                    {readOnly ? (
                        <Grid container spacing={2} justifyContent={"center"}>
                            {saleOnly && <Grid item>
                                <Button
                                    onClick={() => {
                                        setPay(true);
                                    }}
                                >
                                    Payment
                                </Button>
                            </Grid>}
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2} justifyContent={"center"}>
                            <Grid item>
                                <Button
                                    type="submit"
                                    disabled={disableSave}
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                            </Grid>
                            {edit ? (
                                <Grid item>
                                    <Button
                                        onClick={() => {
                                            setPay(true);
                                        }}
                                    >
                                        Payment
                                    </Button>
                                </Grid>
                            ) : (
                                <></>
                            )}
                            {edit && data?.payment_status !== "done" ? (
                                <Grid item>
                                    <Button
                                        sx={{ color: "red" }}
                                        onClick={() => {
                                            handleDelete(data?.id);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                            ) : (
                                <></>
                            )}
                            <Grid item>
                                <Button onClick={handleClose}>Close</Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Modal>
    );
};

export const ProductRow = ({
    index,
    key,
    categories = [],
    data,
    currency,
    products = [],
    country_id,
    handleChange = () => {},
    handleDelete = () => {},
    readOnly = false,
    showDelete = false,
}) => {
    const categroyId = data?.category_id || data?.product?.category_id;
    return (
        <Grid key={`${index}_key_id`} item xs={12} sm={12} sx={{ mt: 1.5 }}>
            <Grid container key={`${index}_container_id`} spacing={2}>
                <Grid item key={`${index}_category`} xs={12} md={2.5}>
                    <Dropdown
                        name={`category_id`}
                        value={categroyId}
                        options={categories}
                        handleChange={(e) => handleChange(index, e)}
                        label="Category"
                        disabled={readOnly}
                    />
                </Grid>
                <Grid item key={`${index}_product`} xs={12} md={3}>
                    <Dropdown
                        name={`product_id`}
                        disabled={readOnly}
                        value={data?.product_id || data?.product?.id}
                        options={products
                            ?.filter((c) => c.category_id === categroyId)
                            ?.map((po) => ({ id: po.id, value: po.name }))}
                        handleChange={(e) => handleChange(index, e)}
                        label="Product"
                    />
                </Grid>
                <Grid item key={`${index}_price`} xs={12} md={2}>
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <TextField
                            sx={{ width: "100%" }}
                            name={"amount"}
                            variant={"standard"}
                            InputLabelProps={{
                                shrink: data?.price ? true : false,
                            }}
                            label={"Price"}
                            autoComplete="off"
                            type="text"
                            value={
                                data?.price ? `${currency} ${data?.price}` : ""
                            }
                            disabled={true}
                        />
                    </Box>
                </Grid>
                <Grid item key={`${index}_quantity`} xs={12} md={1.5}>
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <TextField
                            sx={{ width: "100%" }}
                            name={"quantity"}
                            variant={"standard"}
                            label={"Quantity"}
                            autoComplete="off"
                            type="number"
                            value={data?.quantity}
                            onChange={(e) => handleChange(index, e)}
                            disabled={readOnly || !data?.product_id}
                        />
                    </Box>
                </Grid>
                <Grid item key={`${index}_total`} xs={12} md={3}>
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <TextField
                            sx={{ width: "100%" }}
                            name={"total"}
                            variant={"standard"}
                            InputLabelProps={{ shrink: true }}
                            label={"Total"}
                            autoComplete="off"
                            type="number"
                            value={data?.total.toFixed(2)}
                            onChange={(e) => handleChange(index, e)}
                            disabled={readOnly || !(data?.quantity > 0)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {currency}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {!readOnly && showDelete ? (
                            <IconButton
                                readOnly
                                onClick={() => handleDelete(index)}
                            >
                                <TrashIcon />
                            </IconButton>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SaleForm;

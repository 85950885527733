import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { Container } from "@mui/material";
import Login from "./routes/auth";
import * as AuthActions from "./redux/auth/auth.actions";
import { useSnackbar } from "notistack";
import Drawer from "./components/nav/Drawer";
import Countries from "./routes/countries";
import Cities from "./routes/cities";
import Categories from "./routes/categories";
import Services from "./routes/services";
import Products from "./routes/products";
import Owners from "./routes/owners";
import Outlets from "./routes/outlets";
import Notifications from "./routes/notifications";
import Dashboard from "./routes/dashboard";
import Technicians from "./routes/technicians";
import Customers from "./routes/customers";
import Sales from "./routes/sales";
import Appointments from "./routes/appointments";
import Reports from "./routes/reports";
import Settings from "./routes/settings";
import Expenses from "./routes/expenses";
import { setError, setSuccess } from "./redux/actions/action.actions";

const theme = (mode) => {
  return createTheme({
    palette: {
      mode: mode ? mode : "light",
      primary: {
        main: "#4A1C36", //blue[100]
      },
    },
  });
};

function App() {
  const user = useSelector((state) => state.auth.user);
  const error = useSelector((state) => state.actions.error);
  const success = useSelector((state) => state.actions.success);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const token = localStorage.getItem("finoppal_token");
  const theme_mode = localStorage.getItem("theme_mode");
  const [mode] = useState(theme_mode);

  // Get user data if token is present or redirect to login
  useEffect(() => {
    if (user && location.pathname === "/login") {
      navigate(user?.role === "admin" ? "/outlets" : "/appointments");
    } else if (!user) {
      if (token) {
        dispatch(
          AuthActions.getUser(() => {
            navigate("/login");
          })
        );
      } else {
        navigate("/login");
      }
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(setError(null));
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (success) {
      enqueueSnackbar(success, { variant: "success" });
      dispatch(setSuccess(null));
    }
    // eslint-disable-next-line
  }, [success]);

  return (
    <ThemeProvider theme={theme(mode)}>
      <CssBaseline />
      <Container
        justify="center"
        align="center"
        sx={{ padding: "0!important" }}
        maxWidth={"100vw"}
      >
        {user && <Drawer />}
        <Routes>
          <Route path="/login" exact element={<Login />} />
          {user && (
            <>
              <Route path="/" exact element={<Outlets />} />
              <Route path="/notifications" exact element={<Notifications />} />
              <Route path="/countries" exact element={<Countries />} />
              <Route path="/cities" exact element={<Cities />} />
              <Route path="/categories" exact element={<Categories />} />
              <Route path="/services" exact element={<Services />} />
              <Route path="/products" exact element={<Products />} />
              <Route path="/owners" exact element={<Owners />} />
              <Route path="/outlets" exact element={<Outlets />} />
              <Route path="/reports" exact element={<Reports />}/>
              <Route path="/outlets/:outletId/dashboard" exact element={<Dashboard />} />
              <Route path="/technicians" exact element={<Technicians />} />
              <Route path="/customers" exact element={<Customers />} />
              <Route path="/sales" exact element={<Sales />} />
              <Route path="/appointments" exact element={<Appointments />} />
              <Route path="/expenses" exact element={<Expenses />} />
              <Route path="/settings" exact element={<Settings />} />
            </>
          )}
        </Routes>
      </Container>
    </ThemeProvider>
  );
}

export default App;

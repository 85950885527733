import CategoriesActionType from "./categories.types";
import * as CategoriesActions from "./categories.actions";
import { GET, POST, PUT } from "../../axios";
import { takeEvery, put } from "redux-saga/effects";
import urls from "../urls";
import { setError, setSuccess } from "../actions/action.actions";

function* getCategoriesSaga({ page, size }) {
  const data = yield GET(urls.categories, { page, size });
  if (data) {
    yield put(CategoriesActions.setCategories(data));
  }
}

function* addCategorySaga({ category, callback }) {
  const data = yield POST(urls.categories, category);
  if (data?.id) {
    callback();
    yield put(CategoriesActions.getCategories(1, 100));
  } else {
    yield setError("Something went wrong");
  }
}

function* updateCategorySaga({ category, callback }) {
  const data = yield PUT(`${urls.categories}/${category.id}`, category);
  if (data?.id) {
    callback();
    yield put(setSuccess("Category updated succesfully!"));
    yield put(CategoriesActions.alterCategory(data));
  } else {
    yield put(setError("Something went wrong"));
  }
}

function* getCategoriesOptionsSaga() {
  const data = yield GET(`${urls.categories}/options`);
  if (data) {
    yield put(CategoriesActions.setCategoriesOptions(data));
  }
}

export function* categoriesSagas() {
  yield takeEvery(CategoriesActionType.GET_CATEGORIES, getCategoriesSaga);
  yield takeEvery(CategoriesActionType.ADD_CATEGORY, addCategorySaga);
  yield takeEvery(CategoriesActionType.UPDATE_CATEGORY, updateCategorySaga);
  yield takeEvery(
    CategoriesActionType.GET_CAT_OPTIONS,
    getCategoriesOptionsSaga
  );
}

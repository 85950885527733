import SalesActionType from "./sales.types";

export const createSale = (outletId, sale, callback = () => {}) => ({
  type: SalesActionType.CREATE_SALE,
  outletId,
  sale,
  callback,
});

export const getSales = (outletId, search=null, page = 1, size = 10) => ({
  type: SalesActionType.GET_SALES,
  outletId,
  search,
  page,
  size,
});

export const setSales = (payload) => ({
  type: SalesActionType.SET_SALES,
  payload,
});

export const deleteSale = (outletId, saleId, callback=()=>{}) => ({
  type: SalesActionType.DELETE_SALE,
  outletId,
  saleId,
  callback
});

export const editSale = (outletId, saleId, sale, callback = () => {}) => ({
  type: SalesActionType.EDIT_SALE,
  outletId,
  saleId,
  sale,
  callback,
});

export const addPayments = (
  outletId,
  saleId,
  payments,
  callback = () => {}
) => ({
  type: SalesActionType.ADD_PAYMENTS,
  outletId,
  saleId,
  payments,
  callback,
});

export const getProducts = (outletId) => ({
  type: SalesActionType.GET_PRODUCTS,
  outletId
});

export const setProducts = (payload) => ({
  type: SalesActionType.SET_PRODUCTS,
  payload
});

import CountriesActionType from "./countries.types"
import * as CountriesActions from "./countries.actions"
import { GET, POST, PUT } from "../../axios"
import { takeEvery, put } from "redux-saga/effects"
import urls from "../urls"
import {setError, setSuccess} from "../actions/action.actions"

function* getCountriesSaga({page, size}) {
    const data = yield GET(
        urls.countries,
        { page, size }
    )
    if (data) {
        yield put(CountriesActions.setCountries(data))
    }
}

function* getCitiesSaga({countryId, page, size}) {
    const data = yield GET(
        `${urls.countries}/${countryId}/cities`,
        { page, size }
    )
    if (data) {
        yield put(CountriesActions.setCities(countryId, data))
    }
}

function* addCitySaga({countryId, city, callback}) {
    const data = yield POST(
        `${urls.countries}/${countryId}/cities`, city
    )
    if (data?.id) {
        callback()
        yield put(CountriesActions.getCities(countryId))
    } else {
        yield put(setError('Something went wrong'))
    }
}

function* updateCitySaga({countryId, city, callback}) {
    const data = yield PUT(
        `${urls.countries}/${countryId}/cities/${city.id}`, city
    )
    if (data?.id) {
        callback()
        yield put(setSuccess('City updated succesfully'))
        yield put(CountriesActions.getCities(countryId))
    } else {
        yield put(setError('Something went wrong'))
    }
}

function* addCountrySaga({country, callback}) {
    const data = yield POST(urls.countries, country)
    if (data?.id) {
        callback()
        yield put(setSuccess('Added country succesfully'))
        yield put(CountriesActions.getCountries())
    } else {
        yield put(setError('Something went wrong'))
    }
}

function* updateCountrySaga({country, callback}) {
    const data = yield PUT(`${urls.countries}/${country.id}`, country)
    if (data?.id) {
        callback()
        yield put(setSuccess('Updated country succesfully'))
        yield put(CountriesActions.alterCountry(data))
    } else {
        yield put(setError('Something went wrong'))
    }
}

export function* countriesSagas() {
    yield takeEvery(CountriesActionType.GET_COUNTRIES, getCountriesSaga)
    yield takeEvery(CountriesActionType.GET_CITIES, getCitiesSaga)
    yield takeEvery(CountriesActionType.ADD_CITY, addCitySaga)
    yield takeEvery(CountriesActionType.UPDATE_CITY, updateCitySaga)
    yield takeEvery(CountriesActionType.ADD_COUNTRY, addCountrySaga)
    yield takeEvery(CountriesActionType.UPDATE_COUNTRY, updateCountrySaga)
}

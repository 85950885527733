import CustomersActionType from './customers.types'

export const addCustomer = (outletId, customer, callback=()=>{}) => ({
    type: CustomersActionType.ADD_CUSTOMER,
    outletId, customer, callback
});

export const getCustomers = (outletId, search=null, page=1, size=10, callback) => ({
    type: CustomersActionType.GET_CUSTOMERS,
    outletId, search, page, size, callback
});

export const getCustomerOptions = (outletId) => ({
    type: CustomersActionType.GET_CUSTOMER_OPTIONS,
    outletId
});

export const setCustomers = (outletId, payload) => ({
    type: CustomersActionType.SET_CUSTOMERS,
    outletId, payload
});

export const setCustomerOptions = (outletId, payload) => ({
    type: CustomersActionType.SET_CUSTOMER_OPTIONS,
    outletId, payload
});

export const updateCustomer = (outletId, customer, callback=()=>{}) => ({
    type: CustomersActionType.UPDATE_CUSTOMER,
    outletId, customer, callback
});

export const alterCustomer = (payload) => ({
    type: CustomersActionType.ALTER_CUSTOMER,
    payload
});

export const resetCustomers = () => ({
    type: CustomersActionType.RESET_CUSTOMERS
});

export const getCustomer = (outletId, customerId, callback=()=>{}) => ({
    type: CustomersActionType.GET_CUSTOMER,
    outletId, customerId, callback
});

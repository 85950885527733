import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

const Dropdown = ({
  value = null,
  options = [],
  label = "",
  name = "",
  handleChange = () => {},
  required = true,
  disabled = false,
  addNew = null,
}) => {
  const valueObj = {
    id: value,
    value: options.find((o) => o.id === value)?.value || ""
  };
  return (
    <>
      <FormControl fullWidth>
        <Autocomplete
          fullWidth 
          value={valueObj}
          onChange={(_, newValue) => {
            handleChange({
              target: {
                name,
                value: newValue.id,
              },
            });
          }}
          options={options}
          getOptionLabel={(option) => option.value || ""}
          name={name}
          disabled={disabled || !options || !options.length}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{width: '100%'}}
              label={label}
              required={required}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: addNew ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => addNew()} sx={{marginRight: "-30px"}}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
            />
          )}
          disableClearable={true}
          disableListWrap
        />
      </FormControl>
    </>
  );
};

export default Dropdown;

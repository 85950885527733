import CategoriesActionType from "./categories.types"

const INITIAL_STATE = {
    categories: null,
    page: 1,
    pages: 1,
    options: null
}

const reducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case CategoriesActionType.SET_CATEGORIES:
            return {
                ...state,
                page: payload.page,
                pages: payload.pages,
                categories: payload.objects
            }
        case CategoriesActionType.ALTER_CATEGORY:
            let _categories = state.categories
            _categories[
                _categories.findIndex(x => x.id === payload.id)
            ] = payload
            return {
                ...state,
                categories: [..._categories]
            }
        case CategoriesActionType.SET_CAT_OPTIONS:
            return {
                ...state,
                options: [...payload]
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;
import TechniciansActionType from "./technicians.types";
import * as TechniciansActions from "./technicians.actions";
import { GET, POST, PUT } from "../../axios";
import { takeEvery, put } from "redux-saga/effects";
import urls from "../urls";
import { setError, setSuccess } from "../actions/action.actions";

function* getTechniciansSaga({ outletId, page, size }) {
  const data = yield GET(`${urls.outlets}/${outletId}/technicians`, {
    page,
    size,
  });
  if (data) {
    yield put(TechniciansActions.setTechnicians(outletId, data));
  }
}

function* addTechnicianSaga({ outletId, technician, callback }) {
  const data = yield POST(
    `${urls.outlets}/${outletId}/technicians`,
    technician
  );
  if (data?.id) {
    callback();
    yield put(setSuccess("Technician added succesfully!"));
    yield put(TechniciansActions.getTechnicians(outletId, 1, 100));
  } else {
    yield setError("Something went wrong");
  }
}

function* updateTechnicianSaga({ outletId, technician, callback }) {
  const data = yield PUT(
    `${urls.outlets}/${outletId}/technicians/${technician.id}`,
    technician
  );
  if (data?.id) {
    callback();
    yield put(setSuccess("Technician updated succesfully!"));
    yield put(TechniciansActions.alterTechnician(data));
  } else {
    yield put(setError("Something went wrong"));
  }
}

export function* techniciansSagas() {
  yield takeEvery(TechniciansActionType.GET_TECHNICIANS, getTechniciansSaga);
  yield takeEvery(TechniciansActionType.ADD_TECHNICIAN, addTechnicianSaga);
  yield takeEvery(
    TechniciansActionType.UPDATE_TECHNICIAN,
    updateTechnicianSaga
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import Container from "../../sharedComponents/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Loader from "../../sharedComponents/Loader";
import { getManagerCanEdit, toggleManagerCanEdit } from "../../redux/outlets/outlets.actions";


const Settings = () => {
    const dispatch = useDispatch();
    const [canEdit, setCanEdit] = useState(null);
    const user = useSelector((state) => state.auth.user);
    const [outletId, setOutletId] = useState(null);

    useEffect(() => {
        if (outletId && canEdit === null)
            dispatch(getManagerCanEdit(outletId, (a) => setCanEdit(a)));
    }, [outletId, dispatch, canEdit]);

    useEffect(() => {
        if (user?.outlets) setOutletId(user.outlets[0].id);
    }, [user]);



    const toggleManager = () => {
        dispatch(toggleManagerCanEdit(
            outletId,
            (a) => {
                setCanEdit(a);
            }
        ));
    }

    console.log(canEdit);

    return (
        <Container>
        <Box
            sx={{
            width: "100%",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "column",
            }}
        >
            <Typography>Settings</Typography>
            <Box width="100%" sx={{pt: 2}}>
            {canEdit === null ?
            <Loader/> :
            <TableContainer sx={{ width: "100%" }} component={Paper}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow key={"head"}>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Setting
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            Manager can edit completed appointments
                        </TableCell>
                        <TableCell align="right">
                            <Switch checked={canEdit} onChange={toggleManager} color="primary" inputProps={{ 'aria-label': 'controlled' }}/>
                        </TableCell>
                    </TableRow>
                </TableBody>
                </Table>
                </TableContainer>}
            </Box>
        </Box>
        </Container>
    )

}

export default Settings;

import OutletsActionType from "./outlets.types";
import * as OutletsActions from "./outlets.actions";
import { GET, POST, PUT } from "../../axios";
import { takeEvery, put } from "redux-saga/effects";
import urls from "../urls";
import { setError, setSuccess } from "../actions/action.actions";

function* addOutletSaga({ outlet, callback }) {
  const data = yield POST(`${urls.outlets}`, outlet);
  if (data?.id) {
    callback();
    yield put(setSuccess("Outlet added succesfully!"));
    yield put(OutletsActions.getOutlets(1, 100));
  } else {
    yield put(setError("Something went wrong"));
  }
}

function* getOutletsSaga({ page, size, city }) {
  const data = yield GET(urls.outlets, { page, size, city_id: city });
  if (data) {
    yield put(OutletsActions.setOutlets(data));
  }
}

function* updateOutletSaga({ outlet, callback }) {
  const data = yield PUT(`${urls.outlets}/${outlet.id}`, outlet);
  if (data) {
    callback();
    yield put(setSuccess("Outlet updated succesfully!"));
    yield put(OutletsActions.getOutlets(1, 100));
  }
}

function* selectOutletSaga({ outletId }) {
  const data = yield GET(`${urls.outlets}/${outletId}`);
  if (data && data.id) {
    yield put(OutletsActions.setOutlet(data));
  }
}

function* getUsersSaga({ outletId }) {
  const data = yield GET(`${urls.outlets}/${outletId}/users`);
  if (data) {
    yield put(OutletsActions.setUsers(data));
  }
}

function* checkUsernameSaga({ username, callback }) {
  const data = yield GET(`users/valid_uname`, {username});
  callback(data);
}

function* addUserSaga({ outletId, payload }) {
  const data = yield POST(`${urls.outlets}/${outletId}/users`, payload)
  if (data) {
    yield put(OutletsActions.getUsers(outletId));
  }
}

function* getManagerCanEditSaga({ outletId, callback }) {
  const data = yield GET(`${urls.outlets}/${outletId}/get_manager_can_edit`)
  if (data) {
    callback(data.can_edit_closed_apt);
  }
}

function* toggleManagerCanEditSaga({ outletId, callback }) {
  const data = yield POST(`${urls.outlets}/${outletId}/toggle_manager_can_edit`)
  if (data){
    callback(data.can_edit_closed_apt);
  } else {
    setError('Something went wrong');
  }
}

export function* outletsSagas() {
  yield takeEvery(OutletsActionType.GET_OUTLETS, getOutletsSaga);
  yield takeEvery(OutletsActionType.ADD_OUTLET, addOutletSaga);
  yield takeEvery(OutletsActionType.UPDATE_OUTLET, updateOutletSaga);
  yield takeEvery(OutletsActionType.SELECT_OUTLET, selectOutletSaga);
  yield takeEvery(OutletsActionType.CHECK_USERNAME, checkUsernameSaga);
  yield takeEvery(OutletsActionType.GET_USERS, getUsersSaga);
  yield takeEvery(OutletsActionType.ADD_USER, addUserSaga);
  yield takeEvery(OutletsActionType.GET_MANAGER_CAN_EDIT, getManagerCanEditSaga);
  yield takeEvery(OutletsActionType.TOGGLE_MANAGER_CAN_EDIT, toggleManagerCanEditSaga);
}

import CategoriesActionType from './categories.types'

export const addCategory = (category, callback=()=>{}) => ({
    type: CategoriesActionType.ADD_CATEGORY,
    category, callback
});

export const getCategories = (page=1, size=10) => ({
    type: CategoriesActionType.GET_CATEGORIES,
    page, size
});

export const setCategories = (payload) => ({
    type: CategoriesActionType.SET_CATEGORIES,
    payload
});

export const updateCategory = (category, callback=()=>{}) => ({
    type: CategoriesActionType.UPDATE_CATEGORY,
    category, callback
});

export const alterCategory = (payload) => ({
    type: CategoriesActionType.ALTER_CATEGORY,
    payload
});

export const getCategoriesOptions = () => ({
    type: CategoriesActionType.GET_CAT_OPTIONS
});

export const setCategoriesOptions = (payload) => ({
    type: CategoriesActionType.SET_CAT_OPTIONS,
    payload
});

const OwnersActionType = {
    GET_OWNERS: "GET_OWNERS",
    GET_OWNERS_OPTIONS: "GET_OWNERS_OPTIONS",
    SET_OWNERS: "SET_OWNERS",
    SET_OWNERS_OPTIONS: "SET_OWNERS_OPTIONS",
    ADD_OWNER: "ADD_OWNER",
    UPDATE_OWNER: "UPDATE_OWNER",
    ALTER_OWNER: "ALTER_OWNER",
    RESET_OWNERS: "RESET_OWNERS",
}

export default OwnersActionType;

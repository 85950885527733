import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Cancel,
    SearchRounded,
    BoyRounded,
    GirlRounded,
} from "@mui/icons-material";
import {
    Box,
    Drawer,
    TextField,
    InputAdornment,
    IconButton,
    Table,
    TableCell,
    TableRow,
    Card,
    CardContent,
    Typography,
    Divider,
    TableHead,
    Button,
    Chip,
} from "@mui/material";
import { getCustomers } from "../../redux/customers/customers.actions";
import { getCustomerAppointments } from "../../redux/appointments/appointments.actions";
import { dateString } from "../../utils/date";
import { colorMap } from "../../constants";

const SearchDrawer = ({
    open,
    handleClose,
    setAppointment,
    customers,
    setCustomers,
    customer,
    setCustomer,
    appointments,
    setAppointments,
    setSale,
}) => {
    const [query, setQuery] = useState("");
    const outlet = useSelector((state) => state.outlets.outlet);
    const [outletId, setOutletId] = useState(null);
    const [currency, setCurrency] = useState(null);

    useEffect(() => {
        setOutletId(outlet?.id);
        setCurrency(outlet?.city?.country?.currency_short);
    }, [outlet]);

    const dispatch = useDispatch();

    const resetSearch = () => {
        if (!query) {
            handleClose();
        } else {
            setQuery("");
            setCustomers([]);
            setCustomer(null);
        }
    };

    const searchCustomer = (q) => {
        if (!query) {
            setCustomers([]);
        } else {
            setCustomer(null);
            dispatch(getCustomers(outletId, q, 1, 10, (c) => setCustomers(c)));
        }
    };

    useEffect(() => {
        const typingTimer = setTimeout(() => {
            searchCustomer(query);
        }, 400);

        return () => clearTimeout(typingTimer);
        // eslint-disable-next-line
    }, [query]);

    const selectCustomer = (_customer) => {
        setCustomer(_customer);
        setCustomers([]);
        dispatch(
            getCustomerAppointments(outletId, _customer.id, (d) =>
                setAppointments(d)
            )
        );
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: {
                        sm: "100vw",
                        md: "80vw",
                        lg: "60vw",
                    },
                },
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    pt: 10,
                    pl: {
                        xs: 1,
                        sm: 1,
                        md: 15,
                    },
                    pr: {
                        xs: 1,
                        sm: 1,
                        md: 15,
                    },
                    pb: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <TextField
                    id="search-bar"
                    variant="outlined"
                    placeholder="Search with Customer Name / Phone / Email"
                    fullWidth
                    sx={{}}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <>
                                    <IconButton
                                        onClick={() => searchCustomer(query)}
                                    >
                                        <SearchRounded />
                                    </IconButton>
                                    <IconButton onClick={() => resetSearch()}>
                                        <Cancel />
                                    </IconButton>
                                </>
                            </InputAdornment>
                        ),
                    }}
                />
                {customers && (
                    <Box>
                        <Table>
                            {customers.map((c) => (
                                <TableRow hover>
                                    <TableCell
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => selectCustomer(c)}
                                    >
                                        {c.firstname} {c.lastname}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </Table>
                    </Box>
                )}
                {customer && (
                    <CustomerDetails
                        currency={currency}
                        customer={customer}
                        appointments={appointments}
                        setAppointment={setAppointment}
                        setSale={setSale}
                    />
                )}
            </Box>
        </Drawer>
    );
};

const GenderIcon = ({ gender }) => {
    return gender === "MALE" ? <BoyRounded /> : <GirlRounded />;
};

const DetailsCard = ({ title, children }) => {
    return (
        <Card
            sx={{
                borderRadius: "0.25rem",
                width: {
                    xs: "100%",
                    sm: "calc(50% - 0.5rem)",
                },
                p: 0,
                boxShadow: "0px 0px 15px #acacac",
                m: "0.5rem 0",
                minWidth: "250px",
            }}
        >
            <Typography
                fullWidth
                sx={{ background: "#acacac", fontSize: "0.75rem", pl: "1rem" }}
            >
                {title}
            </Typography>
            {children}
        </Card>
    );
};

const CustomerDetails = ({
    customer,
    currency,
    appointments,
    setAppointment,
    setSale
}) => {
    return (
        <Box sx={{ mt: 2 }}>
            <Card sx={{ display: "flex" }}>
                <Box sx={{ position: "relative", width: "100%" }}>
                    {customer.outstanding_balance > 0 && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                right: 15,
                                p: 1,
                                pt: 2,
                            }}
                        >
                            <Typography sx={{ color: "red", fontSize: 10 }}>
                                Balance
                            </Typography>
                            <Typography
                                sx={{
                                    color: "red",
                                    fontSize: 10,
                                    textAlign: "right",
                                }}
                            >
                                {currency} {customer.outstanding_balance}
                            </Typography>
                        </Box>
                    )}
                    <CardContent sx={{ flex: "1 0 auto", p: 3 }}>
                        <Typography variant="h5">
                            {customer.firstname} {customer.lastname}{" "}
                            <GenderIcon gender={customer.gender} />
                        </Typography>
                        <Typography variant="subtitle" sx={{ color: "gray" }}>
                            {customer.phone} | {customer.email}
                        </Typography>
                        {customer.notes && (
                            <Typography sx={{ color: "gray", mt: 2 }}>
                                Notes: {customer.notes}
                            </Typography>
                        )}
                        <Divider sx={{ m: 1, ml: 0 }} />
                        <Typography sx={{ color: "gray" }}>
                            Hair Color: {customer.hair_color || "N/A"} | Hair
                            System Size: {customer.hair_system_size || "N/A"} |
                            Forehead Length: {customer.forehead_length || "N/A"}
                        </Typography>
                    </CardContent>
                </Box>
            </Card>
            <Typography sx={{ mt: 2 }}>Appointments:</Typography>
            {appointments ? (
                appointments.map((a) => (
                    <AppointmentDetails
                        appointment={a}
                        currency={currency}
                        setAppointment={setAppointment}
                        setSale={setSale}
                    />
                ))
            ) : (
                <Typography>Loading...</Typography>
            )}
        </Box>
    );
};

const ServicesCard = ({ services, currency }) => {
    if (!services.length) return null;
    return (
        <DetailsCard title="Services">
            <Table>
                <TableHead sx={{ background: "#ddd" }}>
                    <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        Name
                    </TableCell>
                    <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        Amount({currency})
                    </TableCell>
                </TableHead>
                {services.map((s) => (
                    <TableRow>
                        <TableCell sx={{ py: "0.5rem" }}>
                            {s.service.value}
                        </TableCell>
                        <TableCell sx={{ py: "0.5rem" }}>{s.amount}</TableCell>
                    </TableRow>
                ))}
            </Table>
        </DetailsCard>
    );
};

const ProductsCard = ({ products, currency }) => {
    if (!products.length) return null;
    return (
        <DetailsCard title="Products">
            <Table>
                <TableHead sx={{ background: "#ddd" }}>
                    <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        Name
                    </TableCell>
                    <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        Quantity
                    </TableCell>
                    <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        Amount({currency})
                    </TableCell>
                </TableHead>
                {products.map((p) => (
                    <TableRow>
                        <TableCell sx={{ py: "0.5rem" }}>
                            {p.product.name}
                        </TableCell>
                        <TableCell sx={{ py: "0.5rem" }}>
                            {p.quantity}
                        </TableCell>
                        <TableCell sx={{ py: "0.5rem" }}>{p.total}</TableCell>
                    </TableRow>
                ))}
            </Table>
        </DetailsCard>
    );
};

const PaymentsCard = ({ payments, currency }) => {
    if (!payments.length) return null;
    return (
        <DetailsCard title="Payments">
            <Table>
                <TableHead sx={{ background: "#ddd" }}>
                    <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        Method
                    </TableCell>
                    <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        Amount({currency})
                    </TableCell>
                    <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        Date
                    </TableCell>
                </TableHead>
                {payments.map((p) => (
                    <TableRow>
                        <TableCell
                            sx={{ py: "0.5rem", textTransform: "capitalize" }}
                        >
                            {p.payment_method.toLowerCase()}
                        </TableCell>
                        <TableCell sx={{ py: "0.5rem" }}>{p.amount}</TableCell>
                        <TableCell sx={{ py: "0.5rem" }}>{dateString(p.created_at, true)}</TableCell>
                    </TableRow>
                ))}
            </Table>
        </DetailsCard>
    );
};

const TitleRow = ({ appointment, currency, total }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <Typography>
                {dateString(appointment.date)} | {appointment.time}
            </Typography>
            <Chip size={"small"} label={`${currency} ${total}`} />
        </Box>
    );
};

const CustomChip = ({ label }) => {
    return (
        <Chip
            size={"small"}
            color={colorMap[label]}
            label={label.replace("_", " ").toLowerCase()}
            sx={{ px: "0.5rem", mx: "0.5rem", textTransform: "capitalize" }}
        />
    );
};

const Notes = ({ notes }) => {
    if (!notes.length) return;
    return (
        <>
            <Typography fontSize="small" fontWeight="600">
                Notes:
            </Typography>
            {notes.map((n) => (
                <Typography fontSize="0.85rem">{n.note}</Typography>
            ))}
        </>
    );
};

const AppointmentDetails = ({ appointment, currency, setAppointment, setSale }) => {
    const total = appointment.products.reduce(
        (a, b) => a + b.total,
        appointment.amount
    );
    return (
        <Card
            sx={{
                display: "flex",
                my: "1rem",
                boxShadow: "0px 0px 15px #acacac",
                borderRadius: "0.25rem",
            }}
        >
            <Box
                sx={{ position: "relative", width: "100%", p: "1rem 1.25rem" }}
            >
                <TitleRow
                    appointment={appointment}
                    total={total}
                    currency={currency}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                    }}
                >
                    <ServicesCard
                        services={appointment.services}
                        currency={currency}
                    />
                    <ProductsCard
                        products={appointment.products}
                        currency={currency}
                    />
                    <PaymentsCard
                        payments={appointment.payments}
                        currency={currency}
                    />
                </Box>
                <Notes notes={appointment.notes} />
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        {!appointment.sale_only && (
                            <CustomChip label={appointment.status} />
                        )}
                        <CustomChip label={appointment.payment_status} />
                    </Box>
                    <Button
                        sx={{ textTransform: "none" }}
                        onClick={() =>
                            appointment.sale_only
                                ? setSale(appointment)
                                : setAppointment(appointment)
                        }
                    >
                        View More
                    </Button>
                </Box>
            </Box>
        </Card>
    );
};

export default SearchDrawer;

import AppointmentsActionType from "./appointments.types";
import * as AppointmentsActions from "./appointments.actions";
import { DELETE, GET, POST, PUT } from "../../axios";
import { takeEvery, put } from "redux-saga/effects";
import urls from "../urls";
import { setError, setSuccess } from "../actions/action.actions";

function* getAppointmentsSaga({outletId, view, date}) {
    const data = yield GET(`${urls.outlets}/${outletId}/appointments`, {view, date});
    if (data) {
        yield put(AppointmentsActions.setAppointments(data));
    }
}

function* createAppointmentSaga({outletId, appointment, callback}) {
    const data = yield POST(`${urls.outlets}/${outletId}/appointments`, appointment);
    if (data?.id){
        callback(data);
        yield put(setSuccess("Appointment created succesfully!"));
    } else {
        yield put(setError("Something went wrong"));
    }
}

function* updateAppointmentSaga({outletId, appointment, callback}) {
    const data = yield PUT(`${urls.outlets}/${outletId}/appointments/${appointment.id}`, appointment);
    if (data?.id) {
        callback(data);
        yield put(setSuccess("Appointment updated succesfully!"));
    } else {
        yield put(setError("Something went wrong"));
    }
}

function* deleteAppointmentSaga({outletId, appointmentId, view, date, callback}) {
    const data = yield DELETE(`${urls.outlets}/${outletId}/appointments/${appointmentId}`);
    if (data) {
        callback()
        yield put(setSuccess("Appointment cancelled succesfully!"));
        yield put(AppointmentsActions.getAppointments(outletId, view, date));
    } else {
        yield put(setError("Something went wrong"));
    }
}

function* addPaymentsSaga({ outletId, appointmentId, payments, callback }) {
    const data = yield POST(`${urls.outlets}/${outletId}/appointments/${appointmentId}/payments`, payments);
    if (data && data === true) {
        callback();
        yield put(setSuccess("Payment details saved succesfully!"));
    } else {
        yield put(setError("Something went wrong"));
    }
}

function* deletePaymentSaga({ outletId, appointmentId, paymentId, callback }) {
    const data = yield DELETE(`${urls.outlets}/${outletId}/appointments/${appointmentId}/payments/${paymentId}`);
    if (data) {
        callback();
        yield put(setSuccess("Payment details saved succesfully!"));
    } else {
        yield put(setError("Something went wrong"));
    }
}

function* getNotesSaga({ outletId, appointmentId, callback }){
    let data = null;
    data = yield GET(`${urls.outlets}/${outletId}/appointments/${appointmentId}/notes`);
    if (data) {
        callback(data);
    } else {
        yield put(setError("Something went wrong"));
    }
}

function* addNotesSaga({ outletId, appointmentId, note, callback }) {
    const data = yield POST(`${urls.outlets}/${outletId}/appointments/${appointmentId}/notes?note=${note}`);
    if (data) {
        callback(data);
    } else {
        yield put(setError("Something went wrong"));
    }
}

function* getCustomerAppointmentsSaga({ outletId, customerId, callback }) {
    const data = yield GET(`${urls.outlets}/${outletId}/customers/${customerId}/appointments`);
    if (data) {
        callback(data);
    } else {
        yield put(setError("Something went wrong"));
    }
}

function* getAppointmentSaga({ outletId, customerId, appointmentId, callback }) {
    const data = yield GET(`${urls.outlets}/${outletId}/customers/${customerId}/appointments/${appointmentId}`);
    if (data) {
        callback(data);
    } else {
        yield put(setError("Something went wrong"));
    }
}

export function* appointmentsSagas() {
    yield takeEvery(AppointmentsActionType.GET_APPOINTMENTS, getAppointmentsSaga);
    yield takeEvery(AppointmentsActionType.CREATE_APPOINTMENT, createAppointmentSaga);
    yield takeEvery(AppointmentsActionType.UPDATE_APPOINTMENT, updateAppointmentSaga);
    yield takeEvery(AppointmentsActionType.DELETE_APPOINTMENT, deleteAppointmentSaga);
    yield takeEvery(AppointmentsActionType.ADD_PAYMENTS, addPaymentsSaga);
    yield takeEvery(AppointmentsActionType.GET_NOTES, getNotesSaga);
    yield takeEvery(AppointmentsActionType.ADD_NOTES, addNotesSaga);
    yield takeEvery(AppointmentsActionType.DELETE_PAYMENT, deletePaymentSaga);
    yield takeEvery(AppointmentsActionType.GET_CUSTOMER_APPOINTMENTS, getCustomerAppointmentsSaga);
    yield takeEvery(AppointmentsActionType.GET_APPOINTMENT, getAppointmentSaga);
}

import React, { useEffect, useState } from "react";
import Container from "../../sharedComponents/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import * as CategoriesActions from "../../redux/categories/categories.actions";
import * as ProductsActions from "../../redux/products/products.actions";
import { getCountries } from "../../redux/countries/countries.actions";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableBody, TableHead, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/EditNote";
import ToggleOff from "@mui/icons-material/ToggleOff";
import ToggleOn from "@mui/icons-material/ToggleOn";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Loader from "../../sharedComponents/Loader";

const Products = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const products = useSelector((state) => state.products.products);
  const countries = useSelector((state) => state.countries.countries);
  const [category, setCategory] = useState(null);
  const [showNew, setShowNew] = useState(false);
  const [newProduct, setNewProduct] = useState({ value: "" });
  const [editProduct, setEditProduct] = useState(null);

  useEffect(() => {
    !categories && dispatch(CategoriesActions.getCategories(1, 100));
    // eslint-disable-next-line
  }, [categories]);

  useEffect(() => {
    category?.id &&
      !products &&
      dispatch(ProductsActions.getProducts(category.id, 1, 100));
    // eslint-disable-next-line
  }, [category]);

  useEffect(() => {
    if (!countries) {
      dispatch(getCountries());
    }
    // eslint-disable-next-line
  }, []);

  const _setCategory = (c) => {
    setNewProduct({ name: "" });
    setShowNew(false);
    if (c?.id === category?.id) return;
    dispatch(ProductsActions.resetProducts());
    setCategory(c);
  };

  const _setEditProduct = (p) => {
    if (!p) {
      setEditProduct(null);
    } else {
      setEditProduct({
        id: p.id,
        name: p.name,
        active: p.active,
        price_details: [],
      });
    }
  };

  const handleSelectCategory = (e) => {
    _setCategory(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      ProductsActions.addProduct(category.id, newProduct, () =>
        _setCategory(category)
      )
    );
  };

  const updateProduct = (data) => {
    dispatch(
      ProductsActions.updateProduct(category.id, data, () => {
        setEditProduct(null);
      })
    );
  };

  const handleNewPriceChange = ({ target: { value, name } }) => {
    let cid = name.split("_")[1];
    let price_details = newProduct.price_details || [];
    let p = price_details.findIndex((x) => x.country_id === cid);
    if (p >= 0) {
      price_details[p].price = value;
    } else {
      price_details.push({
        country_id: cid,
        price: value,
      });
    }
    setNewProduct({
      ...newProduct,
      price_details,
    });
  };

  const handleEditPrice = ({ target: { value, name } }) => {
    let cid = name.split("_")[1];
    let price_details = editProduct.price_details || [];
    let p = price_details.findIndex((x) => x.country_id === cid);
    if (p >= 0) {
      price_details[p].price = value;
    } else {
      price_details.push({
        country_id: cid,
        price: value,
      });
    }
    setEditProduct({ ...editProduct, price_details });
  };

  return (
    <Container>
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography>Products</Typography>
        <CategoryDropdown
          category={category}
          categories={categories}
          handleChange={handleSelectCategory}
        />
        {category && products ? (
          <Box sx={{ maxWidth: 600 }}>
            <Button
              variant="standard"
              onClick={() => {
                setShowNew(true);
              }}
              sx={{ float: "right" }}
            >
              Add
            </Button>
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Table>
                <TableHead>
                  <TableRow key={"head"}>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Prices
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {showNew && (
                    <AddProduct
                      countries={countries}
                      product={newProduct}
                      handleCancel={() => setShowNew(false)}
                      handleChange={(e) =>
                        setNewProduct({
                          ...newProduct,
                          name: e.target.value,
                        })
                      }
                      handleSubmit={handleSubmit}
                      handlePriceChange={handleNewPriceChange}
                    />
                  )}
                  {products?.map((s) =>
                    editProduct?.id === s.id ? (
                      <TableRow key={s.id}>
                        <TableCell
                          sx={{ padding: "10px 16px" }}
                          align="left"
                          scope="row"
                        >
                          <TextField
                            autoFocus
                            key={"name" + s.id}
                            variant="standard"
                            name="name"
                            value={editProduct.name}
                            onChange={(e) =>
                              setEditProduct({
                                ...editProduct,
                                name: e.target.value,
                              })
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {countries.map((c) => {
                            return (
                              <TextField
                                key={c.id + "_" + s.id}
                                size="small"
                                variant="standard"
                                label={c.currency_short}
                                placeholder={c.currency_short}
                                sx={{ width: 60, ml: 2 }}
                                value={
                                  editProduct?.price_details?.find(
                                    (x) => x.country_id === c.id
                                  )?.price ||
                                  s.prices.find((x) => x.country.id === c.id)
                                    ?.price ||
                                  ""
                                }
                                name={`price_${c.id}`}
                                type="number"
                                onChange={handleEditPrice}
                              />
                            );
                          })}
                        </TableCell>
                        <TableCell sx={{ padding: "0px 16px" }} align="right">
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() => updateProduct(editProduct)}
                          >
                            <SaveIcon />
                          </IconButton>
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() => {
                              setEditProduct(null);
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={s.id}>
                        <TableCell
                          sx={{ padding: "10px 16px" }}
                          align="left"
                          scope="row"
                        >
                          {s.name}
                        </TableCell>
                        <TableCell>
                          {s.prices
                            .sort((a, b) =>
                              a.country.currency_short >
                              b.country.currency_short
                                ? 1
                                : -1
                            )
                            .map(
                              (p, i) =>
                                `${i > 0 ? " | " : ""}${
                                  p.country.currency_short
                                } ${p.price}`
                            )}
                        </TableCell>
                        <TableCell sx={{ padding: "0px 16px" }} align="right">
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() => _setEditProduct(s)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() => {
                              updateProduct({ ...s, active: !s.active });
                            }}
                          >
                            {s.active ? (
                              <ToggleOn color="primary" />
                            ) : (
                              <ToggleOff />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : category ? (
          <Loader />
        ) : (
          <Typography
            sx={{ mt: 10, color: (theme) => theme.palette.text.secondary }}
          >
            Please select a category...
          </Typography>
        )}
      </Box>
    </Container>
  );
};

const AddProduct = ({
  handleCancel,
  product,
  handleChange,
  handleSubmit,
  countries,
  handlePriceChange,
}) => {
  return (
    <>
      <TableRow key={"new"}>
        <TableCell sx={{ padding: "15px 16px" }} align="left" scope="row">
          <TextField
            autoFocus
            variant="standard"
            label="Name"
            placeholder="Name"
            sx={{ width: 150 }}
            value={product?.name}
            name="value"
            onChange={handleChange}
          />
        </TableCell>
        <TableCell sx={{ padding: "15px 16px" }} align="left" scope="row">
          {countries?.map((c) => (
            <TextField
              key={`price_new_${c.id}`}
              variant="standard"
              label={c.currency_short}
              placeholder={c.currency_short}
              sx={{ width: 60, ml: 2 }}
              value={
                product?.price_details?.find((p) => p.country_id === c.id)
                  ?.price
              }
              name={`price_${c.id}`}
              type="number"
              onChange={handlePriceChange}
            />
          ))}
        </TableCell>
        <TableCell sx={{ padding: "15px 16px" }} align="right">
          <IconButton onClick={handleSubmit} disabled={!product.name}>
            <SaveIcon />
          </IconButton>
          <IconButton onClick={handleCancel}>
            <CancelIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

const CategoryDropdown = ({
  category = "",
  categories = [],
  handleChange = () => {},
}) => {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="category-label">
          {categories && categories.length
            ? "Category"
            : "Loading Categories..."}
        </InputLabel>
        <Select
          labelId="category-label"
          id="category"
          value={category || ""}
          label="Category"
          onChange={handleChange}
          disabled={!categories || !categories.length}
        >
          {categories &&
            categories.map((c) => (
              <MenuItem key={c.id} value={c}>
                {c.value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Products;

import { combineReducers } from "redux";
import AuthReducer from "./auth/auth.reducer";
import ActionReducer from "./actions/action.reducer";
import CountriesReducer from "./countries/countries.reducer";
import CategoriesReducer from "./categories/categories.reducer";
import ServicesReducer from "./services/services.reducer";
import ProductsReducer from "./products/products.reducer";
import OwnersReducer from "./owners/owners.reducer";
import OutletsReducer from "./outlets/outlets.reducer";
import TechiciansReducer from "./technicians/technicians.reducer";
import CustomersReducer from "./customers/customers.reducer";
import SalesReducer from "./sales/sales.reducer";
import AppointmentsReducer from "./appointments/appointments.reducer";
import ReportsReducer from "./reports/reports.reducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  actions: ActionReducer,
  countries: CountriesReducer,
  categories: CategoriesReducer,
  services: ServicesReducer,
  products: ProductsReducer,
  owners: OwnersReducer,
  outlets: OutletsReducer,
  technicians: TechiciansReducer,
  customers: CustomersReducer,
  sales: SalesReducer,
  appointments: AppointmentsReducer,
  reports: ReportsReducer,
});

const reducer = (state, action) => rootReducer(state, action);

export default reducer;

import React, { useEffect, useState } from "react";
import Container from "../../sharedComponents/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../redux/countries/countries.actions";
import * as CategoriesActions from "../../redux/categories/categories.actions";
import * as ServicesActions from "../../redux/services/services.actions";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableBody, TableHead, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/EditNote";
import ToggleOff from "@mui/icons-material/ToggleOff";
import ToggleOn from "@mui/icons-material/ToggleOn";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Loader from "../../sharedComponents/Loader";

const Services = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const countries = useSelector((state) => state.countries.countries);
  const services = useSelector((state) => state.services.services);
  const [category, setCategory] = useState(null);
  const [showNew, setShowNew] = useState(false);
  const [newService, setNewService] = useState({ value: "" });
  const [editService, setEditService] = useState(null);

  useEffect(() => {
    !categories && dispatch(CategoriesActions.getCategories(1, 100));
    // eslint-disable-next-line
  }, [categories]);

  useEffect(() => {
    category?.id &&
      !services &&
      dispatch(ServicesActions.getServices(category.id, 1, 100));
    // eslint-disable-next-line
  }, [category]);

  useEffect(() => {
    if (!countries) {
      dispatch(getCountries());
    }
    // eslint-disable-next-line
  }, []);

  const _setCategory = (c) => {
    setNewService({ value: "" });
    setShowNew(false);
    if (c.id === category?.id) return;
    dispatch(ServicesActions.resetServices());
    setCategory(c);
  };

  const handleSelectCategory = (e) => {
    _setCategory(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      ServicesActions.addService(category.id, newService, () =>
        _setCategory(category)
      )
    );
  };

  const updateService = (data) => {
    dispatch(
      ServicesActions.updateService(category.id, data, () => {
        setEditService(null);
      })
    );
  };

  const handleEditPrice = ({ target: { value, name } }) => {
    let cid = name.split("_")[1];
    let price_details = editService.price_details || [];
    let p = price_details.findIndex((x) => x.country_id === cid);
    if (p >= 0) {
      price_details[p].price = value;
    } else {
      price_details.push({
        country_id: cid,
        price: value,
      });
    }
    setEditService({ ...editService, price_details });
  };

  const handleNewPriceChange = ({ target: { value, name } }) => {
    let cid = name.split("_")[1];
    let price_details = newService.price_details || [];
    let p = price_details.findIndex((x) => x.country_id === cid);
    if (p >= 0) {
      price_details[p].price = value;
    } else {
      price_details.push({
        country_id: cid,
        price: value,
      });
    }
    setNewService({
      ...newService,
      price_details,
    });
  };

  return (
    <Container>
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography>Services</Typography>
        <CategoryDropdown
          category={category}
          categories={categories}
          handleChange={handleSelectCategory}
        />
        {category && services ? (
          <Box>
            <Button
              variant="standard"
              onClick={() => {
                setShowNew(true);
              }}
              sx={{ float: "right" }}
            >
              Add
            </Button>
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Table>
                <TableHead>
                  <TableRow key={"head"}>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Prices
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {showNew && (
                    <AddService
                      service={newService}
                      handleCancel={() => setShowNew(false)}
                      handleChange={(e) =>
                        setNewService({
                          ...newService,
                          value: e.target.value,
                        })
                      }
                      countries={countries}
                      handleSubmit={handleSubmit}
                      handlePriceChange={handleNewPriceChange}
                    />
                  )}
                  {services?.map((s) =>
                    editService?.id === s.id ? (
                      <TableRow key={s.id}>
                        <TableCell
                          sx={{ padding: "10px 16px" }}
                          align="left"
                          scope="row"
                        >
                          <TextField
                            autoFocus
                            variant="standard"
                            name="value"
                            value={editService.value}
                            onChange={(e) =>
                              setEditService({
                                ...editService,
                                value: e.target.value,
                              })
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {countries.map((c) => {
                            return (
                              <TextField
                                key={c.id + "_" + s.id}
                                size="small"
                                variant="standard"
                                label={c.currency_short}
                                placeholder={c.currency_short}
                                sx={{ width: 60, ml: 2 }}
                                value={
                                  editService?.price_details?.find(
                                    (x) => x.country_id === c.id
                                  )?.price ||
                                  s.prices.find((x) => x.country.id === c.id)
                                    ?.price ||
                                  ""
                                }
                                name={`price_${c.id}`}
                                type="number"
                                onChange={handleEditPrice}
                              />
                            );
                          })}
                        </TableCell>
                        <TableCell sx={{ padding: "0px 16px" }} align="right">
                          <IconButton
                            onClick={() => updateService(editService)}
                          >
                            <SaveIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setEditService(null);
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={s.id}>
                        <TableCell
                          sx={{ padding: "10px 16px" }}
                          align="left"
                          scope="row"
                        >
                          {s.value}
                        </TableCell>
                        <TableCell>
                          {s.prices
                            .sort((a, b) =>
                              a.country.currency_short >
                              b.country.currency_short
                                ? 1
                                : -1
                            )
                            .map(
                              (p, i) =>
                                `${i > 0 ? " | " : ""}${
                                  p.country.currency_short
                                } ${p.price}`
                            )}
                        </TableCell>
                        <TableCell sx={{ padding: "0px 16px" }} align="right">
                          <IconButton onClick={() => setEditService(s)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              updateService({ ...s, active: !s.active });
                            }}
                          >
                            {s.active ? (
                              <ToggleOn color="primary" />
                            ) : (
                              <ToggleOff />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : category ? (
          <Loader />
        ) : (
          <Typography
            sx={{ mt: 10, color: (theme) => theme.palette.text.secondary }}
          >
            Please select a category...
          </Typography>
        )}
      </Box>
    </Container>
  );
};

const AddService = ({
  handleCancel,
  service,
  countries,
  handleChange,
  handleSubmit,
  handlePriceChange,
}) => {
  return (
    <TableRow key={"new"}>
      <TableCell sx={{ padding: "15px 16px" }} align="left" scope="row">
        <TextField
          autoFocus
          variant="standard"
          sx={{ width: 150 }}
          value={service?.value}
          name="value"
          onChange={handleChange}
          placeholder="Name"
        />
      </TableCell>
      <TableCell sx={{ padding: "15px 16px" }} align="left" scope="row">
        {countries?.map((c) => (
          <TextField
            key={`price_new_${c.id}`}
            variant="standard"
            label={c.currency_short}
            placeholder={c.currency_short}
            sx={{ width: 60, ml: 2 }}
            value={
              service?.price_details?.find((p) => p.country_id === c.id)?.price
            }
            name={`price_${c.id}`}
            type="number"
            onChange={handlePriceChange}
          />
        ))}
      </TableCell>
      <TableCell sx={{ padding: "15px 16px" }} align="right">
        <IconButton onClick={handleSubmit} disabled={!service.value}>
          <SaveIcon />
        </IconButton>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const CategoryDropdown = ({
  category = null,
  categories = [],
  handleChange = () => {},
}) => {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="category-label">
          {categories && categories.length
            ? "Category"
            : "Loading Categories..."}
        </InputLabel>
        <Select
          labelId="category-label"
          id="category"
          value={category || ""}
          label="Category"
          onChange={handleChange}
          disabled={!categories || !categories.length}
        >
          {categories &&
            categories.map((c) => (
              <MenuItem key={c.id} value={c}>
                {c.value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Services;

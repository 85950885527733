import ServicesActionType from './services.types'

export const addService = (categoryId, service, callback=()=>{}) => ({
    type: ServicesActionType.ADD_SERVICE,
    categoryId, service, callback
});

export const getServices = (categoryId, page, size) => ({
    type: ServicesActionType.GET_SERVICES,
    categoryId, page, size
});

export const setServices = (categoryId, payload) => ({
    type: ServicesActionType.SET_SERVICES,
    categoryId, payload
});

export const updateService = (categoryId, service, callback=()=>{}) => ({
    type: ServicesActionType.UPDATE_SERVICE,
    categoryId, service, callback
});

export const alterService = (payload) => ({
    type: ServicesActionType.ALTER_SERVICE,
    payload
});

export const resetServices = () => ({
    type: ServicesActionType.RESET_SERVICES
});

export const getServicesOptions = () => ({
    type: ServicesActionType.GET_SERVICES_OPTIONS
});

export const setServicesOptions = (payload) => ({
    type: ServicesActionType.SET_SERVICES_OPTIONS,
    payload
});

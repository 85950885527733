import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button, FormControl, MenuItem } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "calc(100% - 30px)",
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  p: 4,
  pt: 2,
  pb: 2,
  borderRadius: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: 24,
};

const genderOptions = [
  {
    id: "MALE",
    value: "Male",
  },
  {
    id: "FEMALE",
    value: "Female",
  },
  {
    id: "OTHER",
    value: "Other",
  },
];

const TechnicianForm = ({
  open = true,
  edit = false,
  data = {},
  handleChange = () => {},
  readOnly = false,
  handleClose = () => {},
  handleSubmit = () => {},
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" sx={{ p: 2 }}>
          {readOnly ? "View" : edit ? "Edit" : "Add"} Technician
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid key="1" item xs={12} sm={6}>
              <TextField
                sx={{ width: "100%" }}
                name={"firstname"}
                variant={"standard"}
                label={"First Name"}
                required
                autoComplete="off"
                type="text"
                autoFocus={true}
                disabled={readOnly}
                value={data?.firstname || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="2" item xs={12} sm={6}>
              <TextField
                sx={{ width: "100%" }}
                name={"lastname"}
                variant={"standard"}
                label={"Last Name"}
                required
                autoComplete="off"
                type="text"
                disabled={readOnly}
                value={data?.lastname || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="3" item xs={12} sm={6}>
              <Dropdown
                name={"gender"}
                value={data?.gender?.toUpperCase()}
                options={genderOptions}
                handleChange={handleChange}
                label="Gender"
              />
            </Grid>
            <Grid key="4" item xs={12} sm={6}>
              <TextField
                sx={{ width: "100%" }}
                name={"emp_start_date"}
                variant={"standard"}
                label={"Date of Employment"}
                required
                autoComplete="off"
                type="date"
                disabled={readOnly}
                value={data?.emp_start_date || new Date()}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="5" item xs={12} sm={6}>
              <TextField
                sx={{ width: "100%" }}
                name={"designation"}
                variant={"standard"}
                label={"Designation"}
                required
                autoComplete="off"
                type="text"
                disabled={readOnly}
                value={data?.designation || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              key="7"
              item
              xs={12}
              sx={{ m: 2, display: "flex", justifyContent: "center" }}
            >
              {readOnly ? (
                <>
                  <Button onClick={handleClose}>Close</Button>
                </>
              ) : (
                <>
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                  <Button onClick={handleClose}>Cancel</Button>
                </>
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

const Dropdown = ({
  value = "",
  options = [],
  label = "",
  name = "",
  handleChange = () => {},
}) => {
  return (
    <>
      <FormControl fullWidth>
        <TextField
          select
          variant="standard"
          labelid="owner-label"
          id="owner"
          value={value || ""}
          label={label}
          onChange={handleChange}
          name={name}
          disabled={!options || !options.length}
          required
        >
          {options &&
            options.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.value || ""}
              </MenuItem>
            ))}
        </TextField>
      </FormControl>
    </>
  );
};

export default TechnicianForm;

import { takeEvery, put } from "redux-saga/effects"
import { GET, POST } from "../../axios"
import AuthActionType from "./auth.types"
import * as AuthActions from "./auth.actions"
import { setError, setSuccess } from "../actions/action.actions"
import urls from "../urls"


function* loginSaga({username, password}) {
    let data = yield POST(
        urls.login,
        {username, password}
    )
    if (data && data.token){
        yield put(AuthActions.setToken(data.token, data.user))
        if (data?.user?.role !== 'admin')
            yield put(AuthActions.getOutlets())
    } else {
        yield put(setError(data.detail))
    }
}

function* getUserSaga({onError}) {
    let data = yield GET(
        urls.user
    )
    if (data && !data.detail){
        yield put(AuthActions.setUser(data))
        if (data?.role !== 'admin')
            yield put(AuthActions.getOutlets())
    } else {
        onError()
    }
}

function* getOutletsSaga() {
    let data = yield GET(`${urls.user}/outlets`);
    if (data && !data.detail)
        yield put(AuthActions.setOutlets(data));
}

function* changePasswordSaga({payload, callback}) {
    let data = yield POST(`users/change_password`, payload);
    if (data === true) {
        callback();
        yield put(setSuccess("Password updated succesfully!"));
    } else {
        yield put(setError(data.detail))
    }
}

export function* authSagas() {
    yield takeEvery(AuthActionType.LOGIN, loginSaga)
    yield takeEvery(AuthActionType.GET_USER, getUserSaga)
    yield takeEvery(AuthActionType.GET_OUTLETS, getOutletsSaga)
    yield takeEvery(AuthActionType.CHANGE_PASSWORD, changePasswordSaga)
}

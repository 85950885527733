const CustomersActionType = {
    GET_CUSTOMERS: "GET_CUSTOMERS",
    GET_CUSTOMER_OPTIONS: "GET_CUSTOMER_OPTIONS",
    SET_CUSTOMER_OPTIONS: "SET_CUSTOMER_OPTIONS",
    SET_CUSTOMERS: "SET_CUSTOMERS",
    ADD_CUSTOMER: "ADD_CUSTOMER",
    UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
    ALTER_CUSTOMER: "ALTER_CUSTOMER",
    RESET_CUSTOMERS: "RESET_CUSTOMERS",
    GET_CUSTOMER: "GET_CUSTOMER",
}

export default CustomersActionType;

const CountriesActionType = {
    GET_COUNTRIES: "GET_COUNTRIES",
    SET_COUNTRIES: "SET_COUNTRIES",
    ADD_COUNTRY: "ADD_COUNTRY",
    UPDATE_COUNTRY: "UPDATE_COUNTRY",
    ALTER_COUNTRY: "ALTER_COUNTRY",
    GET_CITIES: "GET_CITIES",
    SET_CITIES: "SET_CITIES",
    ADD_CITY: "ADD_CITY",
    UPDATE_CITY: "UPDATE_CITY",
    ALTER_CITY: "ALTER_CITY"
}

export default CountriesActionType

import TechniciansActionType from "./technicians.types"

const INITIAL_STATE = {
    outletId: null,
    technicians: null,
    page: 1,
    pages: 1
}

const reducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case TechniciansActionType.SET_TECHNICIANS:
            return {
                ...state,
                outletId: action.outletId,
                page: payload.page,
                pages: payload.pages,
                technicians: payload.objects
            }
        case TechniciansActionType.ALTER_TECHNICIAN:
            let _technicians = state.technicians
            _technicians[
                _technicians.findIndex(x => x.id === payload.id)
            ] = payload
            return {
                ...state,
                technicians: [..._technicians]
            }
        case TechniciansActionType.RESET_TECHNICIANS:
            return {
                ...state,
                page: 1,
                pages: 1,
                technicians: null
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;

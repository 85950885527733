import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { downloadDailyReport, downloadReport } from "../../redux/reports/reports.actions";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

let firstColumn = {
    MONTHLY: "month",
    DAILY: "date",
    QUARTERLY: "quarter",
    PRODUCTS: "product",
    SERVICES: "service",
};

const ReportsTable = ({ report, reportType, currency, filename, country, outlet}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [downloadData, setDownloadData] = useState([]);
    const [total, setTotal] = useState({});
    const [sortedReport, setSortedReport] = useState(report);

    let fields = [
        "quantity",
        "gross_sales",
        "discounts",
        "net_sales",
        "tax",
        "total",
    ];
    fields.unshift(firstColumn[reportType]);

    const handleDownload = (rowData) => {
        let filter = {
            country_id: country,
            date: rowData.date,
        };
        if (outlet && outlet !== 'all') {
          filter['outlet_id'] = outlet;
        }
        dispatch(downloadDailyReport(filter));
    };

    const handlePdfDownload = (downloadData) => {
        let data = {
            country_id: country,
            csv_data: downloadData,
            type: reportType
        };
        if (outlet && outlet !== 'all') {
            data['outlet_id'] = outlet;
        }
        dispatch(downloadReport(data));
    };

    useEffect(() => {
        let _report = [...report];
        if (reportType !== "MONTHLY") {
            _report = report.sort((a, b) => {
                return a[fields[0]] > b[fields[0]]
                    ? 1
                    : a[fields[0]] < b[fields[0]]
                    ? -1
                    : 0;
            });
            setSortedReport([...report]);
        }
        let _total = {};
        fields.forEach((f) => {
            _total[f] = 0;
        });
        report.forEach((r) => {
            fields.forEach((f) => {
                _total[f] += Number(r[f]);
            });
        });
        _total[fields[0]] = "Total";
        setTotal({ ..._total });
        let _downloadData = [];
        _downloadData.push(
            fields.map(
                (f, i) =>
                    `${f.toUpperCase().replace("_", " ")}${
                        i > 1 ? " (" + currency + ")" : ""
                    }`
            )
        );
        _report.forEach((r) => {
            let re = [];
            fields.forEach((f, i) =>
                re.push(i > 1 ? parseFloat(r[f]).toFixed(2) : r[f])
            );
            _downloadData.push(re);
        });
        let res = [];
        fields.forEach((f, i) =>
            res.push(i > 1 ? parseFloat(_total[f]).toFixed(2) : _total[f])
        );
        _downloadData.push(res);
        setDownloadData(_downloadData);
        // eslint-disable-next-line
    }, [report, reportType]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 4,
                    width: "100%",
                }}
            >
                <Button
                    variant="standard"
                    sx={{ color: theme.palette.primary.main }}
                    onClick={() => handlePdfDownload(downloadData)}
                >
                    Download
                </Button>
            </Box>
            <TableContainer component={Paper} sx={{ width: "100%", mt: 0 }}>
                <Table sx={{ width: "100%" }}>
                    <TableHead>
                        <TableRow key={"head"}>
                            {fields.map((h, i) => (
                                <TableCell
                                    sx={{
                                        textTransform: "capitalize",
                                        padding: "10px 16px",
                                        color: (theme) =>
                                            theme.palette.text.secondary,
                                    }}
                                    align="left"
                                >
                                    {h.replace("_", " ")}{" "}
                                    {i > 1 ? `(${currency})` : ""}
                                </TableCell>
                            ))}
                            {reportType === "DAILY" && (
                            <TableCell align="left">Download</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={"total"} sx={{ background: "#ddd" }}>
                            {fields.map((f, i) => (
                                <TableCell align="left">
                                    {i > 1
                                        ? parseFloat(total[f]).toFixed(2)
                                        : total[f]}
                                </TableCell>
                            ))}
                            <TableCell align="left"></TableCell> 
                        </TableRow>
                        {sortedReport.map((r, j) => (
                            <TableRow key={r[fields[0]]} sx={{ background: j % 2 === 0 ? '#fff' : '#eee' }}>
                                {fields.map((f, i) => (
                                    <TableCell align="left">
                                        {i > 1
                                            ? parseFloat(r[f]).toFixed(2)
                                            : r[f]}
                                    </TableCell>
                                ))}
                                <TableCell align="left">
                                    {reportType === "DAILY" ? (
                                        <Button color="primary" onClick={() => handleDownload(r)}>
                                        <FileDownloadIcon />
                                      </Button>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ReportsTable;

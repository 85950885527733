const AppointmentsActionType = {
    GET_APPOINTMENTS: "GET_APPOINTMENTS",
    SET_APPOINTMENTS: "SET_APPOINTMENTS",
    CREATE_APPOINTMENT: "CREATE_APPOINTMENT",
    UPDATE_APPOINTMENT: "UPDATE_APPOINTMENT",
    ALTER_APPOINTMENT: "ALTER_APPOINTMENT",
    DELETE_APPOINTMENT: "DELETE_APPOINTMENT",
    ADD_PAYMENTS: "ADD_PAYMENTS",
    GET_NOTES: "GET_NOTES",
    ADD_NOTES: "ADD_NOTES",
    DELETE_PAYMENT: "DELETE_PAYMENT",
    GET_CUSTOMER_APPOINTMENTS: "GET_CUSTOMER_APPOINTMENTS",
    GET_APPOINTMENT: "GET_APPOINTMENT",
}

export default AppointmentsActionType;

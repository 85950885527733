import AuthActionType from "./auth.types"

export const login = (username, password) => {
    return {
        type: AuthActionType.LOGIN,
        username,
        password
    }
}

export const setToken = (token, user) => {
    return {
        type: AuthActionType.SET_TOKEN,
        token,
        user
    }
}

export const logout = () => {
    return {
        type: AuthActionType.LOGOUT
    }
}

export const getUser = (onError=()=>{}) => {
    return {
        type: AuthActionType.GET_USER,
        onError
    }
}

export const setUser = (user) => {
    return {
        type: AuthActionType.SET_USER,
        user
    }
}

export const getOutlets = () => ({
    type: AuthActionType.GET_OUTLETS
});

export const setOutlets = (payload) => ({
    type: AuthActionType.SET_OUTLETS,
    payload
});

export const changePassword = (payload, callback=()=>{}) => ({
    type: AuthActionType.CHANGE_PASSWORD,
    payload, callback
});

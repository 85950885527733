import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../sharedComponents/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/EditNote";
import ToggleOff from "@mui/icons-material/ToggleOff";
import ToggleOn from "@mui/icons-material/ToggleOn";
import OutletForm from "../../components/outlets/OutletForm";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import {
  addOutlet,
  getOutlets,
  updateOutlet,
  setOutlet as _setOutlet,
  getUsers,
  resetOutletUsers,
  checkUsername,
  addUser,
} from "../../redux/outlets/outlets.actions";
import { changePassword } from "../../redux/auth/auth.actions";
import { getOwnersOptions } from "../../redux/owners/owners.actions";
import { resetTechnicians } from "../../redux/technicians/technicians.actions";
import {
  getCountries,
  getCities,
} from "../../redux/countries/countries.actions";
import Loader from "../../sharedComponents/Loader";
import UsersModal from "../../components/outlets/Users";

const Outlets = () => {
  const dispatch = useDispatch();
  const outlets = useSelector((state) => state.outlets.outlets);
  const owners = useSelector((state) => state.owners.options);
  const countries = useSelector((state) => state.countries.countries);
  const [showNew, setShowNew] = useState(false);
  const [newOutlet, setNewOutlet] = useState({});
  const [showUsers, setShowUsers] = useState(false);
  const [outletId, setOutletId] = useState(null);
  const outletUsers = useSelector((state) => state.outlets.users);
  const [outlet, setOutlet] = useState(null);

  const _setShowUsers = (show, outlet) => {
    setShowUsers(show);
    setOutletId(outlet?.id);
    setOutlet(outlet);
    dispatch(resetOutletUsers());
  };

  useEffect(() => {
    if (outletId && !outletUsers) dispatch(getUsers(outletId));
    // eslint-disable-next-line
  }, [outletId]);

  const handleChange = ({ target: { name, value } }) => {
    setNewOutlet({
      ...newOutlet,
      [name]: value,
    });
  };

  const handleEditChange = ({ target: { name, value } }) => {
    setOutlet({
      ...outlet,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addOutlet(newOutlet, () => {
        setShowNew(false);
        setNewOutlet({});
      })
    );
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(updateOutlet(outlet, () => setOutlet(null)));
  };

  useEffect(() => {
    if (!outlets) dispatch(getOutlets());
    dispatch(resetTechnicians());
    // eslint-disable-next-line
  }, [outlets]);

  useEffect(() => {
    if (!owners) dispatch(getOwnersOptions());
    if (!countries) dispatch(getCountries(1, 100));
  }, [showNew, owners, countries, dispatch]);

  useEffect(() => {
    if (newOutlet.country_id) {
      if (!countries?.find((x) => x.id === newOutlet.country_id)?.cities)
        dispatch(getCities(newOutlet.country_id, 1, 100));
    }
    // eslint-disable-next-line
  }, [newOutlet]);

  useEffect(() => {
    if (outlet?.country_id) {
      if (!countries?.find((x) => x.id === outlet?.country_id)?.cities)
        dispatch(getCities(outlet?.country_id, 1, 100));
    }
    // eslint-disable-next-line
  }, [outlet]);

  useEffect(() => {
    dispatch(_setOutlet(null));
    // eslint-disable-next-line
  }, []);

  const checkUsernameCallback = (res, data) => {
    if (res) {
      dispatch(addUser(outletId, data));
    } else {
      alert("This username is already taken. Please try a different name.");
    }
  };

  const checkUserName = (data) => {
    dispatch(
      checkUsername(data.username, (res) => checkUsernameCallback(res, data))
    );
  };

  const createUser = (data) => {
    checkUserName(data);
  };

  const _changePassword = (data) => {
    dispatch(changePassword(data));
  };

  return (
    <Container>
      {showUsers && (
        <UsersModal
          outlet={outlet}
          users={outletUsers}
          handleClose={() => _setShowUsers(false, null)}
          createUser={createUser}
          changePassword={_changePassword}
        />
      )}
      <OutletForm
        data={newOutlet}
        open={showNew}
        handleChange={handleChange}
        handleClose={() => setShowNew(false)}
        handleSubmit={handleSubmit}
        owners={owners}
        countries={countries}
        cities={countries?.find((x) => x.id === newOutlet.country_id)?.cities}
      />
      <OutletForm
        data={outlet}
        edit={true}
        open={outlet && !outletId ? true : false}
        handleChange={handleEditChange}
        handleClose={() => setOutlet(null)}
        handleSubmit={handleEditSubmit}
        owners={owners}
        countries={countries}
        cities={countries?.find((x) => x.id === outlet?.country_id)?.cities}
      />
      <Box
        sx={{
          width: "600px",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ width: "100%" }}>Outlets</Typography>
        {outlets ? (
          <Box width="100%">
            <Button
              variant="standard"
              onClick={() => setShowNew(true)}
              sx={{ float: "right" }}
            >
              Add
            </Button>
            <TableContainer sx={{ width: "100%" }} component={Paper}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow key={"head"}>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      City
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="right"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {outlets?.map((c) => (
                    <TableRow
                      key={c.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{ padding: "0px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {c.name}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "10px 16px" }}
                        align="left"
                        scope="row"
                      >
                        {c.city.value}
                      </TableCell>
                      <TableCell sx={{ padding: "0px 16px" }} align="right">
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => _setShowUsers(true, c)}
                        >
                          <PeopleOutlineIcon sx={{ p: 0 }} />
                        </IconButton>
                        <IconButton sx={{ p: 0 }} onClick={() => setOutlet({...c, country_id: c?.city?.country?.id})}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() =>
                            dispatch(
                              updateOutlet({ id: c.id, active: !c.active })
                            )
                          }
                        >
                          {c.active ? (
                            <ToggleOn color="primary" />
                          ) : (
                            <ToggleOff />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Loader />
        )}
      </Box>
    </Container>
  );
};

export default Outlets;

import OwnersActionType from "./owners.types";

const INITIAL_STATE = {
  owners: null,
  page: 1,
  pages: 1,
  options: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case OwnersActionType.SET_OWNERS:
      return {
        ...state,
        page: payload.page,
        pages: payload.pages,
        owners: payload.objects,
      };
    case OwnersActionType.SET_OWNERS_OPTIONS:
      return {
        ...state,
        options: payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;

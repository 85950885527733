import OutletsActionType from "./outlets.types";

const INITIAL_STATE = {
  outlets: null,
  page: 1,
  pages: 1,
  outletId: null,
  outlet: null,
  users: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case OutletsActionType.SET_OUTLETS:
      return {
        ...state,
        page: payload.page,
        pages: payload.pages,
        outlets: payload.objects,
      };
    case OutletsActionType.SET_OUTLET:
      return {
        ...state,
        outletId: payload?.id || null,
        outlet: {
          ...payload,
          currency: payload?.city?.country?.currency_short
        }
      };
    case OutletsActionType.RESET_USERS:
      return {
        ...state,
        users: null
      };
    case OutletsActionType.SET_USERS:
      return {
        ...state,
        users: payload
      };
    default:
      return { ...state };
  }
};

export default reducer;

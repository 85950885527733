const urls = {
  countries: "configs/countries",
  categories: "configs/categories",
  servicesOptions: "configs/services/options",
  login: "login",
  user: "users/me",
  owners: "owners",
  outlets: "outlets"
};

export default urls;

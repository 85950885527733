import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button, IconButton, Chip } from "@mui/material";
import TrashIcon from "@mui/icons-material/Delete";
import CustomerForm from "../customers/CustomerForm";
import Dropdown from "../../sharedComponents/Dropdown";
import PaymentWindow from "../../sharedComponents/PaymentsForm";
import InputAdornment from '@mui/material/InputAdornment';
import { ProductRow } from "../sales/SaleForm";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { colorMap } from "../../constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxWidth: "calc(100% - 30px)",
  maxHeight: "calc(100vh)",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  p: 4,
  pt: 2,
  pb: 2,
  borderRadius: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  boxShadow: 24,
};

const ServiceRow = ({
  index,
  key,
  categories = [],
  service,
  currency,
  country_id,
  handleChange = () => {},
  handleDelete = () => {},
  readOnly = false,
}) => {
  return (
    <Grid key={key} item xs={12} sm={12}>
      <Grid container key={`${index}_container`} spacing={2}>
        <Grid item key={`${index}_category`} xs={12} sm={4}>
          <Dropdown
            name={`category_id`}
            value={service?.category_id || service?.service?.category_id}
            options={categories}
            handleChange={(e) => handleChange(index, e)}
            label="Category"
            disabled={readOnly}
          />
        </Grid>
        <Grid item key={`${index}_service`} xs={12} sm={4}>
          <Dropdown
            name={`service_id`}
            disabled={readOnly}
            value={service?.service_id || service?.service?.id}
            options={
              service?.category_id || service?.service?.category_id
                ? categories
                    .find(
                      (c) =>
                        c.id ===
                        (service?.category_id || service?.service?.category_id)
                    )
                    ?.services?.filter((s) => s.prices?.[country_id] >= 0)
                : []
            }
            handleChange={(e) => handleChange(index, e)}
            label="Service"
          />
        </Grid>
        <Grid item key={`${index}_amount`} xs={12} sm={4}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <TextField
              sx={{ width: "calc(100% - 60px)" }}
              name={"amount"}
              variant={"standard"}
              label={"Amount"}
              required
              autoComplete="off"
              type="number"
              value={service?.amount.toFixed(2)}
              onChange={(e) => handleChange(index, e)}
              disabled={readOnly}
              InputProps={{
                startAdornment:
                  <InputAdornment position="start">
                    {currency}
                  </InputAdornment>
              }}
            />
            {!readOnly ? (
              <IconButton readOnly onClick={() => handleDelete(index)}>
                <TrashIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
        <Grid item key={`${index}_price`} xs={12} sm={4}></Grid>
      </Grid>
    </Grid>
  );
};

const AppointmentForm = ({
  open = true,
  edit = false,
  data = {},
  country_id = "",
  currency = "Rupees",
  handleChange = () => {},
  readOnly = false,
  handleClose = () => {},
  handleSubmit = () => {},
  handleAddService = () => {},
  handleServiceChange = () => {},
  handleDeleteService = () => {},
  handleDeleteAppointment = () => {},
  customers = [],
  categories = [],
  technicians = [],
  updateTip = () => {},
  addPayments = () => {},
  deletePayment = () => {},
  addNewCustomer = () => {},
  fetchNotes = () => {},
  addNote= () => {},
  notes = [],
  handleAddProduct = () => {},
  handleProductChange = () => {},
  handleProductDelete = () => {},
  handleDownloadInvoice = () => {},
  products = [],
}) => {
  const [pay, setPay] = useState(false);
  const [showNewCustomer, setShowNewCustomer] = useState(false);
  const [newCustomer, setNewCustomer] = useState({});
  const [disableSave, setDisableSave] = useState(false);
  const [warning, setWarning] = useState("");
  const [total, setTotal] = useState(0);
  const [showNotes, setShowNotes] = useState(false)
  const [addNoteShow, setAddNoteShow] = useState(false)
  const [newNote, setNewNote] = useState("");
  const handleCustomerChange = ({ target: { name, value } }) => {
    setNewCustomer({
      ...newCustomer,
      [name]: value,
    });
  };
  const _setNewNote = (e) => {
    setNewNote(e?.target?.value || '');
  };
  const handleCloseNotes = () => {
    setShowNotes(false)
  }
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'};
    return new Date(dateString).toLocaleDateString('en-us', options)
  };
  const statuses = [
    {
      value: "Booked",
      id: "BOOKED",
    },
    {
      value: "In Progress",
      id: "IN_PROGRESS",
    },
  ];

  if (data?.status === "COMPLETED")
    statuses.push({ value: "Completed", id: "COMPLETED" });
  let scheduleTime = new Date(`${data?.date} ${data?.time}`);
  if (scheduleTime < new Date())
    statuses.push({ value: "No Show", id: "NO_SHOW" });
  useEffect(() => {
    let _services = data?.services;
    let _products = data?.products;
    setTotal(
      _products.reduce((a, p) => a + (isNaN(p?.total) ? 0 : p.total), 0) +
      _services.reduce((a, s) => a + (isNaN(s?.amount) ? 0 : s.amount), 0)
    );
    let d = false; //_services?.length > 0 ? false : true;
    let w = "";
    _services?.forEach((s) => {
      if (!s.category_id || !s.service_id) d = true;
    });
    const _uniqueServices = new Set(_services.map((s) => s.service_id));
    if (_uniqueServices.size !== _services.length) {
      d = true;
      w = "You have selected same service more than once.";
    }
    setDisableSave(d);
    setWarning(w);
  }, [data]);

  const handleFetchNotes = () => {

    fetchNotes();
    setShowNotes(true);
  };

  const handleAddNotes = (note, cb) => {
      addNote(note, cb);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" sx={{ p: 2 }}>
          {readOnly ? "View" : edit ? "Edit" : "Add"} Appointment
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Grid key="1" item xs={12} sm={4}>
              <Dropdown
                name={"customer_id"}
                value={data?.customer_id}
                options={customers ? customers : []}
                handleChange={handleChange}
                label="Customer"
                disabled={data?.customer}
                required={false}
                addNew={
                  edit
                    ? null
                    : () => {
                        setShowNewCustomer(true);
                      }
                }
              />
            </Grid>
            <Grid key="2" item xs={12} sm={4}>
              <TextField
                sx={{ width: "100%" }}
                name={"date"}
                variant={"standard"}
                label={"Date"}
                required
                autoComplete="off"
                type="date"
                disabled={readOnly}
                value={data?.date || new Date()}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="3" item xs={12} sm={4}>
              <TextField
                sx={{ width: "100%" }}
                name={"time"}
                variant={"standard"}
                label={"Time"}
                required
                autoComplete="off"
                type="time"
                disabled={readOnly}
                value={data?.time || new Date()}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="4" item xs={12} sm={4}>
              <TextField
                sx={{ width: "100%" }}
                name={"duration"}
                variant={"standard"}
                label={"Duration (Minutes)"}
                required
                autoComplete="off"
                type="number"
                disabled={readOnly}
                value={data?.duration || new Date()}
                onChange={handleChange}
              />
            </Grid>
            <Grid key="5" item xs={12} sm={4}>
              <Dropdown
                name={"technician_id"}
                value={data?.technician_id}
                options={technicians}
                required={false}
                handleChange={handleChange}
                disabled={readOnly}
                label="Technician"
              />
            </Grid>
            <Grid key="6" item xs={12} sm={4}>
              <Dropdown
                name={"status"}
                value={data?.status}
                options={statuses}
                required={false}
                handleChange={handleChange}
                label="Status"
                disabled={!edit || readOnly}
              />
            </Grid>
            {edit === true && <Grid key="notes" item xs={12}>
              <TextField
                name={"notes"}
                type="text"
                value={data?.customer?.notes || "None"}
                label={"Customer Notes"}
                variant={"standard"}
                sx={{ width: "100%" }}
                multiline={true}
                disabled={true}
                onChange={handleChange}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <Button sx={{textDecoration: ""}} 
                        onClick={handleFetchNotes}
                      >View All</Button>
                      {/* <Button sx={{textDecoration: ""}}>Add</Button> */}
                    </InputAdornment>
                }}
              />
            </Grid>}
            {
              showNotes && (
                <Modal open={open} onClose={handleCloseNotes}>
                  <Box sx={{...style, width: "550px"}}>
                    <Typography variant="h6" sx={{ p: 2 }}>
                      Notes
                    </Typography>
                    <table width={"100%"} style={{borderCollapse: "collapse"}}>
                      <tbody>
                        {notes.map((note) => (
                          <tr key={note.id} style={{borderBottom: "1px solid #ddd"}}>
                            <td style={{ color: 'gray', width: "1%", whiteSpace: "nowrap", paddingTop: "0.75rem", verticalAlign: "top"}}>{formatDate(note.created_at)}:</td>
                            <td style={{ paddingTop: "0.75rem", paddingLeft: "0.5rem"}}>{note.note}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {addNoteShow && (
                      <Grid
                      item
                      key="36354"
                      xs={12}
                      lg={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <AddNotes
                          note={newNote}
                          setNote={_setNewNote}
                          onCancel={() => setAddNoteShow(false)}
                          handleAddNotes={handleAddNotes}
                        />
                        </Grid>
                      )}
                    <Grid
                      item
                      key="02"
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        // size="small"
                        // sx={{ fontSize: "11px" }}
                        onClick={() => setAddNoteShow(true)}
                      >
                        Add
                      </Button>
                      <Button
                        // size="small"
                        // sx={{ fontSize: "11px" }}
                        onClick={() => setShowNotes(false)}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Box>
                </Modal>

              )
            }
            <Grid key="7" item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item key="s_title" xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 16,
                      textAlign: "center",
                      mb: -1.5,
                      mt: 0.5,
                    }}
                  >
                    Service(s)
                  </Typography>
                </Grid>
                {data?.services?.map((service, i) => {
                  return (
                    <ServiceRow
                      index={i}
                      key={i}
                      service={service}
                      categories={categories}
                      currency={currency}
                      country_id={country_id}
                      handleChange={handleServiceChange}
                      handleDelete={handleDeleteService}
                      readOnly={readOnly}
                    />
                  );
                })}
              </Grid>
            </Grid>
            {data?.products?.length > 0 && <Grid key="34523" item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item key="s_title" xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 16,
                      textAlign: "center",
                      mb: -1.5,
                      mt: 0.5,
                    }}
                  >
                    Product(s)
                  </Typography>
                </Grid>
                {data?.products?.sort((a, b) => 
                  a.sort_idx > b.sort_idx ? 1 : a.sort_idx < b.sort_idx ? -1 : 0
                ).map((product, i) => {
                  return (
                    <ProductRow
                      index={i}
                      key={i}
                      products={products}
                      categories={categories}
                      data={product}
                      currency={currency}
                      country_id={country_id}
                      handleChange={handleProductChange}
                      handleDelete={handleProductDelete}
                      readOnly={readOnly}
                      showDelete={true}
                    />
                  );
                })}
              </Grid>
            </Grid>}
            {data?.payment_status ? (
              <Grid sx={{ display: "flex", alignItems: "flex-end" }}>
                <Chip
                  color={colorMap[data?.payment_status]}
                  sx={{ borderRadius: "2px" }}
                  size={"small"}
                  label={data?.payment_status?.replace("_", " ")}
                />
              </Grid>
            ) : (
              <></>
            )}
            {data?.services?.length > 0 && (
              <Grid
                key="8"
                item
                xs={12}
                sm={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <TextField
                  sx={{ width: 50 }}
                  name={"total"}
                  variant={"standard"}
                  autoComplete="off"
                  type="text"
                  disabled={true}
                  value={`Total`}
                />
                <TextField
                  sx={{ width: "100%" }}
                  name={"total"}
                  variant={"standard"}
                  autoComplete="off"
                  type="float"
                  disabled={true}
                  value={`${currency} ${parseFloat(total).toFixed(2)}`}
                />
              </Grid>
            )}
            <Grid
              item
              key="02"
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                size="small"
                disabled={readOnly}
                sx={{ fontSize: "11px" }}
                onClick={handleAddProduct}
              >
                Add Product
              </Button>
              <Button
                size="small"
                disabled={readOnly}
                sx={{ fontSize: "11px" }}
                onClick={handleAddService}
              >
                Add Service
              </Button>
            </Grid>
            <Grid item key={"warning"} xs={12}>
              <Typography color="red" textAlign={"center"}>
                {warning}
              </Typography>
            </Grid>
            <Grid
              key="9"
              item
              xs={12}
              sx={{ m: 2, display: "flex", justifyContent: "center" }}
            >
              {readOnly ? (
                <>
                  <Button onClick={handleClose}>Close</Button>
                  <Grid item>
                    <Button
                      onClick={() => {
                        setPay(true);
                      }}
                    >
                      Payment
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => handleDownloadInvoice()}
                    >
                      Invoice
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid container spacing={2} justifyContent={"center"}>
                  <Grid item>
                    <Button type="submit" disabled={disableSave}>
                      Save
                    </Button>
                  </Grid>
                  {edit ? (
                    <Grid item>
                      <Button
                        onClick={() => {
                          setPay(true);
                        }}
                      >
                        Payment
                      </Button>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {edit && data?.payment_status !== "done" ? (
                    <Grid item>
                      <Button
                        sx={{ color: "red" }}
                        onClick={() => {
                          handleDeleteAppointment(data?.id);
                        }}
                      >
                        Cancel Appointment
                      </Button>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item>
                    <Button onClick={handleClose}>Close</Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
        {pay ? (
          <PaymentWindow
            open={pay}
            addPayments={addPayments}
            deletePayment={deletePayment}
            handleClose={() => setPay(false)}
            amount={total || data?.total || data?.amount}
            currency={currency}
            tip={data?.tip}
            updateTip={updateTip}
            paymentsData={data?.payments}
            setPay={setPay}
            status={data?.payment_status}
          />
        ) : (
          <></>
        )}
        <CustomerForm
          open={showNewCustomer}
          handleClose={() => setShowNewCustomer(false)}
          handleChange={handleCustomerChange}
          handleSubmit={(e) => {
            e.preventDefault();
            addNewCustomer(newCustomer, () => setShowNewCustomer(false));
          }}
          data={newCustomer}
        />
      </Box>
    </Modal>
  );
};

const AddNotes = ({ note, onCancel, setNote, handleAddNotes }) => {
  const cb = () => {
    setNote('');
    onCancel();
  }
  return (
    <TableRow key={"0001"}>
      <TableCell sx={{ padding: "15px 16px" }} align="left" scope="row">
        <TextField
          autoFocus
          variant="standard"
          sx={{ width: 150 }}
          value={note}
          onChange={setNote}
          placeholder="Note"
        />
      </TableCell>
      <TableCell sx={{ padding: "15px 16px" }} align="right">
        <IconButton
        disabled={!note}
        onClick={() => {
          handleAddNotes(note, cb);
        }} >
          <SaveIcon />
        </IconButton>
        <IconButton onClick={onCancel}>
          <CancelIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};


export default AppointmentForm;
